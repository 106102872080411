import { Paper, Typography, Box, PaperProps } from '@mui/material';
import { ReactComponent as TurkeyIcon } from 'assets/icons/turkey.svg';
import { styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useTariffsQuery } from 'graphql/generated';

const Root = styled(Paper)(({ theme }: { theme: Theme }) => ({
    width: '100%',
    height: 'max-content',
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
    },
    '& .tariff-country': {
        display: 'flex',
        backgroundColor: 'rgba(255, 177, 0, 0.15)',
        padding: theme.spacing(1, 3),
        borderRadius: theme.spacing(1.25),
        alignItems: 'flex-start',
        width: 'max-content',
        marginBottom: theme.spacing(1.5),
    },
    '& .tariff': {
        display: 'flex',
        backgroundColor: 'rgba(255, 177, 0, 0.15)',
        padding: theme.spacing(1, 3),
        borderRadius: '10px',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1.5),
        },
    },
}));

type TariffsProps = PaperProps & {
    showTitle?: boolean;
};

export const Tariffs: React.FC<TariffsProps> = ({ showTitle, ...props }) => {
    const { data } = useTariffsQuery({
        fetchPolicy: 'network-only',
    });

    const tariffs = data?.tariffs.edges.map(({ node }) => node) || [];

    return (
        <Root {...props}>
            {showTitle && (
                <Typography variant="h3" mb={3}>
                    Tariflər
                </Typography>
            )}
            <Box className="tariff-country">
                <TurkeyIcon style={{ marginRight: 12 }} />
                <Typography>Türkiyə</Typography>
            </Box>
            {tariffs.map((tariff, index) => (
                <Box key={index} className="tariff">
                    <Typography>{tariff.weight.name}</Typography>
                    <Typography variant="h4">${tariff.price}</Typography>
                </Box>
            ))}
        </Root>
    );
};
