import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/styles';

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: theme.dark ? '#999cb0' : '#F2F4FF',
    },
    '& .status': {
        width: '8px',
        height: '8px',
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        borderRadius: '2px',
    },
    '& .notification': {
        fontWeight: 500,
        fontSize: '12px',
    },
}));

export const NotificationItem = ({ notification }) => {
    return (
        <Root>
            <Box className="status" />
            <Typography className="notification" color="textSecondary">
                {notification}
            </Typography>
        </Root>
    );
};
