import { Box, FormControl } from '@mui/material';
import { styled } from '@mui/styles';
import { AutoCompleteInput } from 'components/form/autocomplete';
import { Field, FieldProps } from 'formik';
import { useCustomersLazyQuery } from 'graphql/generated';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type CustomerInputProp = {
    error?: boolean | undefined;
    touched?: boolean | undefined;
    labelprop?: string;
    setBranchPrice?: Dispatch<SetStateAction<number>>;
    onClickOption?: (customerId: string) => void;
    customerId?: string;
};

type CustomerOption = {
    id: string;
    fullName: string;
    price: number;
};
const Root = styled(Box)(() => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        padding: '5px',
    },
}));

export const CustomerInput: React.FC<CustomerInputProp> = ({
    error,
    touched,
    labelprop,
    setBranchPrice,
    onClickOption,
    customerId,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState<CustomerOption | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<CustomerOption[]>([]);

    const [loadCustomers, { loading }] = useCustomersLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: { where: { isGetAll: true } },
        onCompleted(data) {
            const customers = data?.customers?.edges.map((item) => {
                return {
                    id: item.node.id,
                    fullName: item.node.code + ' ' + item.node.user.fullName,
                    price: item.node?.branches.edges[0]?.node?.price,
                };
            });
            if (customers) {
                setOptions(customers);
            }
        },
    });

    const filterCompanies = useMemo(
        () =>
            _.debounce(
                (fullName?: string) => loadCustomers({ variables: { where: { keyword: fullName, isGetAll: true } } }),
                500,
            ),

        [],
    );

    useEffect(() => {
        if (customerId) {
            loadCustomers({ variables: { where: { isGetAll: true, customerId } } });
        }
    }, [customerId]);

    useEffect(() => {
        if (inputValue && inputValue !== value?.fullName) {
            filterCompanies(inputValue);
        } else if (inputValue.length === 0) {
            setOptions([]);
        }
    }, [inputValue]);

    return (
        <Root>
            <FormControl variant="outlined" fullWidth error={touched && error}>
                <Field name="customerId" fullWidth>
                    {({ field: { name }, form: { setFieldValue, values } }: FieldProps): React.ReactNode => {
                        return (
                            <AutoCompleteInput
                                options={options}
                                value={options.find((o) => o.id === values[name]) || null}
                                onChange={(_, newValue: CustomerOption | null): void => {
                                    setFieldValue(name, newValue?.id || '');
                                    setValue((newValue || '') as any);
                                    setBranchPrice && setBranchPrice(newValue?.price || 0);
                                }}
                                onInputChange={(_, newInputValue): void => {
                                    setInputValue(newInputValue);
                                }}
                                getOptionSelected={(option: CustomerOption, value: CustomerOption): boolean => {
                                    return option?.id === value?.id;
                                }}
                                getOptionLabel={(option: CustomerOption): string => option?.fullName}
                                loading={loading}
                                error={error}
                                touched={touched}
                                label={labelprop ? labelprop : t('transactions:customer')}
                                renderOption={(props, option) => (
                                    <li
                                        {...props}
                                        onClick={(e) => {
                                            props.onClick(e);
                                            onClickOption?.(option?.id);
                                        }}
                                    >
                                        {option?.fullName}
                                    </li>
                                )}
                            />
                        );
                    }}
                </Field>
            </FormControl>
        </Root>
    );
};
