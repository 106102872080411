import React from 'react';
import { alpha } from '@mui/system';
import { Button, ButtonProps } from '@mui/material';

type PayButtonProps = {
    buttonColor: string;
    action?: () => void;
};

export const PayButton: React.FC<PayButtonProps & ButtonProps> = ({ children, buttonColor, action, ...props }) => {
    return (
        <Button
            sx={{
                height: 30,
                fontSize: '0.8rem',
                backgroundColor: alpha(buttonColor, 0.18),
                color: buttonColor,
                '&:hover': {
                    backgroundColor: alpha(buttonColor, 0.28),
                },
                padding: '8px',
                cursor: action ? 'pointer' : 'inherit',
            }}
            onClick={action}
            {...props}
        >
            {children}
        </Button>
    );
};
