import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography, Collapse, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';

type FAQItemProps = {
    question: string;
    answer: string;
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
    '& .question': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3, 0, 3, 4),
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 0, 3, 0),
        },
    },
    '& .answer': {
        margin: theme.spacing(1.75, 6, 1.75, 2),
    },
    '& .arrow-icon': {
        transition: 'all .3s',
    },
}));

export const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const toggleCollapse = () => setCollapsed((prev) => !prev);
    const theme = useTheme();

    return (
        <Root>
            <Box
                className="question"
                onClick={toggleCollapse}
                sx={{ borderBottom: `.5px solid ${collapsed ? theme.palette.primary.main : theme.palette.divider}` }}
            >
                <Typography
                    fontWeight={500}
                    sx={{ color: collapsed ? 'primary.main' : 'inherit', transition: 'all .2s' }}
                >
                    {question}
                </Typography>
                <KeyboardArrowDownIcon
                    className="arrow-icon"
                    fontSize="small"
                    sx={{
                        transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                        color: collapsed ? 'primary.main' : '#707070',
                    }}
                />
            </Box>
            <Collapse in={collapsed}>
                <Typography className="answer">{answer}</Typography>
            </Collapse>
        </Root>
    );
};
