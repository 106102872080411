import { Box, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { Link } from 'components';
import { RatioBox } from 'components/ratio-box';
import { BlogPost } from 'graphql/generated';

const Root = styled(Paper)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[1],
    height: '100%',
    borderRadius: theme.spacing(3),
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main,
    },
    '& .news-description': {
        width: '100%',
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        padding: theme.spacing(1.5, 3),
    },
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderTopLeftRadius: theme.spacing(3),
        borderTopRightRadius: theme.spacing(3),
    },
}));

export const NewsCard: React.FC<{ post: BlogPost }> = ({ post }) => {
    const title = post.title.length > 95 ? `${post.title.slice(0, 95)}...` : post.title;
    return (
        <Link to={`/news/${post.slug}`} style={{ textDecoration: 'none', height: '100%' }}>
            <Root>
                <RatioBox ratio={0.65}>
                    <img className="image" src={post.coverImage.url} />
                </RatioBox>

                <Box className="news-description">
                    <Typography mb={1} color="textSecondary" fontSize=".75rem">
                        {post.createdAt.split('T')[0]}
                    </Typography>

                    <Typography mb={1.5} fontWeight="500">
                        {title}
                    </Typography>
                </Box>
            </Root>
        </Link>
    );
};
