export const notifications = [
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
    '#123456789 nömrəli bağlamanız yerli anbardadır.',
    '#123456789 nömrəli sifarişiniz təsdiqləndi.',
];
