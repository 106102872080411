import { forwardRef } from 'react';
import { Package } from 'graphql/generated';
import { useBarcode } from 'react-barcodes';
import { styled } from '@mui/system';
import { Box, Theme } from '@mui/material';

type PackageToPrintProps = {
    pack: Package;
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
    width: '100%',
    '& *': {
        fontWeight: 800,
        fontSize: 30,
    },
    '& .title': {
        fontSize: '30px',
        borderBottom: '1px solid gray',
        marginBottom: theme.spacing(7),
        textAlign: 'center',
    },
    '& .qr-code': {
        display: 'flex',
        justifyContent: 'center',
        transform: 'scale(1.5)',
        marginBottom: theme.spacing(7),
    },
    '& .column': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    '& .column-title': {
        writingMode: 'vertical-rl',
        textOrientation: 'sideways-right',
        transform: 'rotate(180deg)',
    },
    '& .content': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(8, 4),
        border: `1px solid ${theme.palette.text.primary}`,
        width: '100%',
    },
    '& .content-item': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    '& .receiver-content': {
        padding: theme.spacing(6, 4),
        border: `1px solid ${theme.palette.text.primary}`,
        width: '100%',
    },
    '& .receiver-name': {
        fontSize: 40,
        marginBottom: theme.spacing(3),
    },
    '& .receiver-item': {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const PackageToPrint = forwardRef<HTMLDivElement, PackageToPrintProps>(({ pack }, ref) => {
    const date = new Date();
    const currentDate = ` ${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;

    const { inputRef } = useBarcode({
        value: pack.falkonTrackingNumber,
    });

    return (
        <>
            <Root ref={ref}>
                <div className="title">FALKON - AZERI EXPRESS SERVICE</div>
                <div className="qr-code">
                    <canvas ref={inputRef} />
                </div>
                <div className="column">
                    <p className="column-title">Sender</p>
                    <div className="content">
                        <div className="content-item">
                            <p>Store</p>
                            <p>{pack?.store?.name}</p>
                        </div>
                        <div className="content-item">
                            <p>Weight</p>
                            <p>{pack?.weight} kq</p>
                        </div>
                        <div className="content-item">
                            <p>Price</p>
                            <p>{pack?.invoicePrice.toFixed(2)} TL</p>
                        </div>
                        <div className="content-item">
                            <p>Shipping</p>
                            <p>{pack?.deliveryPrice?.toFixed(2) + '$'}</p>
                        </div>
                        {/* <div className="content-item">
                            <p>Declared value</p>
                            <p>{pack?.declaredValue?.toFixed(2)}</p>
                        </div> */}
                        <div className="content-item">
                            <p>Quantitiy</p>
                            <p>{pack?.count}</p>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <p className="column-title">Receiver</p>
                    <div className="receiver-content">
                        <p className="receiver-name">{pack?.customer?.user?.fullName + '-' + pack?.customer?.code}</p>
                        <div className="receiver-item">
                            <p>Personal id:</p>
                            <p>{pack?.customer?.code}</p>
                        </div>
                        <div className="receiver-item">
                            <p>Mobil:</p>
                            <p>{pack?.customer?.user?.phoneNumber}</p>
                        </div>
                        <div className="receiver-item">
                            <p>Address</p>
                            <p>{pack?.customer?.user?.address}</p>
                        </div>
                        <div className="receiver-item">
                            <p>Country</p>
                            <p>{pack?.country?.name}</p>
                        </div>
                        <div className="receiver-item">
                            <p>Ship date</p>
                            <p>{currentDate}</p>
                        </div>
                        <div className="receiver-item">
                            <p>Branch</p>
                            <p>{pack?.customer?.branches?.edges[0].node.name}</p>
                        </div>
                        {pack?.regNumber && (
                            <div className="receiver-item">
                                <p>Reg number</p>
                                <p>{pack?.regNumber}</p>
                            </div>
                        )}
                    </div>
                </div>
            </Root>
        </>
    );
});

PackageToPrint.displayName = 'PackageToPrint';

export { PackageToPrint };
