import { Box as MUIBox } from '@mui/material';
import { styled } from '@mui/styles';
import { StyledSlider } from 'components';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

type RangeTextFieldProps = {
    label: string;
    name: string;
};

const Box = styled(MUIBox)(() => ({
    '& .MuiOutlinedInput-root': { borderBottomLeftRadius: 'unset', borderBottomRightRadius: 'unset' },
    '& .MuiOutlinedInput-notchedOutline': { borderBottom: 'none !important' },
}));

export const RangeTextField: React.FC<RangeTextFieldProps> = ({ name, ...others }) => {
    const { setFieldValue, values } = useFormikContext<any>();
    const handleChange = (_event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setFieldValue(name, newValue);
        }
    };
    return (
        <Box style={{ position: 'relative' }}>
            <Field
                style={{ borderBottomLeftRadius: 'unset', borderBottomRightRadius: 'unset' }}
                name={name}
                type="number"
                component={TextField}
                variant="outlined"
                {...others}
            />
            <Box style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <StyledSlider onChange={handleChange} value={values[name]} step={0.1} max={20} min={0} />
            </Box>
        </Box>
    );
};
