import { Box, BoxProps, Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Fragment } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export type Status = {
    title: string;
    date?: string;
    completed: boolean;
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
    },
    [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'column',
    },
    '& .status-item': {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.up('md')]: {
            width: 24,
        },
    },
    '& .status-icon': {
        marginBottom: theme.spacing(1),
    },
    '& .status-item-content': {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(-6),
            marginRight: theme.spacing(-6),
        },
    },
    '& .seperator-wrap': {
        [theme.breakpoints.up('md')]: {
            height: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    '& .seperator': {
        backgroundColor: theme.palette.text.secondary,
        [theme.breakpoints.up('md')]: {
            width: '85%',
            height: '1px',
        },
        [theme.breakpoints.down('md')]: {
            width: '1px',
            height: 40,
        },
    },
}));

export const StatusesStepper: React.FC<BoxProps & { statuses: any; statusEnum?: any; statusColorEnum?: any }> = ({
    statuses,
    statusEnum,
    statusColorEnum,
    ...props
}) => {
    const currentStatuses = statuses.map((s) => s.status);
    const { t } = useTranslation();

    return (
        <Root {...props}>
            {Object.values(statusEnum).map((status: any, index) => {
                const currentStatus = statuses[currentStatuses.indexOf(status)];
                return (
                    <Fragment key={index}>
                        <Box className="status-item">
                            {currentStatus ? (
                                <RadioButtonCheckedIcon
                                    sx={{ color: statusColorEnum[currentStatus.status] }}
                                    className="status-icon"
                                />
                            ) : (
                                <RadioButtonUncheckedIcon sx={{ opacity: 0.4 }} className="status-icon" />
                            )}
                            <Box className="status-item-content">
                                <Typography
                                    sx={{
                                        mb: currentStatus ? 0.5 : 0,
                                        opacity: currentStatus ? 0.8 : 0.4,
                                    }}
                                    fontSize={12}
                                >
                                    {t(`statuses:${currentStatus?.status || status}`)}
                                </Typography>
                                {currentStatus?.createdAt && (
                                    <Typography
                                        sx={{ color: statusColorEnum[currentStatus?.status] }}
                                        fontSize={12}
                                        fontWeight={500}
                                    >
                                        {DateTime.fromISO(currentStatus?.createdAt).toFormat('dd.MM.yyyy')}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        {Object.values(statusEnum).length - 1 !== index && (
                            <Box className="seperator-wrap">
                                <Box className="seperator" />
                            </Box>
                        )}
                    </Fragment>
                );
            })}
        </Root>
    );
};
