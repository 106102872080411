import { PaymentStatus, PackagePaymentStatus, PackageStatus } from 'graphql/generated';

export const Theme = {
    Dark: 'dark',
    Light: 'light',
};

export const paymentStatusColor = {
    [PaymentStatus.Paid]: 'rgb(64, 218, 93)',
    [PaymentStatus.Pending]: 'rgb(254, 144, 0)',
    [PaymentStatus.Declined]: 'rgb(255, 0, 0)',
    [PaymentStatus.Cancelled]: 'rgb(231, 109, 79)',
};

export const packagePaymentStatus = {
    [PackagePaymentStatus.Paid]: 'Paid',
    [PackagePaymentStatus.NotPaid]: 'Not Paid',
    [PackagePaymentStatus.PaidByCash]: 'Paid by cash',
};

export const statusColor = {
    [PackageStatus.HandedOver]: 'rgba(56, 191, 253, 1)',
    [PackageStatus.InBranch]: 'rgba(255, 177, 0, 1)',
    [PackageStatus.InCustom]: 'rgba(59, 67, 242, 1)',
    [PackageStatus.InExternalStock]: 'rgba(255, 81, 63, 1)',
    [PackageStatus.InLocalStock]: 'rgba(254, 144, 0, 1)',
    [PackageStatus.Pending]: 'rgba(64, 218, 93, 1)',
    [PackageStatus.SentFromExternalStock]: 'rgba(64, 218, 93, 1)',
    [PackageStatus.PendingReturn]: 'rgba(255, 191, 0, 1)',
    [PackageStatus.Returned]: 'rgba(0, 204, 0, 1)',
};

export const PackageOrderStatus = {
    Pending: 'PENDING',
    InExternalStock: 'IN_EXTERNAL_STOCK',
    SentFromExternalStock: 'SENT_FROM_EXTERNAL_STOCK',
    InCustom: 'IN_CUSTOM',
    InLocalStock: 'IN_LOCAL_STOCK',
    InBranch: 'IN_BRANCH',
    HandedOver: 'HANDED_OVER',
};

export const ReturnStatus = {
    PendingReturn: 'PENDING_RETURN',
    Returned: 'RETURNED',
};

export const ApolloNetworkStatus = {
    /**
     * The query has never been run before and the query is now currently running. A query will still
     * have this network status even if a partial data result was returned from the cache, but a
     * query was dispatched anyway.
     */
    Loading: 1,
    /**
     * If `setVariables` was called and a query was fired because of that then the network status
     * will be `setVariables` until the result of that query comes back.
     */
    SetVariables: 2,
    /**
     * Indicates that `fetchMore` was called on this query and that the query created is currently in
     * flight.
     */
    FetchMore: 3,
    /**
     * Similar to the `setVariables` network status. It means that `refetch` was called on a query
     * and the refetch request is currently in flight.
     */
    Refetch: 4,
    /**
     * Indicates that a polling query is currently in flight. So for example if you are polling a
     * query every 10 seconds then the network status will switch to `poll` every 10 seconds whenever
     * a poll request has been sent but not resolved.
     */
    Poll: 6,
    /**
     * No request is in flight for this query, and no errors happened. Everything is OK.
     */
    Ready: 7,
    /**
     * No request is in flight for this query, but one or more errors were detected.
     */
    Error: 8,
};
