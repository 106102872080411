import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    page: Scalars['Int'];
    totalPages: Scalars['Int'];
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type Category = RelayNode & {
    __typename?: 'Category';
    id: Scalars['ID'];
    name: Scalars['String'];
    iconId?: Maybe<Scalars['String']>;
    parentId: Scalars['String'];
    color: Scalars['String'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    icon?: Maybe<FileInfo>;
    children: CategoryConnection;
    parent?: Maybe<Category>;
};

export type RelayNode = {
    id: Scalars['ID'];
};

export type CategoryEdge = {
    __typename?: 'CategoryEdge';
    node: Category;
};

export type CategoryConnection = {
    __typename?: 'CategoryConnection';
    totalCount: Scalars['Int'];
    edges: Array<CategoryEdge>;
    pageInfo: PageInfo;
};

export type BlurhashMeta = {
    __typename?: 'BlurhashMeta';
    hash: Scalars['String'];
    width: Scalars['Int'];
    height: Scalars['Int'];
};

export type CreateCategoryPayload = {
    __typename?: 'CreateCategoryPayload';
    category: Category;
};

export type DeleteCategoryPayload = {
    __typename?: 'DeleteCategoryPayload';
    id: Scalars['String'];
};

export type Configuration = RelayNode & {
    __typename?: 'Configuration';
    id: Scalars['ID'];
    type: ConfigurationType;
    price: Scalars['Float'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export enum ConfigurationType {
    CommissionFee = 'COMMISSION_FEE',
    ExpeditedOrderServiceFee = 'EXPEDITED_ORDER_SERVICE_FEE',
    PackageMinPrice = 'PACKAGE_MIN_PRICE',
}

export type ConfigurationEdge = {
    __typename?: 'ConfigurationEdge';
    node: Configuration;
};

export type ConfigurationConnection = {
    __typename?: 'ConfigurationConnection';
    totalCount: Scalars['Int'];
    edges: Array<ConfigurationEdge>;
    pageInfo: PageInfo;
};

export type UpdateConfigurationPayload = {
    __typename?: 'UpdateConfigurationPayload';
    configuration: Configuration;
};

export type CronJob = RelayNode & {
    __typename?: 'CronJob';
    id: Scalars['ID'];
    name: Scalars['String'];
    isActivated: Scalars['Boolean'];
    interval?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type CronJobEdge = {
    __typename?: 'CronJobEdge';
    node: CronJob;
};

export type CronJobConnection = {
    __typename?: 'CronJobConnection';
    totalCount: Scalars['Int'];
    edges: Array<CronJobEdge>;
    pageInfo: PageInfo;
};

export type CreateCronJobPayload = {
    __typename?: 'CreateCronJobPayload';
    cronJob: CronJob;
};

export type UpdateMultipleCronJobActivationsPayload = {
    __typename?: 'UpdateMultipleCronJobActivationsPayload';
    success: Scalars['Boolean'];
};

export type InternalShelf = RelayNode & {
    __typename?: 'InternalShelf';
    id: Scalars['ID'];
    name: Scalars['String'];
    internalWarehouseId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    packages?: Maybe<PackageConnection>;
    internalWarehouse: Warehouse;
};

export type InternalShelfEdge = {
    __typename?: 'InternalShelfEdge';
    node: InternalShelf;
};

export type InternalShelfConnection = {
    __typename?: 'InternalShelfConnection';
    totalCount: Scalars['Int'];
    edges: Array<InternalShelfEdge>;
    pageInfo: PageInfo;
};

export type CreateInternalShelfPayload = {
    __typename?: 'CreateInternalShelfPayload';
    shelf: InternalShelf;
};

export type DeleteInternalShelfPayload = {
    __typename?: 'DeleteInternalShelfPayload';
    id: Scalars['ID'];
};

export type UpdateInternalShelfPayload = {
    __typename?: 'UpdateInternalShelfPayload';
    shelf: InternalShelf;
};

export type Shelf = RelayNode & {
    __typename?: 'Shelf';
    id: Scalars['ID'];
    name: Scalars['String'];
    externalWarehouseId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    packages?: Maybe<PackageConnection>;
    externalWarehouse: ExternalWarehouse;
};

export type ShelfEdge = {
    __typename?: 'ShelfEdge';
    node: Shelf;
};

export type ShelfConnection = {
    __typename?: 'ShelfConnection';
    totalCount: Scalars['Int'];
    edges: Array<ShelfEdge>;
    pageInfo: PageInfo;
};

export type CreateShelfPayload = {
    __typename?: 'CreateShelfPayload';
    shelf: Shelf;
};

export type DeleteShelfPayload = {
    __typename?: 'DeleteShelfPayload';
    id: Scalars['String'];
};

export type UpdateShelfPayload = {
    __typename?: 'UpdateShelfPayload';
    shelf: Shelf;
};

export type Store = RelayNode & {
    __typename?: 'Store';
    id: Scalars['ID'];
    name: Scalars['String'];
    link?: Maybe<Scalars['String']>;
    isAutoCreated: Scalars['Boolean'];
    description?: Maybe<Scalars['String']>;
    isMerchantStore: Scalars['Boolean'];
    logoId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
    logo?: Maybe<FileInfo>;
    categories: CategoryConnection;
};

export type StoreEdge = {
    __typename?: 'StoreEdge';
    node: Store;
};

export type StoreConnection = {
    __typename?: 'StoreConnection';
    totalCount: Scalars['Int'];
    edges: Array<StoreEdge>;
    pageInfo: PageInfo;
};

export type CreateStorePayload = {
    __typename?: 'CreateStorePayload';
    store: Store;
};

export type DeleteStorePayload = {
    __typename?: 'DeleteStorePayload';
    id: Scalars['String'];
};

export type UpdateStorePayload = {
    __typename?: 'UpdateStorePayload';
    store: Store;
};

export type Tariff = RelayNode & {
    __typename?: 'Tariff';
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
    price: Scalars['Float'];
    type: PackageMaterialType;
    countryId: Scalars['String'];
    weightId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    weight: Weight;
    country: Region;
};

export enum PackageMaterialType {
    Default = 'DEFAULT',
    Liquid = 'LIQUID',
    Fragile = 'FRAGILE',
}

export type TariffEdge = {
    __typename?: 'TariffEdge';
    node: Tariff;
};

export type TariffConnection = {
    __typename?: 'TariffConnection';
    totalCount: Scalars['Int'];
    edges: Array<TariffEdge>;
    pageInfo: PageInfo;
};

export type CreateTariffPayload = {
    __typename?: 'CreateTariffPayload';
    tariff: Tariff;
};

export type DeleteTariffPayload = {
    __typename?: 'DeleteTariffPayload';
    id: Scalars['String'];
};

export type UpdateTariffPayload = {
    __typename?: 'UpdateTariffPayload';
    tariff: Tariff;
};

export type Warehouse = RelayNode & {
    __typename?: 'Warehouse';
    id: Scalars['ID'];
    name: Scalars['String'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    internalShelfs?: Maybe<InternalShelfConnection>;
};

export type InternalWarehouseEdge = {
    __typename?: 'InternalWarehouseEdge';
    node: Warehouse;
};

export type InternalWarehouseConnection = {
    __typename?: 'InternalWarehouseConnection';
    totalCount: Scalars['Int'];
    edges: Array<InternalWarehouseEdge>;
    pageInfo: PageInfo;
};

export type CreateInternalWarehousePayload = {
    __typename?: 'CreateInternalWarehousePayload';
    internalWarehouse: Warehouse;
};

export type DeleteInternalWarehousePayload = {
    __typename?: 'DeleteInternalWarehousePayload';
    id: Scalars['String'];
};

export type UpdateInternalWarehousePayload = {
    __typename?: 'UpdateInternalWarehousePayload';
    internalWarehouse: Warehouse;
};

export type Weight = RelayNode & {
    __typename?: 'Weight';
    id: Scalars['ID'];
    name: Scalars['String'];
    min: Scalars['Float'];
    max: Scalars['Float'];
    createdAt: Scalars['DateTime'];
};

export type WeightEdge = {
    __typename?: 'WeightEdge';
    node: Weight;
};

export type WeightConnection = {
    __typename?: 'WeightConnection';
    totalCount: Scalars['Int'];
    edges: Array<WeightEdge>;
    pageInfo: PageInfo;
};

export type DeleteWeightPayload = {
    __typename?: 'DeleteWeightPayload';
    id: Scalars['String'];
};

export type UpdateWeightPayload = {
    __typename?: 'UpdateWeightPayload';
    weight: Weight;
};

export type FileInfo = RelayNode & {
    __typename?: 'FileInfo';
    id: Scalars['ID'];
    objectName?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    url: Scalars['String'];
    size: Scalars['Int'];
    extension: Scalars['String'];
    blurhash?: Maybe<BlurhashMeta>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type FileInfoEdge = {
    __typename?: 'FileInfoEdge';
    node: FileInfo;
};

export type Package = RelayNode & {
    __typename?: 'Package';
    id: Scalars['ID'];
    status: PackageStatus;
    smartCustomStatus: PackageSmartCustomStatus;
    paymentStatus: PackagePaymentStatus;
    materialType: PackageMaterialType;
    count: Scalars['Int'];
    invoicePrice: Scalars['Float'];
    containerId?: Maybe<Scalars['String']>;
    flightId?: Maybe<Scalars['String']>;
    deliveryPrice?: Maybe<Scalars['Float']>;
    volumetricWeight?: Maybe<Scalars['Float']>;
    declaredValue?: Maybe<Scalars['Float']>;
    falkonTrackingNumber: Scalars['String'];
    storeTrackingNumber?: Maybe<Scalars['String']>;
    airWaybill?: Maybe<Scalars['String']>;
    parcelNumber?: Maybe<Scalars['String']>;
    regNumber?: Maybe<Scalars['String']>;
    packageNumber: Scalars['String'];
    width?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    length?: Maybe<Scalars['Float']>;
    weight?: Maybe<Scalars['Float']>;
    description?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    cargoCompany?: Maybe<Scalars['String']>;
    returnId?: Maybe<Scalars['String']>;
    customerId: Scalars['String'];
    countryId: Scalars['String'];
    storeId: Scalars['String'];
    categoryId: Scalars['String'];
    fileId?: Maybe<Scalars['String']>;
    createdById: Scalars['String'];
    updatedById?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    estimatedAt?: Maybe<Scalars['DateTime']>;
    returnedAt?: Maybe<Scalars['DateTime']>;
    purchaseOrderId?: Maybe<Scalars['String']>;
    invoicePriceUpdated: Scalars['Boolean'];
    version: Scalars['Int'];
    shelfId?: Maybe<Scalars['String']>;
    internalShelfId?: Maybe<Scalars['String']>;
    customer: Customer;
    country: Region;
    createdBy: User;
    updatedBy?: Maybe<User>;
    store: Store;
    category: Category;
    file?: Maybe<FileInfo>;
    statuses: PackageStatusHistoryConnection;
    externalShelf?: Maybe<Shelf>;
    internalShelf?: Maybe<InternalShelf>;
    purchaseOrder?: Maybe<PurchaseOrder>;
};

export enum PackageStatus {
    Pending = 'PENDING',
    InExternalStock = 'IN_EXTERNAL_STOCK',
    SentFromExternalStock = 'SENT_FROM_EXTERNAL_STOCK',
    InCustom = 'IN_CUSTOM',
    InLocalStock = 'IN_LOCAL_STOCK',
    InBranch = 'IN_BRANCH',
    HandedOver = 'HANDED_OVER',
    PendingReturn = 'PENDING_RETURN',
    Returned = 'RETURNED',
}

export enum PackageSmartCustomStatus {
    Pending = 'PENDING',
    Sent = 'SENT',
    Declared = 'DECLARED',
    AddedToBox = 'ADDED_TO_BOX',
    Depesh = 'DEPESH',
}

export enum PackagePaymentStatus {
    NotPaid = 'NOT_PAID',
    Paid = 'PAID',
    PaidByCash = 'PAID_BY_CASH',
}

export type PackageEdge = {
    __typename?: 'PackageEdge';
    node: Package;
};

export type PackageConnection = {
    __typename?: 'PackageConnection';
    totalCount: Scalars['Int'];
    edges: Array<PackageEdge>;
    pageInfo: PageInfo;
};

export type ExternalWarehouse = RelayNode & {
    __typename?: 'ExternalWarehouse';
    id: Scalars['ID'];
    name: Scalars['String'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    externalShelfs?: Maybe<ShelfConnection>;
};

export type ExternalWarehouseEdge = {
    __typename?: 'ExternalWarehouseEdge';
    node: ExternalWarehouse;
};

export type ExternalWarehouseConnection = {
    __typename?: 'ExternalWarehouseConnection';
    totalCount: Scalars['Int'];
    edges: Array<ExternalWarehouseEdge>;
    pageInfo: PageInfo;
};

export type Region = RelayNode & {
    __typename?: 'Region';
    id: Scalars['ID'];
    name: Scalars['String'];
    active: Scalars['Boolean'];
    aliases: Array<Scalars['String']>;
    currencies: CurrencyConnection;
};

export type RegionEdge = {
    __typename?: 'RegionEdge';
    node: Region;
};

export type RegionConnection = {
    __typename?: 'RegionConnection';
    totalCount: Scalars['Int'];
    edges: Array<RegionEdge>;
    pageInfo: PageInfo;
};

export type CreateWeightPayload = {
    __typename?: 'CreateWeightPayload';
    weight: Weight;
};

export type CreateExternalWarehousePayload = {
    __typename?: 'CreateExternalWarehousePayload';
    externalWarehouse: ExternalWarehouse;
};

export type UpdateExternalWarehousePayload = {
    __typename?: 'UpdateExternalWarehousePayload';
    externalWarehouse: ExternalWarehouse;
};

export type DeleteExternalWarehousePayload = {
    __typename?: 'DeleteExternalWarehousePayload';
    id: Scalars['String'];
};

export type User = RelayNode & {
    __typename?: 'User';
    id: Scalars['ID'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    emailVerified?: Maybe<Scalars['Boolean']>;
    customer?: Maybe<Customer>;
    manager?: Maybe<Manager>;
    roles: RoleConnection;
    bio?: Maybe<Scalars['String']>;
    fullName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    gender: Gender;
    lastLogin?: Maybe<Scalars['DateTime']>;
    avatar?: Maybe<FileInfo>;
};

export enum Gender {
    Male = 'MALE',
    Female = 'FEMALE',
}

export type UserEdge = {
    __typename?: 'UserEdge';
    node: User;
};

export type AccessTokenPayload = {
    __typename?: 'AccessTokenPayload';
    accessToken: Scalars['String'];
};

export type ActivateAccountPayload = {
    __typename?: 'ActivateAccountPayload';
    success: Scalars['Boolean'];
};

export type ChangePasswordPayload = {
    __typename?: 'ChangePasswordPayload';
    success: Scalars['Boolean'];
};

export type ForgotPasswordPayload = {
    __typename?: 'ForgotPasswordPayload';
    sent: Scalars['Boolean'];
};

export type ResetPasswordPayload = {
    __typename?: 'ResetPasswordPayload';
    success: Scalars['Boolean'];
};

export type SendVerifyEmailPayload = {
    __typename?: 'SendVerifyEmailPayload';
    sent: Scalars['Boolean'];
};

export type OtpForgotPasswordPayload = {
    __typename?: 'OtpForgotPasswordPayload';
    token: Scalars['String'];
};

export type OtpResetPasswordPayload = {
    __typename?: 'OtpResetPasswordPayload';
    success: Scalars['Boolean'];
};

export type BlogPost = RelayNode & {
    __typename?: 'BlogPost';
    id: Scalars['ID'];
    title: Scalars['String'];
    body: Scalars['String'];
    viewCount: Scalars['Int'];
    slug: Scalars['String'];
    tags?: Maybe<Array<Scalars['String']>>;
    authorId: Scalars['String'];
    coverImageId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    deletedAt: Scalars['DateTime'];
    coverImage: FileInfo;
    relatedBlogPosts: BlogPostConnection;
};

export type BlogPostEdge = {
    __typename?: 'BlogPostEdge';
    node: BlogPost;
};

export type BlogPostConnection = {
    __typename?: 'BlogPostConnection';
    totalCount: Scalars['Int'];
    edges: Array<BlogPostEdge>;
    pageInfo: PageInfo;
};

export type CreateBlogPostPayload = {
    __typename?: 'CreateBlogPostPayload';
    post: BlogPost;
};

export type DeleteBlogPostPayload = {
    __typename?: 'DeleteBlogPostPayload';
    id: Scalars['String'];
};

export type UploadBlogPostCoverImagePayload = {
    __typename?: 'UploadBlogPostCoverImagePayload';
    blogPost: BlogPost;
};

export type UpdateBlogPostPayload = {
    __typename?: 'UpdateBlogPostPayload';
    blogPost: BlogPost;
};

export type ViewBlogPostPayload = {
    __typename?: 'ViewBlogPostPayload';
    viewCount: Scalars['Int'];
};

export type Branch = RelayNode & {
    __typename?: 'Branch';
    id: Scalars['ID'];
    name: Scalars['String'];
    price: Scalars['Float'];
    address?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    logoId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    managers: ManagerConnection;
    logo?: Maybe<FileInfo>;
};

export type BranchEdge = {
    __typename?: 'BranchEdge';
    node: Branch;
};

export type BranchConnection = {
    __typename?: 'BranchConnection';
    totalCount: Scalars['Int'];
    edges: Array<BranchEdge>;
    pageInfo: PageInfo;
};

export type CreateBranchPayload = {
    __typename?: 'CreateBranchPayload';
    branch: Branch;
};

export type UpdateBranchPayload = {
    __typename?: 'UpdateBranchPayload';
    branch: Branch;
};

export type UploadBranchLogoPayload = {
    __typename?: 'UploadBranchLogoPayload';
    branch: Branch;
};

export type Manager = RelayNode & {
    __typename?: 'Manager';
    id: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    userId: Scalars['String'];
    avatarId?: Maybe<Scalars['String']>;
    coverPhotoId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    user: User;
};

export type ManagerEdge = {
    __typename?: 'ManagerEdge';
    node: Manager;
};

export type ManagerConnection = {
    __typename?: 'ManagerConnection';
    totalCount: Scalars['Int'];
    edges: Array<ManagerEdge>;
    pageInfo: PageInfo;
};

export type CustomerAddress = RelayNode & {
    __typename?: 'CustomerAddress';
    id: Scalars['ID'];
    title: Scalars['String'];
    city: Scalars['String'];
    deliveryZone: Scalars['String'];
    address: Scalars['String'];
    phoneNumber: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    isDefault: Scalars['Boolean'];
    customerId: Scalars['String'];
    createdAt: Scalars['DateTime'];
};

export type CustomerAddressEdge = {
    __typename?: 'CustomerAddressEdge';
    node: CustomerAddress;
};

export type CustomerAddressConnection = {
    __typename?: 'CustomerAddressConnection';
    totalCount: Scalars['Int'];
    edges: Array<CustomerAddressEdge>;
    pageInfo: PageInfo;
};

export type Bonus = RelayNode & {
    __typename?: 'Bonus';
    id: Scalars['ID'];
    type: BonusType;
    amount: Scalars['Float'];
    active: Scalars['Boolean'];
    weeklyLimit: Scalars['Float'];
    expiresAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
};

export enum BonusType {
    Register = 'REGISTER',
    Referral = 'REFERRAL',
}

export type BonusEdge = {
    __typename?: 'BonusEdge';
    node: Bonus;
};

export type Customer = RelayNode & {
    __typename?: 'Customer';
    id: Scalars['ID'];
    userId: Scalars['String'];
    code: Scalars['String'];
    balance: Scalars['Float'];
    idFin: Scalars['String'];
    idSerialNumber: Scalars['String'];
    createdAt: Scalars['DateTime'];
    isRegistered: Scalars['Boolean'];
    branches: BranchConnection;
    user: User;
    customerBonuses?: Maybe<CustomerBonusConnection>;
    deliveryAddresses?: Maybe<DeliveryAddressConnection>;
    courierOrders?: Maybe<CourierOrderConnection>;
    status?: Maybe<CustomerStatus>;
    balanceUSD: Scalars['Float'];
    balanceTRY: Scalars['Float'];
    totalBonusAmount: Scalars['Float'];
    mounthlyLimit: Scalars['Float'];
};

export enum CustomerStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export type CustomerEdge = {
    __typename?: 'CustomerEdge';
    node: Customer;
};

export type CustomerConnection = {
    __typename?: 'CustomerConnection';
    totalCount: Scalars['Int'];
    edges: Array<CustomerEdge>;
    pageInfo: PageInfo;
};

export type UpdateCustomerPayload = {
    __typename?: 'UpdateCustomerPayload';
    customer: Customer;
};

export type UpdateBonusPayload = {
    __typename?: 'UpdateBonusPayload';
    bonus: Bonus;
};

export type CreateCustomerAddressPayload = {
    __typename?: 'CreateCustomerAddressPayload';
    customerAddress: CustomerAddress;
};

export type CustomerBonus = RelayNode & {
    __typename?: 'CustomerBonus';
    id: Scalars['ID'];
    customerId: Scalars['String'];
    bonusId: Scalars['String'];
    isVerified: Scalars['Boolean'];
    amount: Scalars['Float'];
    createdAt: Scalars['DateTime'];
    referrerCustomerId?: Maybe<Scalars['String']>;
    customer?: Maybe<Customer>;
    referrerCustomer?: Maybe<Customer>;
    bonus?: Maybe<Bonus>;
};

export type CustomerBonusEdge = {
    __typename?: 'CustomerBonusEdge';
    node: CustomerBonus;
};

export type CustomerBonusConnection = {
    __typename?: 'CustomerBonusConnection';
    totalCount: Scalars['Int'];
    edges: Array<CustomerBonusEdge>;
    pageInfo: PageInfo;
};

export type UpdateCustomerByAdminPayload = {
    __typename?: 'UpdateCustomerByAdminPayload';
    customer: Customer;
};

export type CourierOrder = RelayNode & {
    __typename?: 'CourierOrder';
    id: Scalars['ID'];
    courierPrice: Scalars['Float'];
    packagePrice: Scalars['Float'];
    totalPrice: Scalars['Float'];
    customerId: Scalars['ID'];
    deliveryAddressId: Scalars['ID'];
    deliveryType: Scalars['String'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    customer: Customer;
    deliveryAddress: DeliveryAddress;
    packages: PackageConnection;
};

export type CourierOrderEdge = {
    __typename?: 'CourierOrderEdge';
    node: CourierOrder;
};

export type CourierOrderConnection = {
    __typename?: 'CourierOrderConnection';
    totalCount: Scalars['Int'];
    edges: Array<CourierOrderEdge>;
    pageInfo: PageInfo;
};

export type DeliveryAddress = RelayNode & {
    __typename?: 'DeliveryAddress';
    id: Scalars['ID'];
    title: Scalars['String'];
    deliveryCityId: Scalars['ID'];
    deliveryZoneId: Scalars['ID'];
    customerId: Scalars['ID'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    customer: Customer;
    deliveryZone: DeliveryZone;
    deliveryCity: DeliveryCity;
};

export type DeliveryAddressEdge = {
    __typename?: 'DeliveryAddressEdge';
    node: DeliveryAddress;
};

export type DeliveryAddressConnection = {
    __typename?: 'DeliveryAddressConnection';
    totalCount: Scalars['Int'];
    edges: Array<DeliveryAddressEdge>;
    pageInfo: PageInfo;
};

export type UploadUserAvatarPayload = {
    __typename?: 'UploadUserAvatarPayload';
    user: User;
};

export type Role = RelayNode & {
    __typename?: 'Role';
    id: Scalars['ID'];
    name: Scalars['String'];
    rolePermissions?: Maybe<RolePermissionConnection>;
};

export type RoleEdge = {
    __typename?: 'RoleEdge';
    node: Role;
};

export type RoleConnection = {
    __typename?: 'RoleConnection';
    totalCount: Scalars['Int'];
    edges: Array<RoleEdge>;
    pageInfo: PageInfo;
};

export type DeleteRolePayload = {
    __typename?: 'DeleteRolePayload';
    id: Scalars['String'];
};

export type CreateRolePayload = {
    __typename?: 'CreateRolePayload';
    role: Role;
};

export type UpdateRolePayload = {
    __typename?: 'UpdateRolePayload';
    role: Role;
};

export type UpdateUserSettingsPayload = {
    __typename?: 'UpdateUserSettingsPayload';
    result: Scalars['Boolean'];
};

export type Permission = RelayNode & {
    __typename?: 'Permission';
    id: Scalars['ID'];
    name: PermissionName;
    description: Scalars['String'];
    createdAt: Scalars['DateTime'];
    parameters?: Maybe<PermissionParameterConnection>;
};

export enum PermissionName {
    DashboardWiew = 'DASHBOARD_WIEW',
    CustomersWiew = 'CUSTOMERS_WIEW',
    CustomersCreate = 'CUSTOMERS_CREATE',
    CustomersUpdate = 'CUSTOMERS_UPDATE',
    CustomersDelete = 'CUSTOMERS_DELETE',
    PackagesView = 'PACKAGES_VIEW',
    PackagesCreate = 'PACKAGES_CREATE',
    PackagesUpdate = 'PACKAGES_UPDATE',
    PackagesDelete = 'PACKAGES_DELETE',
    PurchaseOrderView = 'PURCHASE_ORDER_VIEW',
    PurchaseOrderCreate = 'PURCHASE_ORDER_CREATE',
    PurchaseOrderUpdate = 'PURCHASE_ORDER_UPDATE',
    PurchaseOrderDelete = 'PURCHASE_ORDER_DELETE',
    CouriersView = 'COURIERS_VIEW',
    CouriersCreate = 'COURIERS_CREATE',
    CouriersUpdate = 'COURIERS_UPDATE',
    CouriersDelete = 'COURIERS_DELETE',
    SentBacksView = 'SENT_BACKS_VIEW',
    SentBacksCreate = 'SENT_BACKS_CREATE',
    SentBacksUpdate = 'SENT_BACKS_UPDATE',
    SentBacksDelete = 'SENT_BACKS_DELETE',
    TransactionsView = 'TRANSACTIONS_VIEW',
    TransactionsCreate = 'TRANSACTIONS_CREATE',
    TransactionsUpdate = 'TRANSACTIONS_UPDATE',
    TransactionsDelete = 'TRANSACTIONS_DELETE',
    ReportsView = 'REPORTS_VIEW',
    ReportsCreate = 'REPORTS_CREATE',
    ReportsUpdate = 'REPORTS_UPDATE',
    ReportsDelete = 'REPORTS_DELETE',
    StoresView = 'STORES_VIEW',
    StoresCreate = 'STORES_CREATE',
    StoresUpdate = 'STORES_UPDATE',
    StoresDelete = 'STORES_DELETE',
    MessagesView = 'MESSAGES_VIEW',
    MessagesCreate = 'MESSAGES_CREATE',
    MessagesUpdate = 'MESSAGES_UPDATE',
    MessagesDelete = 'MESSAGES_DELETE',
    RegistrationBonusesView = 'REGISTRATION_BONUSES_VIEW',
    RegistrationBonusesCreate = 'REGISTRATION_BONUSES_CREATE',
    RegistrationBonusesUpdate = 'REGISTRATION_BONUSES_UPDATE',
    RegistrationBonusesDelete = 'REGISTRATION_BONUSES_DELETE',
    ReferrerBonusesView = 'REFERRER_BONUSES_VIEW',
    ReferrerBonusesCreate = 'REFERRER_BONUSES_CREATE',
    ReferrerBonusesUpdate = 'REFERRER_BONUSES_UPDATE',
    ReferrerBonusesDelete = 'REFERRER_BONUSES_DELETE',
    CouponsView = 'COUPONS_VIEW',
    CouponsCreate = 'COUPONS_CREATE',
    CouponsUpdate = 'COUPONS_UPDATE',
    CouponsDelete = 'COUPONS_DELETE',
    GiftsView = 'GIFTS_VIEW',
    GiftsCreate = 'GIFTS_CREATE',
    GiftsUpdate = 'GIFTS_UPDATE',
    GiftsDelete = 'GIFTS_DELETE',
    CompanyEmailsView = 'COMPANY_EMAILS_VIEW',
    CompanyEmailsCreate = 'COMPANY_EMAILS_CREATE',
    CompanyEmailsUpdate = 'COMPANY_EMAILS_UPDATE',
    CompanyEmailsDelete = 'COMPANY_EMAILS_DELETE',
    SliderView = 'SLIDER_VIEW',
    SliderCreate = 'SLIDER_CREATE',
    SliderUpdate = 'SLIDER_UPDATE',
    SliderDelete = 'SLIDER_DELETE',
    CommonSettingsView = 'COMMON_SETTINGS_VIEW',
    CommonSettingsCreate = 'COMMON_SETTINGS_CREATE',
    CommonSettingsUpdate = 'COMMON_SETTINGS_UPDATE',
    CommonSettingsDelete = 'COMMON_SETTINGS_DELETE',
    CommentsView = 'COMMENTS_VIEW',
    CommentsCreate = 'COMMENTS_CREATE',
    CommentsUpdate = 'COMMENTS_UPDATE',
    CommentsDelete = 'COMMENTS_DELETE',
    BlogsView = 'BLOGS_VIEW',
    BlogsCreate = 'BLOGS_CREATE',
    BlogsUpdate = 'BLOGS_UPDATE',
    BlogsDelete = 'BLOGS_DELETE',
    EmailNotificationsView = 'EMAIL_NOTIFICATIONS_VIEW',
    EmailNotificationsCreate = 'EMAIL_NOTIFICATIONS_CREATE',
    EmailNotificationsUpdate = 'EMAIL_NOTIFICATIONS_UPDATE',
    EmailNotificationsDelete = 'EMAIL_NOTIFICATIONS_DELETE',
    SmsNotificationsView = 'SMS_NOTIFICATIONS_VIEW',
    SmsNotificationsCreate = 'SMS_NOTIFICATIONS_CREATE',
    SmsNotificationsUpdate = 'SMS_NOTIFICATIONS_UPDATE',
    SmsNotificationsDelete = 'SMS_NOTIFICATIONS_DELETE',
    ServicesView = 'SERVICES_VIEW',
    ServicesCreate = 'SERVICES_CREATE',
    ServicesUpdate = 'SERVICES_UPDATE',
    ServicesDelete = 'SERVICES_DELETE',
    FaqsView = 'FAQS_VIEW',
    FaqsCreate = 'FAQS_CREATE',
    FaqsUpdate = 'FAQS_UPDATE',
    FaqsDelete = 'FAQS_DELETE',
    TermsAndConditionsView = 'TERMS_AND_CONDITIONS_VIEW',
    TermsAndConditionsUpdate = 'TERMS_AND_CONDITIONS_UPDATE',
    HomePageView = 'HOME_PAGE_VIEW',
    HomePageUpdate = 'HOME_PAGE_UPDATE',
    AboutUsView = 'ABOUT_US_VIEW',
    AboutUsUpdate = 'ABOUT_US_UPDATE',
    BranchesView = 'BRANCHES_VIEW',
    BranchesCreate = 'BRANCHES_CREATE',
    BranchesUpdate = 'BRANCHES_UPDATE',
    BranchesDelete = 'BRANCHES_DELETE',
    UsersView = 'USERS_VIEW',
    UsersCreate = 'USERS_CREATE',
    UsersUpdate = 'USERS_UPDATE',
    UsersDelete = 'USERS_DELETE',
    CategoriesView = 'CATEGORIES_VIEW',
    CategoriesCreate = 'CATEGORIES_CREATE',
    CategoriesUpdate = 'CATEGORIES_UPDATE',
    CategoriesDelete = 'CATEGORIES_DELETE',
    TerminalUpdate = 'TERMINAL_UPDATE',
    DeliveryCitiesView = 'DELIVERY_CITIES_VIEW',
    DeliveryCitiesCreate = 'DELIVERY_CITIES_CREATE',
    DeliveryCitiesUpdate = 'DELIVERY_CITIES_UPDATE',
    DeliveryCitiesDelete = 'DELIVERY_CITIES_DELETE',
    DeliveryZonesView = 'DELIVERY_ZONES_VIEW',
    DeliveryZonesCreate = 'DELIVERY_ZONES_CREATE',
    DeliveryZonesUpdate = 'DELIVERY_ZONES_UPDATE',
    DeliveryZonesDelete = 'DELIVERY_ZONES_DELETE',
    ContentsView = 'CONTENTS_VIEW',
    ContentsCreate = 'CONTENTS_CREATE',
    ContentsUpdate = 'CONTENTS_UPDATE',
    ContentsDelete = 'CONTENTS_DELETE',
    ShelfView = 'SHELF_VIEW',
    ShelfCreate = 'SHELF_CREATE',
    ShelfUpdate = 'SHELF_UPDATE',
    ShelfDelete = 'SHELF_DELETE',
    FlightView = 'FLIGHT_VIEW',
    FlightCreate = 'FLIGHT_CREATE',
    FlightUpdate = 'FLIGHT_UPDATE',
    FlightDelete = 'FLIGHT_DELETE',
    FlightFinish = 'FLIGHT_FINISH',
    ContainerView = 'CONTAINER_VIEW',
    ContainerCreate = 'CONTAINER_CREATE',
    ContainerUpdate = 'CONTAINER_UPDATE',
    ContainerDelete = 'CONTAINER_DELETE',
    AddPackageToContainer = 'ADD_PACKAGE_TO_CONTAINER',
    RemovePackageFromContainer = 'REMOVE_PACKAGE_FROM_CONTAINER',
    ApplyingPackagesToCwb = 'APPLYING_PACKAGES_TO_CWB',
    RefreshExchangeRates = 'REFRESH_EXCHANGE_RATES',
    UpdateExchangeRates = 'UPDATE_EXCHANGE_RATES',
    CheckSmsBalance = 'CHECK_SMS_BALANCE',
    IncreaseBalanceInCash = 'INCREASE_BALANCE_IN_CASH',
    AssignPackageToShelf = 'ASSIGN_PACKAGE_TO_SHELF',
    Pay = 'PAY',
    MarketingUpdate = 'MARKETING_UPDATE',
}

export type PermissionEdge = {
    __typename?: 'PermissionEdge';
    node: Permission;
};

export type PermissionConnection = {
    __typename?: 'PermissionConnection';
    totalCount: Scalars['Int'];
    edges: Array<PermissionEdge>;
    pageInfo: PageInfo;
};

export type PermissionParameter = RelayNode & {
    __typename?: 'PermissionParameter';
    id: Scalars['ID'];
    description: Scalars['String'];
    permissionId: Scalars['String'];
    value: PermissionParameterValue;
    type: PermissionParameterType;
    createdAt: Scalars['DateTime'];
};

export enum PermissionParameterValue {
    All = 'ALL',
    Branch = 'BRANCH',
    Country = 'COUNTRY',
    User = 'USER',
}

export enum PermissionParameterType {
    String = 'STRING',
}

export type PermissionParameterEdge = {
    __typename?: 'PermissionParameterEdge';
    node: PermissionParameter;
};

export type PermissionParameterConnection = {
    __typename?: 'PermissionParameterConnection';
    totalCount: Scalars['Int'];
    edges: Array<PermissionParameterEdge>;
    pageInfo: PageInfo;
};

export type RolePermission = RelayNode & {
    __typename?: 'RolePermission';
    id: Scalars['ID'];
    roleId: Scalars['String'];
    permissionId: Scalars['String'];
    parameterId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    permission: Permission;
    parameter: PermissionParameter;
};

export type RolePermissionEdge = {
    __typename?: 'RolePermissionEdge';
    node: RolePermission;
};

export type RolePermissionConnection = {
    __typename?: 'RolePermissionConnection';
    totalCount: Scalars['Int'];
    edges: Array<RolePermissionEdge>;
    pageInfo: PageInfo;
};

export type UserPermission = RelayNode & {
    __typename?: 'UserPermission';
    id: Scalars['ID'];
    userId: Scalars['String'];
    permissionId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    user: User;
    permission: Permission;
    parameter: PermissionParameter;
};

export type UserPermissionEdge = {
    __typename?: 'UserPermissionEdge';
    node: UserPermission;
};

export type UserPermissionConnection = {
    __typename?: 'UserPermissionConnection';
    totalCount: Scalars['Int'];
    edges: Array<UserPermissionEdge>;
    pageInfo: PageInfo;
};

export type UserSetting = RelayNode & {
    __typename?: 'UserSetting';
    id: Scalars['ID'];
    userId: Scalars['String'];
    settingId: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type UserSettingEdge = {
    __typename?: 'UserSettingEdge';
    node: UserSetting;
};

export type UserSettingConnection = {
    __typename?: 'UserSettingConnection';
    totalCount: Scalars['Int'];
    edges: Array<UserSettingEdge>;
    pageInfo: PageInfo;
};

export type UpdateManagerByAdminPayload = {
    __typename?: 'UpdateManagerByAdminPayload';
    manager: Manager;
};

export type UpdateManagerPayload = {
    __typename?: 'UpdateManagerPayload';
    manager: Manager;
};

export type UpdatePackageByAdminPayload = {
    __typename?: 'UpdatePackageByAdminPayload';
    package: Package;
};

export type UpdatePackagePayload = {
    __typename?: 'UpdatePackagePayload';
    package: Package;
};

export type CreatePackageByAdminPayload = {
    __typename?: 'CreatePackageByAdminPayload';
    package: Package;
};

export type CreatePackagePayload = {
    __typename?: 'CreatePackagePayload';
    package: Package;
};

export type ReturnPackagePayload = {
    __typename?: 'ReturnPackagePayload';
    package: Package;
};

export type ConfirmReturnPackagePayload = {
    __typename?: 'ConfirmReturnPackagePayload';
    package: Package;
};

export type PackageCountByStatus = {
    __typename?: 'PackageCountByStatus';
    status: PackageStatus;
    count: Scalars['Int'];
};

export type PackageStatusHistory = RelayNode & {
    __typename?: 'PackageStatusHistory';
    id: Scalars['ID'];
    status: PackageStatus;
    createdById?: Maybe<Scalars['String']>;
    packageId: Scalars['String'];
    createdAt: Scalars['DateTime'];
};

export type PackageStatusHistoryEdge = {
    __typename?: 'PackageStatusHistoryEdge';
    node: PackageStatusHistory;
};

export type PackageStatusHistoryConnection = {
    __typename?: 'PackageStatusHistoryConnection';
    totalCount: Scalars['Int'];
    edges: Array<PackageStatusHistoryEdge>;
    pageInfo: PageInfo;
};

export type PurchaseOrder = RelayNode & {
    __typename?: 'PurchaseOrder';
    id: Scalars['ID'];
    purchaseOrderNumber: Scalars['String'];
    status: PurchaseOrderStatus;
    invoicePrice: Scalars['Float'];
    storeCargoPrice: Scalars['Float'];
    totalPrice: Scalars['Float'];
    expeditedServicePrice: Scalars['Float'];
    storeLink: Scalars['String'];
    storeId: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    count: Scalars['Int'];
    color?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['String']>;
    customerId: Scalars['String'];
    countryId?: Maybe<Scalars['String']>;
    createdById: Scalars['String'];
    updatedById?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
    customer: Customer;
    country: Region;
    createdBy: User;
    updatedBy?: Maybe<User>;
    store: Store;
    package?: Maybe<Package>;
    statuses: PurchaseOrderStatusHistoryConnection;
};

export enum PurchaseOrderStatus {
    PendingAdminApprove = 'PENDING_ADMIN_APPROVE',
    PendingPayment = 'PENDING_PAYMENT',
    Paid = 'PAID',
    HasBeenOrdered = 'HAS_BEEN_ORDERED',
    InExternalStock = 'IN_EXTERNAL_STOCK',
}

export type PurchaseOrderEdge = {
    __typename?: 'PurchaseOrderEdge';
    node: PurchaseOrder;
};

export type PurchaseOrderConnection = {
    __typename?: 'PurchaseOrderConnection';
    totalCount: Scalars['Int'];
    edges: Array<PurchaseOrderEdge>;
    pageInfo: PageInfo;
};

export type Payment = RelayNode & {
    __typename?: 'Payment';
    id: Scalars['ID'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    companySlug?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    status: PaymentStatus;
    date?: Maybe<Scalars['DateTime']>;
};

export enum PaymentStatus {
    Pending = 'PENDING',
    Paid = 'PAID',
    Cancelled = 'CANCELLED',
    Declined = 'DECLINED',
}

export type PaymentEdge = {
    __typename?: 'PaymentEdge';
    node: Payment;
};

export type UpdateExchangeRatePayload = {
    __typename?: 'UpdateExchangeRatePayload';
    success: Scalars['Boolean'];
};

export type Currency = RelayNode & {
    __typename?: 'Currency';
    id: Scalars['ID'];
    name: Scalars['String'];
    abbreviation: CurrencyAbbreviation;
    createdAt: Scalars['DateTime'];
    rate?: Maybe<Scalars['Float']>;
};

export enum CurrencyAbbreviation {
    Azn = 'AZN',
    Try = 'TRY',
    Usd = 'USD',
}

export type CurrencyEdge = {
    __typename?: 'CurrencyEdge';
    node: Currency;
};

export type CurrencyConnection = {
    __typename?: 'CurrencyConnection';
    totalCount: Scalars['Int'];
    edges: Array<CurrencyEdge>;
    pageInfo: PageInfo;
};

export type ExchangeRate = RelayNode & {
    __typename?: 'ExchangeRate';
    id: Scalars['ID'];
    to: CurrencyAbbreviation;
    from: CurrencyAbbreviation;
    rate: Scalars['Float'];
};

export type ExchangeRateEdge = {
    __typename?: 'ExchangeRateEdge';
    node: ExchangeRate;
};

export type ExchangeRateConnection = {
    __typename?: 'ExchangeRateConnection';
    totalCount: Scalars['Int'];
    edges: Array<ExchangeRateEdge>;
    pageInfo: PageInfo;
};

export type SalesOrderLine = RelayNode & {
    __typename?: 'SalesOrderLine';
    id: Scalars['ID'];
    type: SalesOrderLineType;
    salesOrderId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    packageId?: Maybe<Scalars['String']>;
    purchaseOrderId?: Maybe<Scalars['String']>;
    packageRefundId?: Maybe<Scalars['String']>;
    purchaseOrderRefundId?: Maybe<Scalars['String']>;
    package?: Maybe<Package>;
    purchaseOrder?: Maybe<PurchaseOrder>;
};

export enum SalesOrderLineType {
    Package = 'PACKAGE',
    PackageRefund = 'PACKAGE_REFUND',
    PurchaseOrder = 'PURCHASE_ORDER',
    CourierOrder = 'COURIER_ORDER',
    PurchaseOrderRefund = 'PURCHASE_ORDER_REFUND',
}

export type SalesOrderLineEdge = {
    __typename?: 'SalesOrderLineEdge';
    node: SalesOrderLine;
};

export type SalesOrderLineConnection = {
    __typename?: 'SalesOrderLineConnection';
    totalCount: Scalars['Int'];
    edges: Array<SalesOrderLineEdge>;
    pageInfo: PageInfo;
};

export type Transaction = RelayNode & {
    __typename?: 'Transaction';
    id: Scalars['ID'];
    customerId: Scalars['String'];
    amount: Scalars['Float'];
    type: TransactionType;
    status: PaymentStatus;
    createdAt: Scalars['DateTime'];
    salesOrderId?: Maybe<Scalars['String']>;
    sessionId?: Maybe<Scalars['String']>;
    orderId?: Maybe<Scalars['String']>;
    promoCode?: Maybe<Scalars['String']>;
    salesOrderLines?: Maybe<SalesOrderLineConnection>;
    customer: Customer;
};

export enum TransactionType {
    SalesOrderPayByCard = 'SALES_ORDER_PAY_BY_CARD',
    SalesOrderPayByCash = 'SALES_ORDER_PAY_BY_CASH',
    SalesOrderPayByBalance = 'SALES_ORDER_PAY_BY_BALANCE',
    SalesOrderPayByPromoCode = 'SALES_ORDER_PAY_BY_PROMO_CODE',
    BalanceTopUpByCard = 'BALANCE_TOP_UP_BY_CARD',
    BalanceTopUpInCash = 'BALANCE_TOP_UP_IN_CASH',
    BalanceRefund = 'BALANCE_REFUND',
    RegisterBonus = 'REGISTER_BONUS',
    ReferralBonus = 'REFERRAL_BONUS',
}

export type TransactionEdge = {
    __typename?: 'TransactionEdge';
    node: Transaction;
};

export type TransactionConnection = {
    __typename?: 'TransactionConnection';
    totalCount: Scalars['Int'];
    edges: Array<TransactionEdge>;
    pageInfo: PageInfo;
};

export type CreatePurchaseOrderByAdminPayload = {
    __typename?: 'CreatePurchaseOrderByAdminPayload';
    purchaseOrder: PurchaseOrder;
};

export type PurchaseOrderStatusHistory = RelayNode & {
    __typename?: 'PurchaseOrderStatusHistory';
    id: Scalars['ID'];
    status: PurchaseOrderStatus;
    createdById?: Maybe<Scalars['String']>;
    purchaseOrderId: Scalars['String'];
    createdAt: Scalars['DateTime'];
};

export type PurchaseOrderStatusHistoryEdge = {
    __typename?: 'PurchaseOrderStatusHistoryEdge';
    node: PurchaseOrderStatusHistory;
};

export type PurchaseOrderStatusHistoryConnection = {
    __typename?: 'PurchaseOrderStatusHistoryConnection';
    totalCount: Scalars['Int'];
    edges: Array<PurchaseOrderStatusHistoryEdge>;
    pageInfo: PageInfo;
};

export type UpdatePurchaseOrderByAdminPayload = {
    __typename?: 'UpdatePurchaseOrderByAdminPayload';
    purchaseOrder: PurchaseOrder;
};

export type CreatePurchaseOrdersPayload = {
    __typename?: 'CreatePurchaseOrdersPayload';
    purchaseOrders: Array<PurchaseOrder>;
};

export type UpdatePurchaseOrderPayload = {
    __typename?: 'UpdatePurchaseOrderPayload';
    purchaseOrder: PurchaseOrder;
};

export type Report = RelayNode & {
    __typename?: 'Report';
    id: Scalars['ID'];
    code: Scalars['String'];
    embedUrl: Scalars['String'];
};

export type ReportEdge = {
    __typename?: 'ReportEdge';
    node: Report;
};

export type ReportConnection = {
    __typename?: 'ReportConnection';
    totalCount: Scalars['Int'];
    edges: Array<ReportEdge>;
    pageInfo: PageInfo;
};

export type Message = RelayNode & {
    __typename?: 'Message';
    id: Scalars['ID'];
    name: Scalars['String'];
    email: Scalars['String'];
    message: Scalars['String'];
    createdAt: Scalars['DateTime'];
    isRead: Scalars['Boolean'];
    isAnswered: Scalars['Boolean'];
    code?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['String']>;
    children?: Maybe<MessageConnection>;
};

export type MessageEdge = {
    __typename?: 'MessageEdge';
    node: Message;
};

export type MessageConnection = {
    __typename?: 'MessageConnection';
    totalCount: Scalars['Int'];
    edges: Array<MessageEdge>;
    pageInfo: PageInfo;
};

export type CreateMessagePayload = {
    __typename?: 'CreateMessagePayload';
    message: Message;
};

export type DeleteMessagePayload = {
    __typename?: 'DeleteMessagePayload';
    id: Scalars['String'];
};

export type MarkAsReadPayload = {
    __typename?: 'MarkAsReadPayload';
    success: Scalars['Boolean'];
};

export type PromoCode = RelayNode & {
    __typename?: 'PromoCode';
    id: Scalars['ID'];
    code: Scalars['String'];
    type: Scalars['String'];
    value: Scalars['String'];
    usageLimit?: Maybe<Scalars['Int']>;
    usedCount: Scalars['Int'];
    scope: Array<PromoCodeScope>;
    maxAmount?: Maybe<Scalars['Int']>;
    maxItem?: Maybe<Scalars['Int']>;
    expiresAt?: Maybe<Scalars['DateTime']>;
    isActive: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export enum PromoCodeScope {
    Package = 'PACKAGE',
    Order = 'ORDER',
    Courier = 'COURIER',
}

export type PromoCodeEdge = {
    __typename?: 'PromoCodeEdge';
    node: PromoCode;
};

export type PromoCodeConnection = {
    __typename?: 'PromoCodeConnection';
    totalCount: Scalars['Int'];
    edges: Array<PromoCodeEdge>;
    pageInfo: PageInfo;
};

export type CreatePromoCodeByAdminPayload = {
    __typename?: 'CreatePromoCodeByAdminPayload';
    promoCode: PromoCode;
};

export type UpdatePromoCodeByAdminPayload = {
    __typename?: 'UpdatePromoCodeByAdminPayload';
    promoCode: PromoCode;
};

export type CreateCourierOrderByAdminPayload = {
    __typename?: 'CreateCourierOrderByAdminPayload';
    courierOrder: CourierOrder;
};

export type CreateCourierOrderPayload = {
    __typename?: 'CreateCourierOrderPayload';
    courierOrder: CourierOrder;
    redirectURL: Scalars['String'];
};

export type DeleteCourierOrderPayload = {
    __typename?: 'DeleteCourierOrderPayload';
    id: Scalars['String'];
};

export type CreateDeliveryAddressByAdminPayload = {
    __typename?: 'CreateDeliveryAddressByAdminPayload';
    deliveryAddress: DeliveryAddress;
};

export type CreateDeliveryAddressPayload = {
    __typename?: 'CreateDeliveryAddressPayload';
    deliveryAddress: DeliveryAddress;
};

export type DeleteDeliveryAddressByAdminPayload = {
    __typename?: 'DeleteDeliveryAddressByAdminPayload';
    id: Scalars['String'];
};

export type DeleteDeliveryAddressPayload = {
    __typename?: 'DeleteDeliveryAddressPayload';
    id: Scalars['String'];
};

export type UpdateDeliveryAddressByAdminPayload = {
    __typename?: 'UpdateDeliveryAddressByAdminPayload';
    deliveryAddress: DeliveryAddress;
};

export type UpdateDeliveryAddressPayload = {
    __typename?: 'UpdateDeliveryAddressPayload';
    deliveryAddress: DeliveryAddress;
};

export type DeliveryCity = RelayNode & {
    __typename?: 'DeliveryCity';
    id: Scalars['ID'];
    name: Scalars['String'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    deliveryZones: DeliveryZoneConnection;
};

export type DeliveryCityEdge = {
    __typename?: 'DeliveryCityEdge';
    node: DeliveryCity;
};

export type DeliveryCityConnection = {
    __typename?: 'DeliveryCityConnection';
    totalCount: Scalars['Int'];
    edges: Array<DeliveryCityEdge>;
    pageInfo: PageInfo;
};

export type DeleteDeliveryCityPayload = {
    __typename?: 'DeleteDeliveryCityPayload';
    id: Scalars['ID'];
};

export type UpdateDeliveryCityPayload = {
    __typename?: 'UpdateDeliveryCityPayload';
    deliveryCity: DeliveryCity;
};

export type DeliveryZone = RelayNode & {
    __typename?: 'DeliveryZone';
    id: Scalars['ID'];
    name: Scalars['String'];
    standartPrice: Scalars['Float'];
    expressPrice: Scalars['Float'];
    deliveryCityId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type DeliveryZoneEdge = {
    __typename?: 'DeliveryZoneEdge';
    node: DeliveryZone;
};

export type DeliveryZoneConnection = {
    __typename?: 'DeliveryZoneConnection';
    totalCount: Scalars['Int'];
    edges: Array<DeliveryZoneEdge>;
    pageInfo: PageInfo;
};

export type CreateDeliveryZonePayload = {
    __typename?: 'CreateDeliveryZonePayload';
    deliveryZone: DeliveryZone;
};

export type DeleteDeliveryZonePayload = {
    __typename?: 'DeleteDeliveryZonePayload';
    id: Scalars['String'];
};

export type UpdateDeliveryZonePayload = {
    __typename?: 'UpdateDeliveryZonePayload';
    deliveryZone: DeliveryZone;
};

export type UpdateCourierOrderByAdminPayload = {
    __typename?: 'UpdateCourierOrderByAdminPayload';
    courierOrder: CourierOrder;
};

export type AssignPackagesToCourierOrderByAdminPayload = {
    __typename?: 'AssignPackagesToCourierOrderByAdminPayload';
    courierOrder: CourierOrder;
};

export type CreateDeliveryCityPayload = {
    __typename?: 'CreateDeliveryCityPayload';
    deliveryCity: DeliveryCity;
};

export type Container = RelayNode & {
    __typename?: 'Container';
    id: Scalars['ID'];
    name: Scalars['String'];
    flightId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
    packages: PackageConnection;
    flight: Flight;
    totalWeight: Scalars['Float'];
    packageCount: Scalars['Float'];
};

export type ContainerEdge = {
    __typename?: 'ContainerEdge';
    node: Container;
};

export type ContainerConnection = {
    __typename?: 'ContainerConnection';
    totalCount: Scalars['Int'];
    edges: Array<ContainerEdge>;
    pageInfo: PageInfo;
};

export type AssignPackagesToContainerPayload = {
    __typename?: 'AssignPackagesToContainerPayload';
    container: Container;
};

export type CreateContainerPayload = {
    __typename?: 'CreateContainerPayload';
    container: Container;
};

export type DeleteContainerPayload = {
    __typename?: 'DeleteContainerPayload';
    id: Scalars['String'];
};

export type UnAssignPackageFromContainerPayload = {
    __typename?: 'UnAssignPackageFromContainerPayload';
    packageId: Scalars['String'];
    containerId: Scalars['String'];
};

export type UpdateContainerPayload = {
    __typename?: 'UpdateContainerPayload';
    container: Container;
};

export type Flight = RelayNode & {
    __typename?: 'Flight';
    id: Scalars['ID'];
    name: Scalars['String'];
    cwb?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    completedAt?: Maybe<Scalars['DateTime']>;
    version: Scalars['Int'];
    packages: PackageConnection;
    containers: ContainerConnection;
    totalWeight: Scalars['Float'];
    packageCount: Scalars['Float'];
    containerCount: Scalars['Float'];
};

export type FlightEdge = {
    __typename?: 'FlightEdge';
    node: Flight;
};

export type FlightConnection = {
    __typename?: 'FlightConnection';
    totalCount: Scalars['Int'];
    edges: Array<FlightEdge>;
    pageInfo: PageInfo;
};

export type ApplyingPackagesToCwbPayload = {
    __typename?: 'ApplyingPackagesToCwbPayload';
    flight: Flight;
};

export type CreateFlightPayload = {
    __typename?: 'CreateFlightPayload';
    flight: Flight;
};

export type DeleteFlightPayload = {
    __typename?: 'DeleteFlightPayload';
    id: Scalars['String'];
};

export type FinishFlightPayload = {
    __typename?: 'FinishFlightPayload';
    flight: Flight;
};

export type UpdateFlightPayload = {
    __typename?: 'UpdateFlightPayload';
    flight: Flight;
};

export type PackageInput = {
    id: Scalars['ID'];
    status: PackageStatus;
    smartCustomStatus: PackageSmartCustomStatus;
    paymentStatus: PackagePaymentStatus;
    materialType: PackageMaterialType;
    count: Scalars['Int'];
    invoicePrice: Scalars['Float'];
    containerId?: Maybe<Scalars['String']>;
    flightId?: Maybe<Scalars['String']>;
    deliveryPrice?: Maybe<Scalars['Float']>;
    volumetricWeight?: Maybe<Scalars['Float']>;
    declaredValue?: Maybe<Scalars['Float']>;
    falkonTrackingNumber: Scalars['String'];
    storeTrackingNumber?: Maybe<Scalars['String']>;
    airWaybill?: Maybe<Scalars['String']>;
    parcelNumber?: Maybe<Scalars['String']>;
    regNumber?: Maybe<Scalars['String']>;
    packageNumber: Scalars['String'];
    width?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    length?: Maybe<Scalars['Float']>;
    weight?: Maybe<Scalars['Float']>;
    description?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    cargoCompany?: Maybe<Scalars['String']>;
    returnId?: Maybe<Scalars['String']>;
    customerId: Scalars['String'];
    countryId: Scalars['String'];
    storeId: Scalars['String'];
    categoryId: Scalars['String'];
    fileId?: Maybe<Scalars['String']>;
    createdById: Scalars['String'];
    updatedById?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    estimatedAt?: Maybe<Scalars['DateTime']>;
    returnedAt?: Maybe<Scalars['DateTime']>;
    purchaseOrderId?: Maybe<Scalars['String']>;
    invoicePriceUpdated: Scalars['Boolean'];
    version: Scalars['Int'];
    shelfId?: Maybe<Scalars['String']>;
    internalShelfId?: Maybe<Scalars['String']>;
};

export type ContainerInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    flightId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type FlightInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    cwb?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    completedAt?: Maybe<Scalars['DateTime']>;
    version: Scalars['Int'];
};

export type Query = {
    __typename?: 'Query';
    me?: Maybe<User>;
    user: User;
    fileInfo: FileInfo;
    customers?: Maybe<CustomerConnection>;
    customer?: Maybe<Customer>;
    myBonuses: CustomerBonusConnection;
    myAddresses: CustomerAddressConnection;
    roles?: Maybe<RoleConnection>;
    role?: Maybe<Role>;
    permissions?: Maybe<PermissionConnection>;
    userPermissions?: Maybe<UserPermissionConnection>;
    selectedSettings: UserSettingConnection;
    stores: StoreConnection;
    store: Store;
    categories: CategoryConnection;
    category: Category;
    weight: Weight;
    weights: WeightConnection;
    tariff: Tariff;
    tariffs: TariffConnection;
    configurations: ConfigurationConnection;
    configuration: Configuration;
    shelf: Shelf;
    shelfs: ShelfConnection;
    internalShelf: InternalShelf;
    internalShelfs: InternalShelfConnection;
    cronJobs: CronJobConnection;
    internalWarehouse: Warehouse;
    internalWarehouses: InternalWarehouseConnection;
    externalWarehouse: ExternalWarehouse;
    externalWarehouses: ExternalWarehouseConnection;
    branches: BranchConnection;
    branch: Branch;
    manager: Manager;
    managers: ManagerConnection;
    region: Region;
    regions: RegionConnection;
    package: Package;
    packagesByAdmin: PackageConnection;
    packages: PackageConnection;
    returningPackages: PackageConnection;
    packageCountsByStatuses: Array<PackageCountByStatus>;
    purchaseOrder: PurchaseOrder;
    purchaseOrdersByAdmin: PurchaseOrderConnection;
    purchaseOrders: PurchaseOrderConnection;
    purchaseOrdersCount: Scalars['Int'];
    checkSMSBalance: Scalars['Float'];
    transactions: TransactionConnection;
    exchangeRates: ExchangeRateConnection;
    blogPosts: BlogPostConnection;
    blogPost: BlogPost;
    messages: MessageConnection;
    message: Message;
    dasboards: ReportConnection;
    reports: ReportConnection;
    promoCodeByAdmin: PromoCode;
    promoCodesByAdmin: PromoCodeConnection;
    deliveryZone: DeliveryZone;
    deliveryZones: DeliveryZoneConnection;
    deliveryCity: DeliveryCity;
    deliveryCities: DeliveryCityConnection;
    deliveryAddressByAdmin: DeliveryAddress;
    deliveryAddressesByAdmin: DeliveryAddressConnection;
    deliveryAddress: DeliveryAddress;
    deliveryAddresses: DeliveryAddressConnection;
    courierOrderByAdmin: CourierOrder;
    courierOrdersByAdmin: CourierOrderConnection;
    courierOrder: CourierOrder;
    courierOrders: CourierOrderConnection;
    container: Container;
    containers: ContainerConnection;
    flight: Flight;
    flights: FlightConnection;
};

export type QueryUserArgs = {
    id: Scalars['String'];
};

export type QueryFileInfoArgs = {
    id: Scalars['String'];
};

export type QueryCustomersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<CustomerWhereInput>;
    orderBy?: Maybe<CustomerOrderBy>;
};

export type QueryCustomerArgs = {
    id: Scalars['String'];
};

export type QueryMyBonusesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<CustomerBonusOrderBy>;
    where?: Maybe<CustomerBonusWhereInput>;
};

export type QueryMyAddressesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<CustomerAddressOrderBy>;
    where?: Maybe<CustomerAddressWhereInput>;
};

export type QueryRoleArgs = {
    id: Scalars['String'];
};

export type QueryUserPermissionsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where: UserPermissionWhereInput;
};

export type QueryStoresArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<StoreWhereInput>;
    orderBy?: Maybe<StoreOrderBy>;
};

export type QueryStoreArgs = {
    id: Scalars['String'];
};

export type QueryCategoriesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<CategoryWhereInput>;
};

export type QueryCategoryArgs = {
    id: Scalars['String'];
};

export type QueryWeightArgs = {
    id: Scalars['String'];
};

export type QueryWeightsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<WeightWhereInput>;
    orderBy?: Maybe<WeightOrderBy>;
};

export type QueryTariffArgs = {
    id: Scalars['String'];
};

export type QueryTariffsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<TariffWhereInput>;
    orderBy?: Maybe<TariffOrderBy>;
};

export type QueryConfigurationArgs = {
    id: Scalars['String'];
};

export type QueryShelfArgs = {
    id: Scalars['String'];
};

export type QueryShelfsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ShelfWhereInput>;
    orderBy?: Maybe<ShelfOrderBy>;
};

export type QueryInternalShelfArgs = {
    id: Scalars['String'];
};

export type QueryInternalShelfsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<InternalShelfWhereInput>;
    orderBy?: Maybe<InternalShelfOrderBy>;
};

export type QueryInternalWarehouseArgs = {
    id: Scalars['String'];
};

export type QueryInternalWarehousesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<InternalWarehouseWhereInput>;
    orderBy?: Maybe<InternalWarehouseOrderBy>;
};

export type QueryExternalWarehouseArgs = {
    id: Scalars['String'];
};

export type QueryExternalWarehousesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ExternalWarehouseWhereInput>;
    orderBy?: Maybe<ExternalWarehouseOrderBy>;
};

export type QueryBranchesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type QueryBranchArgs = {
    id: Scalars['String'];
};

export type QueryManagerArgs = {
    id: Scalars['String'];
};

export type QueryManagersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ManagerWhereInput>;
    orderBy?: Maybe<ManagerOrderBy>;
};

export type QueryRegionArgs = {
    id: Scalars['String'];
};

export type QueryRegionsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<RegionWhereInput>;
};

export type QueryPackageArgs = {
    id: Scalars['String'];
};

export type QueryPackagesByAdminArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PackageWhereInput>;
    orderBy?: Maybe<PackageOrderBy>;
};

export type QueryPackagesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PackageWhereInput>;
    orderBy?: Maybe<PackageOrderBy>;
};

export type QueryReturningPackagesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PackageWhereInput>;
    orderBy?: Maybe<PackageOrderBy>;
};

export type QueryPurchaseOrderArgs = {
    id: Scalars['String'];
};

export type QueryPurchaseOrdersByAdminArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PurchaseOrderWhereInput>;
    orderBy?: Maybe<PurchaseOrderOrderBy>;
};

export type QueryPurchaseOrdersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PurchaseOrderWhereInput>;
    orderBy?: Maybe<PurchaseOrderOrderBy>;
};

export type QueryTransactionsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<CustomerTransactionOrderBy>;
    where?: Maybe<TransactionWhereInput>;
};

export type QueryBlogPostsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<BlogPostWhereInput>;
    orderBy?: Maybe<BlogPostOrderBy>;
};

export type QueryBlogPostArgs = {
    id?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type QueryMessagesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<MessageWhereInput>;
    orderBy?: Maybe<MessageOrderBy>;
};

export type QueryMessageArgs = {
    id: Scalars['String'];
};

export type QueryPromoCodeByAdminArgs = {
    id: GetPromoCodeByAdminInput;
};

export type QueryPromoCodesByAdminArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PromoCodeWhereInput>;
    orderBy?: Maybe<PromoCodeOrderBy>;
};

export type QueryDeliveryZoneArgs = {
    id: Scalars['String'];
};

export type QueryDeliveryZonesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<DeliveryZoneWhereInput>;
    orderBy?: Maybe<DeliveryZoneOrderBy>;
};

export type QueryDeliveryCityArgs = {
    id: Scalars['String'];
};

export type QueryDeliveryCitiesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<DeliveryCityWhereInput>;
    orderBy?: Maybe<DeliveryCityOrderBy>;
};

export type QueryDeliveryAddressByAdminArgs = {
    id: Scalars['String'];
};

export type QueryDeliveryAddressesByAdminArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<DeliveryAddressWhereInput>;
    orderBy?: Maybe<DeliveryAddressOrderBy>;
};

export type QueryDeliveryAddressArgs = {
    id: Scalars['String'];
};

export type QueryDeliveryAddressesArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<DeliveryAddressWhereInput>;
    orderBy?: Maybe<DeliveryAddressOrderBy>;
};

export type QueryCourierOrderByAdminArgs = {
    id: Scalars['String'];
};

export type QueryCourierOrdersByAdminArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<CourierOrderWhereInput>;
    orderBy?: Maybe<CourierOrderOrderBy>;
};

export type QueryCourierOrderArgs = {
    id: Scalars['String'];
};

export type QueryCourierOrdersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<CourierOrderWhereInput>;
    orderBy?: Maybe<CourierOrderOrderBy>;
};

export type QueryContainerArgs = {
    id: Scalars['String'];
};

export type QueryContainersArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ContainerWhereInput>;
    orderBy?: Maybe<ContainerOrderBy>;
};

export type QueryFlightArgs = {
    id: Scalars['String'];
};

export type QueryFlightsArgs = {
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<FlightWhereInput>;
    orderBy?: Maybe<FlightOrderBy>;
};

export type CustomerWhereInput = {
    customerId?: Maybe<Scalars['String']>;
    customerCode?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    keyword?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
    idSerialNumber?: Maybe<Scalars['String']>;
    idFin?: Maybe<Scalars['String']>;
    referralCode?: Maybe<Scalars['String']>;
    branchId?: Maybe<Scalars['String']>;
    isRegistered?: Maybe<Scalars['Boolean']>;
    status?: Maybe<CustomerStatus>;
    registrationDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
    lastLoginDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
};

export type DateRangeWhereInput = {
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
};

export enum CustomerOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export enum CustomerBonusOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type CustomerBonusWhereInput = {
    keyword?: Maybe<Scalars['String']>;
};

export enum CustomerAddressOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type CustomerAddressWhereInput = {
    title?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    isDefault?: Maybe<Scalars['Boolean']>;
};

export type UserPermissionWhereInput = {
    userId: Scalars['String'];
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export type StoreWhereInput = {
    categoryIds?: Maybe<Array<Scalars['String']>>;
    searchText?: Maybe<Scalars['String']>;
    isAutoCreated?: Maybe<Scalars['Boolean']>;
    isMerchantStore?: Maybe<Scalars['Boolean']>;
};

export enum StoreOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
}

export type CategoryWhereInput = {
    smartCustoms?: Maybe<Scalars['Boolean']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export type WeightWhereInput = {
    keyword: Scalars['String'];
};

export enum WeightOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
}

export type TariffWhereInput = {
    keyword?: Maybe<Scalars['String']>;
};

export enum TariffOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type ShelfWhereInput = {
    keyword?: Maybe<Scalars['String']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export enum ShelfOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type InternalShelfWhereInput = {
    keyword?: Maybe<Scalars['String']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export enum InternalShelfOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type InternalWarehouseWhereInput = {
    name?: Maybe<Scalars['String']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export enum InternalWarehouseOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
}

export type ExternalWarehouseWhereInput = {
    name?: Maybe<Scalars['String']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export enum ExternalWarehouseOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
}

export type ManagerWhereInput = {
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    role?: Maybe<Scalars['String']>;
};

export enum ManagerOrderBy {
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
}

export type RegionWhereInput = {
    all?: Maybe<Scalars['Boolean']>;
};

export type PackageWhereInput = {
    customerCode?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    branchId?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['String']>;
    keyword?: Maybe<Scalars['String']>;
    status?: Maybe<PackageStatus>;
    smartCustomStatus?: Maybe<PackageSmartCustomStatus>;
    paymentStatus?: Maybe<PackagePaymentStatus>;
    airWaybill?: Maybe<Scalars['String']>;
    parcelNumber?: Maybe<Scalars['String']>;
    isReturned?: Maybe<Scalars['Boolean']>;
    packageNumber?: Maybe<Scalars['String']>;
    regNumber?: Maybe<Scalars['String']>;
    trackingNumber?: Maybe<Scalars['String']>;
    storeTrackingNumber?: Maybe<Scalars['String']>;
    invoicePriceUpdated?: Maybe<Scalars['Boolean']>;
    createdAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
    shelfId?: Maybe<Scalars['String']>;
    isGetAll?: Maybe<Scalars['Boolean']>;
};

export enum PackageOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type PurchaseOrderWhereInput = {
    customerCode?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    status?: Maybe<PurchaseOrderStatus>;
    createdAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
};

export enum PurchaseOrderOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export enum CustomerTransactionOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type TransactionWhereInput = {
    timestamp?: Maybe<DateRangeWhereInput>;
    transactionType?: Maybe<TransactionType>;
    salesOrderLineType?: Maybe<SalesOrderLineType>;
    status?: Maybe<PaymentStatus>;
    customerCode?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['String']>;
    promoCode?: Maybe<Scalars['String']>;
};

export type BlogPostWhereInput = {
    tags?: Maybe<Array<Scalars['String']>>;
    title?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
};

export enum BlogPostOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
}

export type MessageWhereInput = {
    keyword?: Maybe<Scalars['String']>;
};

export enum MessageOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type GetPromoCodeByAdminInput = {
    id: Scalars['String'];
};

export type PromoCodeWhereInput = {
    code?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export enum PromoCodeOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    ExpiresAtAsc = 'EXPIRES_AT_ASC',
    ExpiresAtDesc = 'EXPIRES_AT_DESC',
    ValueAsc = 'VALUE_ASC',
    ValueDesc = 'VALUE_DESC',
    UsedCountAsc = 'USED_COUNT_ASC',
    UsedCountDesc = 'USED_COUNT_DESC',
}

export type DeliveryZoneWhereInput = {
    name?: Maybe<Scalars['String']>;
    standartPriceRangeWhereInput?: Maybe<PriceRangeWhereInput>;
    expressPriceRangeWhereInput?: Maybe<PriceRangeWhereInput>;
    deliveryCityId?: Maybe<Scalars['String']>;
    createdAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
};

export type PriceRangeWhereInput = {
    fromPrice?: Maybe<Scalars['Float']>;
    toPrice?: Maybe<Scalars['Float']>;
};

export enum DeliveryZoneOrderBy {
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
    StandartPriceAsc = 'STANDART_PRICE_ASC',
    StandartPriceDesc = 'STANDART_PRICE_DESC',
    ExpressPriceAsc = 'EXPRESS_PRICE_ASC',
    ExpressPriceDesc = 'EXPRESS_PRICE_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type DeliveryCityWhereInput = {
    name?: Maybe<Scalars['String']>;
};

export enum DeliveryCityOrderBy {
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type DeliveryAddressWhereInput = {
    title?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    building?: Maybe<Scalars['String']>;
    customerId?: Maybe<Scalars['String']>;
};

export enum DeliveryAddressOrderBy {
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
    StreetAsc = 'STREET_ASC',
    StreetDesc = 'STREET_DESC',
    BuildingAsc = 'BUILDING_ASC',
    BuildingDesc = 'BUILDING_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type CourierOrderWhereInput = {
    customerId?: Maybe<Scalars['String']>;
    deliveryAddressId?: Maybe<Scalars['String']>;
    deliveryType?: Maybe<DeliveryType>;
    courierPriceRangeWhereInput?: Maybe<PriceRangeWhereInput>;
    packagePriceRangeWhereInput?: Maybe<PriceRangeWhereInput>;
    totalPriceRangeWhereInput?: Maybe<PriceRangeWhereInput>;
    createdAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
};

export enum DeliveryType {
    Standart = 'STANDART',
    Express = 'EXPRESS',
}

export enum CourierOrderOrderBy {
    CourierPriceAsc = 'COURIER_PRICE_ASC',
    CourierPriceDesc = 'COURIER_PRICE_DESC',
    PackagePriceAsc = 'PACKAGE_PRICE_ASC',
    PackagePriceDesc = 'PACKAGE_PRICE_DESC',
    TotalPriceAsc = 'TOTAL_PRICE_ASC',
    TotalPriceDesc = 'TOTAL_PRICE_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type ContainerWhereInput = {
    name?: Maybe<Scalars['String']>;
    flightId?: Maybe<Scalars['String']>;
    weightRangeWhereInput?: Maybe<WeightRangeWhereInput>;
    packageCountRangeWhereInput?: Maybe<PackageCountRangeWhereInput>;
    createdAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
};

export type WeightRangeWhereInput = {
    fromWeight?: Maybe<Scalars['Float']>;
    toWeight?: Maybe<Scalars['Float']>;
};

export type PackageCountRangeWhereInput = {
    fromPackageCount?: Maybe<Scalars['Float']>;
    toPackageCount?: Maybe<Scalars['Float']>;
};

export enum ContainerOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type FlightWhereInput = {
    name?: Maybe<Scalars['String']>;
    weightRangeWhereInput?: Maybe<WeightRangeWhereInput>;
    containerCountRangeWhereInput?: Maybe<ContainerCountRangeWhereInput>;
    packageCountRangeWhereInput?: Maybe<PackageCountRangeWhereInput>;
    createdAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
    completedAtDateRangeWhereInput?: Maybe<DateRangeWhereInput>;
};

export type ContainerCountRangeWhereInput = {
    fromContainerCount?: Maybe<Scalars['Float']>;
    toContainerCount?: Maybe<Scalars['Float']>;
};

export enum FlightOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type Mutation = {
    __typename?: 'Mutation';
    registerCustomer: Scalars['String'];
    registerCustomerByAdmin: Scalars['String'];
    completeSocialRegistration: Scalars['String'];
    registerManager: Scalars['String'];
    /**
     * {
     *
     *     "credentials": {
     *         "email": "customer@gmail.com",
     *         "password": "Customer123!"
     *     }
     *
     *     "credentials": {
     *         "email": "admin@falkon.az",
     *         "password": "@dm1nP@ssL0CAL"
     *     }
     *
     *   }
     */
    login: AccessTokenPayload;
    facebookLogin: AccessTokenPayload;
    googleLogin: AccessTokenPayload;
    sendVerificationEmail: SendVerifyEmailPayload;
    activateAccount: ActivateAccountPayload;
    sendPasswordResetEmail: ForgotPasswordPayload;
    resetPassword: ResetPasswordPayload;
    sendOtpPasswordResetEmail: OtpForgotPasswordPayload;
    OtpresetPassword: OtpResetPasswordPayload;
    changePassword: ChangePasswordPayload;
    updateCustomer: UpdateCustomerPayload;
    updateCustomerByAdmin: UpdateCustomerByAdminPayload;
    deleteCustomers: Scalars['Boolean'];
    updateBonus: UpdateBonusPayload;
    createCustomerAddress: CreateCustomerAddressPayload;
    updateCustomerAddress: CreateCustomerAddressPayload;
    uploadUserAvatar: UploadUserAvatarPayload;
    createRole: CreateRolePayload;
    updateRole: UpdateRolePayload;
    deleteRole: DeleteRolePayload;
    updateUserSettings: UpdateUserSettingsPayload;
    updateUserSetting: UpdateUserSettingsPayload;
    createStore: CreateStorePayload;
    updateStore: UpdateStorePayload;
    deleteStore: DeleteStorePayload;
    createCategory: CreateCategoryPayload;
    updateCategory: CreateCategoryPayload;
    deleteCategory: DeleteCategoryPayload;
    createWeight: CreateWeightPayload;
    updateWeight: UpdateWeightPayload;
    deleteWeight: DeleteWeightPayload;
    createTariff: CreateTariffPayload;
    updateTariff: UpdateTariffPayload;
    deleteTariff: DeleteTariffPayload;
    updateConfiguration: UpdateConfigurationPayload;
    createShelf: CreateShelfPayload;
    deleteShelf: DeleteShelfPayload;
    updateShelf: UpdateShelfPayload;
    createInternalShelf: CreateInternalShelfPayload;
    deleteInternalShelf: DeleteInternalShelfPayload;
    updateInternalShelf: UpdateInternalShelfPayload;
    createCronJobyAdmin: CreateCronJobPayload;
    updateMultipleCronJobActivations: UpdateMultipleCronJobActivationsPayload;
    createInternalWarehouse: CreateInternalWarehousePayload;
    updateInternalWarehouse: UpdateInternalWarehousePayload;
    deleteInternalWarehouse: DeleteInternalWarehousePayload;
    createExternalWarehouse: CreateExternalWarehousePayload;
    updateExternalWarehouse: UpdateExternalWarehousePayload;
    deleteExternalWarehouse: DeleteExternalWarehousePayload;
    createBranch: CreateBranchPayload;
    updateBranch: UpdateBranchPayload;
    uploadBranchLogo: UploadBranchLogoPayload;
    updateManager: UpdateManagerPayload;
    updateManagerByAdmin: UpdateManagerByAdminPayload;
    deleteManagers: Scalars['Boolean'];
    createPackage: CreatePackagePayload;
    createPackageByAdmin: CreatePackageByAdminPayload;
    updatePackageByAdmin: UpdatePackageByAdminPayload;
    updatePackage: UpdatePackagePayload;
    deletePackages: Scalars['Boolean'];
    deletePackagesByAdmin: Scalars['Boolean'];
    bulkPackageSendSMS: Scalars['Boolean'];
    updateBulkPackageGeneralStatus: Scalars['Boolean'];
    updateBulkPackageInvoicePriceUpdated: Scalars['Boolean'];
    updateBulkPackageDeliveryDate: Scalars['Boolean'];
    returnPackage: ReturnPackagePayload;
    confirmReturnPackage: ConfirmReturnPackagePayload;
    updateBulkPackageParcelNumber: Scalars['Boolean'];
    updateBulkPackageShelf: Scalars['Boolean'];
    updateBulkPackageInternalShelf: Scalars['Boolean'];
    exportManifest: Scalars['String'];
    createPurchaseOrderByAdmin: CreatePurchaseOrderByAdminPayload;
    createPurchaseOrders: CreatePurchaseOrdersPayload;
    updatePurchaseOrderByAdmin: UpdatePurchaseOrderByAdminPayload;
    updatePurchaseOrder: UpdatePurchaseOrderPayload;
    deletePurchaseOrdersByAdmin: Scalars['Boolean'];
    deletePurchaseOrders: Scalars['Boolean'];
    bulkPurchaseOrderSendSMS: Scalars['Boolean'];
    increaseBalanceByCard: Scalars['String'];
    increaseBalanceInCash: Scalars['String'];
    pay: Scalars['String'];
    payByAdmin: Scalars['Boolean'];
    refundByAdmin: Scalars['Boolean'];
    refreshExchangeRate: Scalars['Boolean'];
    updateExchangeRate: UpdateExchangeRatePayload;
    createPost: CreateBlogPostPayload;
    updateBlogPost: UpdateBlogPostPayload;
    deleteBlogPost: DeleteBlogPostPayload;
    uploadBlogPostCoverImage: UploadBlogPostCoverImagePayload;
    viewBlogPost: ViewBlogPostPayload;
    sendToSmartCustom: Scalars['Boolean'];
    removeDeclaration: Scalars['Boolean'];
    checkToSmartCustomsStatus: Scalars['Boolean'];
    addToBox: Scalars['Boolean'];
    cancelAddToBox: Scalars['Boolean'];
    cancelDepesh: Scalars['Boolean'];
    depesh: Scalars['Boolean'];
    createMessage: CreateMessagePayload;
    deleteMessage: DeleteMessagePayload;
    markAsRead: MarkAsReadPayload;
    createPromoCodeByAdmin: CreatePromoCodeByAdminPayload;
    updatePromoCodeByAdmin: UpdatePromoCodeByAdminPayload;
    createDeliveryZone: CreateDeliveryZonePayload;
    updateDeliveryZone: UpdateDeliveryZonePayload;
    deleteDeliveryZone: DeleteDeliveryZonePayload;
    createDeliveryCity: CreateDeliveryCityPayload;
    updateDeliveryCity: UpdateDeliveryCityPayload;
    deleteDeliveryCity: DeleteDeliveryCityPayload;
    createDeliveryAddressByAdmin: CreateDeliveryAddressByAdminPayload;
    updateDeliveryAddressByAdmin: UpdateDeliveryAddressByAdminPayload;
    deleteDeliveryAddressByAdmin: DeleteDeliveryAddressByAdminPayload;
    createDeliveryAddress: CreateDeliveryAddressPayload;
    updateDeliveryAddress: UpdateDeliveryAddressPayload;
    deleteDeliveryAddress: DeleteDeliveryAddressPayload;
    createCourierOrder: CreateCourierOrderPayload;
    deleteCourierByAdmin: DeleteCourierOrderPayload;
    createCourierOrderByAdmin: CreateCourierOrderByAdminPayload;
    updateCourierOrderByAdmin: UpdateCourierOrderByAdminPayload;
    assignPackagesToCourierOrderByAdmin: AssignPackagesToCourierOrderByAdminPayload;
    createContainer: CreateContainerPayload;
    assignPackagesToContainer: AssignPackagesToContainerPayload;
    deleteContainer: DeleteContainerPayload;
    unAssignPackageFromContainer: UnAssignPackageFromContainerPayload;
    updateContainer: UpdateContainerPayload;
    createFlight: CreateFlightPayload;
    updateFlight: UpdateFlightPayload;
    applyingPackagesToCwb: ApplyingPackagesToCwbPayload;
    deleteFlight: DeleteFlightPayload;
    finishFlight: FinishFlightPayload;
};

export type MutationRegisterCustomerArgs = {
    customer: RegisterCustomerInput;
};

export type MutationRegisterCustomerByAdminArgs = {
    customer: RegisterCustomerByAdminInput;
};

export type MutationCompleteSocialRegistrationArgs = {
    input: CompleteSocialRegistrationInput;
};

export type MutationRegisterManagerArgs = {
    manager: RegisterManagerInput;
};

export type MutationLoginArgs = {
    credentials: CredentialsInput;
};

export type MutationSendVerificationEmailArgs = {
    input: SendVerifyEmailInput;
};

export type MutationActivateAccountArgs = {
    input: ActivateAccountInput;
};

export type MutationSendPasswordResetEmailArgs = {
    input: ForgotPasswordInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationSendOtpPasswordResetEmailArgs = {
    input: OtpForgotPasswordInput;
};

export type MutationOtpresetPasswordArgs = {
    input: OtpResetPasswordInput;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationUpdateCustomerArgs = {
    customer: UpdateCustomerInput;
};

export type MutationUpdateCustomerByAdminArgs = {
    customer: UpdateCustomerByAdminInput;
};

export type MutationDeleteCustomersArgs = {
    input: DeleteCustomersInput;
};

export type MutationUpdateBonusArgs = {
    bonus: UpdateBonusInput;
};

export type MutationCreateCustomerAddressArgs = {
    input: CreateCustomerAddressInput;
};

export type MutationUpdateCustomerAddressArgs = {
    input: UpdateCustomerAddressInput;
};

export type MutationUploadUserAvatarArgs = {
    input: UploadUserAvatarInput;
};

export type MutationCreateRoleArgs = {
    input: CreateRoleInput;
};

export type MutationUpdateRoleArgs = {
    input: UpdateRoleInput;
};

export type MutationDeleteRoleArgs = {
    id: Scalars['String'];
};

export type MutationUpdateUserSettingsArgs = {
    input: UpdateUserSettingsInput;
};

export type MutationUpdateUserSettingArgs = {
    input: UpdateUserSettingInput;
};

export type MutationCreateStoreArgs = {
    store: CreateStoreInput;
};

export type MutationUpdateStoreArgs = {
    store: UpdateStoreInput;
};

export type MutationDeleteStoreArgs = {
    id: Scalars['String'];
};

export type MutationCreateCategoryArgs = {
    category: CreateCategoryInput;
};

export type MutationUpdateCategoryArgs = {
    category: UpdateCategoryInput;
};

export type MutationDeleteCategoryArgs = {
    id: Scalars['String'];
};

export type MutationCreateWeightArgs = {
    weight: CreateWeightInput;
};

export type MutationUpdateWeightArgs = {
    weight: UpdateWeightInput;
};

export type MutationDeleteWeightArgs = {
    id: Scalars['String'];
};

export type MutationCreateTariffArgs = {
    tariff: CreateTariffInput;
};

export type MutationUpdateTariffArgs = {
    tariff: UpdateTariffInput;
};

export type MutationDeleteTariffArgs = {
    id: Scalars['String'];
};

export type MutationUpdateConfigurationArgs = {
    input: UpdateConfigurationInput;
};

export type MutationCreateShelfArgs = {
    input: CreateShelfInput;
};

export type MutationDeleteShelfArgs = {
    id: Scalars['String'];
};

export type MutationUpdateShelfArgs = {
    shelf: UpdateShelfInput;
};

export type MutationCreateInternalShelfArgs = {
    input: CreateInternalShelfInput;
};

export type MutationDeleteInternalShelfArgs = {
    id: Scalars['String'];
};

export type MutationUpdateInternalShelfArgs = {
    shelf: UpdateInternalShelfInput;
};

export type MutationCreateCronJobyAdminArgs = {
    input: CreateCronJobInput;
};

export type MutationUpdateMultipleCronJobActivationsArgs = {
    input: UpdateMultipleCronJobActivationsInput;
};

export type MutationCreateInternalWarehouseArgs = {
    input: CreateInternalWarehouseInput;
};

export type MutationUpdateInternalWarehouseArgs = {
    input: UpdateInternalWarehouseInput;
};

export type MutationDeleteInternalWarehouseArgs = {
    input: DeleteInternalWarehouseInput;
};

export type MutationCreateExternalWarehouseArgs = {
    input: CreateExternalWarehouseInput;
};

export type MutationUpdateExternalWarehouseArgs = {
    input: UpdateExternalWarehouseInput;
};

export type MutationDeleteExternalWarehouseArgs = {
    id: Scalars['String'];
};

export type MutationCreateBranchArgs = {
    branch: CreateBranchInput;
};

export type MutationUpdateBranchArgs = {
    branch: UpdateBranchInput;
};

export type MutationUploadBranchLogoArgs = {
    branch: UploadBranchLogoInput;
};

export type MutationUpdateManagerArgs = {
    manager: UpdateManagerInput;
};

export type MutationUpdateManagerByAdminArgs = {
    manager: UpdateManagerByAdminInput;
};

export type MutationDeleteManagersArgs = {
    input: DeleteManagersInput;
};

export type MutationCreatePackageArgs = {
    input: CreatePackageInput;
};

export type MutationCreatePackageByAdminArgs = {
    input: CreatePackageByAdminInput;
};

export type MutationUpdatePackageByAdminArgs = {
    input: UpdatePackageByAdminInput;
};

export type MutationUpdatePackageArgs = {
    input: UpdatePackageInput;
};

export type MutationDeletePackagesArgs = {
    input: DeletePackagesInput;
};

export type MutationDeletePackagesByAdminArgs = {
    input: DeletePackagesInput;
};

export type MutationBulkPackageSendSmsArgs = {
    input: BulkPackageSendSmsInput;
};

export type MutationUpdateBulkPackageGeneralStatusArgs = {
    input: UpdateMultiplePackageGeneralStatusInput;
};

export type MutationUpdateBulkPackageInvoicePriceUpdatedArgs = {
    input: UpdateBulkPackageInvoicePriceUpdatedInput;
};

export type MutationUpdateBulkPackageDeliveryDateArgs = {
    input: UpdateMultiplePackageDeliveryDateInput;
};

export type MutationReturnPackageArgs = {
    input: ReturnPackageInput;
};

export type MutationConfirmReturnPackageArgs = {
    input: ConfirmReturnPackageInput;
};

export type MutationUpdateBulkPackageParcelNumberArgs = {
    input: UpdateBulkPackageParcelNumberInput;
};

export type MutationUpdateBulkPackageShelfArgs = {
    input: UpdateBulkPackageShelfInput;
};

export type MutationUpdateBulkPackageInternalShelfArgs = {
    input: UpdateBulkPackageInternalShelfInput;
};

export type MutationExportManifestArgs = {
    input: ExportManifestInput;
};

export type MutationCreatePurchaseOrderByAdminArgs = {
    input: CreatePurchaseOrderByAdminInput;
};

export type MutationCreatePurchaseOrdersArgs = {
    input: CreatePurchaseOrdersInput;
};

export type MutationUpdatePurchaseOrderByAdminArgs = {
    input: UpdatePurchaseOrderByAdminInput;
};

export type MutationUpdatePurchaseOrderArgs = {
    input: UpdatePurchaseOrderInput;
};

export type MutationDeletePurchaseOrdersByAdminArgs = {
    input: DeletePurchaseOrdersInput;
};

export type MutationDeletePurchaseOrdersArgs = {
    input: DeletePurchaseOrdersInput;
};

export type MutationBulkPurchaseOrderSendSmsArgs = {
    input: BulkPurchaseOrderSendSmsInput;
};

export type MutationIncreaseBalanceByCardArgs = {
    input: IncreaseBalanceByCardInput;
};

export type MutationIncreaseBalanceInCashArgs = {
    input: IncreaseBalanceInCashInput;
};

export type MutationPayArgs = {
    input: PayInput;
};

export type MutationPayByAdminArgs = {
    input: PayByAdminInput;
};

export type MutationRefundByAdminArgs = {
    input: RefundByAdminInput;
};

export type MutationUpdateExchangeRateArgs = {
    input: UpdateExchangeRateInput;
};

export type MutationCreatePostArgs = {
    blogPost: CreateBlogPostInput;
};

export type MutationUpdateBlogPostArgs = {
    blogPost: UpdateBlogPostInput;
};

export type MutationDeleteBlogPostArgs = {
    id: Scalars['String'];
};

export type MutationUploadBlogPostCoverImageArgs = {
    blogPost: UploadBlogPostCoverImageInput;
};

export type MutationViewBlogPostArgs = {
    blogPost: ViewBlogPostInput;
};

export type MutationSendToSmartCustomArgs = {
    input: SendToSmartCustomsInput;
};

export type MutationRemoveDeclarationArgs = {
    input: RemoveDeclarationInput;
};

export type MutationCheckToSmartCustomsStatusArgs = {
    input: CheckToSmartCustomsStatusInput;
};

export type MutationAddToBoxArgs = {
    input: AddToBoxInput;
};

export type MutationCancelAddToBoxArgs = {
    input: CancelAddToBoxInput;
};

export type MutationCancelDepeshArgs = {
    input: CancelDepeshInput;
};

export type MutationDepeshArgs = {
    input: DepeshInput;
};

export type MutationCreateMessageArgs = {
    message: CreateMessageInput;
};

export type MutationDeleteMessageArgs = {
    id: Scalars['String'];
};

export type MutationMarkAsReadArgs = {
    input: MarkAsReadInput;
};

export type MutationCreatePromoCodeByAdminArgs = {
    input: CreatePromoCodeByAdminInput;
};

export type MutationUpdatePromoCodeByAdminArgs = {
    input: UpdatePromoCodeByAdminInput;
};

export type MutationCreateDeliveryZoneArgs = {
    input: CreateDeliveryZoneInput;
};

export type MutationUpdateDeliveryZoneArgs = {
    input: UpdateDeliveryZoneInput;
};

export type MutationDeleteDeliveryZoneArgs = {
    input: DeleteDeliveryZoneInput;
};

export type MutationCreateDeliveryCityArgs = {
    input: CreateDeliveryCityInput;
};

export type MutationUpdateDeliveryCityArgs = {
    input: UpdateDeliveryCityInput;
};

export type MutationDeleteDeliveryCityArgs = {
    input: DeleteDeliveryCityInput;
};

export type MutationCreateDeliveryAddressByAdminArgs = {
    input: CreateDeliveryAddressByAdminInput;
};

export type MutationUpdateDeliveryAddressByAdminArgs = {
    input: UpdateDeliveryAddressByAdminInput;
};

export type MutationDeleteDeliveryAddressByAdminArgs = {
    input: DeleteDeliveryAddressByAdminInput;
};

export type MutationCreateDeliveryAddressArgs = {
    input: CreateDeliveryAddressInput;
};

export type MutationUpdateDeliveryAddressArgs = {
    input: UpdateDeliveryAddressInput;
};

export type MutationDeleteDeliveryAddressArgs = {
    input: DeleteDeliveryAddressInput;
};

export type MutationCreateCourierOrderArgs = {
    input: CreateCourierOrderInput;
};

export type MutationDeleteCourierByAdminArgs = {
    input: DeleteCourierOrderInput;
};

export type MutationCreateCourierOrderByAdminArgs = {
    input: CreateCourierOrderByAdminInput;
};

export type MutationUpdateCourierOrderByAdminArgs = {
    input: UpdateCourierOrderByAdminInput;
};

export type MutationAssignPackagesToCourierOrderByAdminArgs = {
    input: AssignPackagesToCourierOrderByAdminInput;
};

export type MutationCreateContainerArgs = {
    input: CreateContainerInput;
};

export type MutationAssignPackagesToContainerArgs = {
    input: AssignPackagesToContainerInput;
};

export type MutationDeleteContainerArgs = {
    input: DeleteContainerInput;
};

export type MutationUnAssignPackageFromContainerArgs = {
    input: UnAssignPackageFromContainerInput;
};

export type MutationUpdateContainerArgs = {
    input: UpdateContainerInput;
};

export type MutationCreateFlightArgs = {
    input: CreateFlightInput;
};

export type MutationUpdateFlightArgs = {
    input: UpdateFlightInput;
};

export type MutationApplyingPackagesToCwbArgs = {
    input: ApplyingPackagesToCwbInput;
};

export type MutationDeleteFlightArgs = {
    input: DeleteFlightInput;
};

export type MutationFinishFlightArgs = {
    input: FinishFlightInput;
};

export type RegisterCustomerInput = {
    branchId: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
    idFin: Scalars['String'];
    idSerialNumber: Scalars['String'];
    phoneNumber: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    gender: Gender;
    referrerCustomerCode?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
};

export type RegisterCustomerByAdminInput = {
    branchId: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
    idFin: Scalars['String'];
    idSerialNumber: Scalars['String'];
    phoneNumber: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    gender: Gender;
    emailVerified: Scalars['Boolean'];
    referrerCustomerCode?: Maybe<Scalars['String']>;
    referrerCustomerId?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
};

export type CompleteSocialRegistrationInput = {
    idFin: Scalars['String'];
    idSerialNumber: Scalars['String'];
    phoneNumber: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    gender: Gender;
    address: Scalars['String'];
    branchId: Scalars['String'];
};

export type RegisterManagerInput = {
    branchId: Scalars['String'];
    countryId: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
    phoneNumber: Scalars['String'];
    gender: Gender;
    roleIds: Array<Scalars['String']>;
};

export type CredentialsInput = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export type SendVerifyEmailInput = {
    email: Scalars['String'];
};

export type ActivateAccountInput = {
    token: Scalars['String'];
};

export type ForgotPasswordInput = {
    email: Scalars['String'];
};

export type ResetPasswordInput = {
    token: Scalars['String'];
    password: Scalars['String'];
};

export type OtpForgotPasswordInput = {
    email: Scalars['String'];
};

export type OtpResetPasswordInput = {
    token: Scalars['String'];
    password: Scalars['String'];
    code: Scalars['String'];
};

export type ChangePasswordInput = {
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
};

export type UpdateCustomerInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    idFin: Scalars['String'];
    idSerialNumber: Scalars['String'];
    phoneNumber: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    branchId?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    gender: Gender;
};

export type UpdateCustomerByAdminInput = {
    customerId: Scalars['ID'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    idFin: Scalars['String'];
    idSerialNumber: Scalars['String'];
    phoneNumber: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    gender: Gender;
    emailVerified?: Maybe<Scalars['Boolean']>;
    branchId?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
};

export type DeleteCustomersInput = {
    ids: Array<Scalars['String']>;
};

export type UpdateBonusInput = {
    id: Scalars['ID'];
    active: Scalars['Boolean'];
    expiresAt: Scalars['DateTime'];
    amount: Scalars['Float'];
    weeklyLimit: Scalars['Float'];
};

export type CreateCustomerAddressInput = {
    title: Scalars['String'];
    city: Scalars['String'];
    deliveryZone: Scalars['String'];
    address: Scalars['String'];
    phoneNumber: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    isDefault: Scalars['Boolean'];
};

export type UpdateCustomerAddressInput = {
    id: Scalars['String'];
    title: Scalars['String'];
    city: Scalars['String'];
    deliveryZone: Scalars['String'];
    address: Scalars['String'];
    phoneNumber: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    isDefault: Scalars['Boolean'];
};

export type UploadUserAvatarInput = {
    avatar: Scalars['Upload'];
};

export type CreateRoleInput = {
    name: Scalars['String'];
    permissions: Array<PermissionInput>;
};

export type PermissionInput = {
    permissionId: Scalars['String'];
    parameterId: Scalars['String'];
};

export type UpdateRoleInput = {
    id: Scalars['String'];
    name: Scalars['String'];
    permissions: Array<PermissionInput>;
};

export type UpdateUserSettingsInput = {
    settings: Array<UpdateUserSettingInput>;
};

export type UpdateUserSettingInput = {
    settingId: Scalars['String'];
    value: Scalars['String'];
};

export type CreateStoreInput = {
    name: Scalars['String'];
    link?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    isMerchantStore?: Maybe<Scalars['Boolean']>;
    logo?: Maybe<Scalars['Upload']>;
    categoryIds?: Maybe<Array<Scalars['String']>>;
    isAutoCreated: Scalars['Boolean'];
};

export type UpdateStoreInput = {
    id: Scalars['String'];
    name: Scalars['String'];
    link: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isMerchantStore: Scalars['Boolean'];
    logo?: Maybe<Scalars['Upload']>;
    categoryIds: Array<Scalars['String']>;
};

export type CreateCategoryInput = {
    parentId?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    icon?: Maybe<Scalars['Upload']>;
    color: Scalars['String'];
};

export type UpdateCategoryInput = {
    id: Scalars['String'];
    name: Scalars['String'];
    icon?: Maybe<Scalars['Upload']>;
    color: Scalars['String'];
};

export type CreateWeightInput = {
    name: Scalars['String'];
    min: Scalars['Float'];
    max: Scalars['Float'];
};

export type UpdateWeightInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    min: Scalars['Float'];
    max: Scalars['Float'];
};

export type CreateTariffInput = {
    countryId: Scalars['String'];
    isActive: Scalars['Boolean'];
    price: Scalars['Float'];
    type: PackageMaterialType;
    weightId: Scalars['String'];
};

export type UpdateTariffInput = {
    id: Scalars['String'];
    countryId: Scalars['String'];
    isActive: Scalars['Boolean'];
    price: Scalars['Float'];
    type: PackageMaterialType;
    weightId: Scalars['String'];
};

export type UpdateConfigurationInput = {
    id: Scalars['String'];
    price: Scalars['Float'];
};

export type CreateShelfInput = {
    name: Scalars['String'];
    externalWarehouseId: Scalars['String'];
};

export type UpdateShelfInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    externalWarehouseId: Scalars['String'];
};

export type CreateInternalShelfInput = {
    name: Scalars['String'];
    internalWarehouseId: Scalars['ID'];
};

export type UpdateInternalShelfInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    internalWarehouseId: Scalars['ID'];
};

export type CreateCronJobInput = {
    name: Scalars['String'];
    isActivated: Scalars['Boolean'];
    interval?: Maybe<Scalars['String']>;
};

export type UpdateMultipleCronJobActivationsInput = {
    cronJobs: Array<UpdateSingleCronJobActivationInput>;
};

export type UpdateSingleCronJobActivationInput = {
    id: Scalars['String'];
    isActivated: Scalars['Boolean'];
};

export type CreateInternalWarehouseInput = {
    name: Scalars['String'];
};

export type UpdateInternalWarehouseInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type DeleteInternalWarehouseInput = {
    id: Scalars['ID'];
};

export type CreateExternalWarehouseInput = {
    name: Scalars['String'];
};

export type UpdateExternalWarehouseInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type CreateBranchInput = {
    name: Scalars['String'];
    address?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    price: Scalars['Float'];
    logo?: Maybe<Scalars['Upload']>;
};

export type UpdateBranchInput = {
    id: Scalars['String'];
    address?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    price: Scalars['Float'];
    name: Scalars['String'];
};

export type UploadBranchLogoInput = {
    branchId: Scalars['String'];
    logo: Scalars['Upload'];
};

export type UpdateManagerInput = {
    countryId: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    phoneNumber: Scalars['String'];
    bio?: Maybe<Scalars['String']>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    gender?: Maybe<Scalars['String']>;
};

export type UpdateManagerByAdminInput = {
    id: Scalars['ID'];
    countryId: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    phoneNumber: Scalars['String'];
    bio?: Maybe<Scalars['String']>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    gender?: Maybe<Scalars['String']>;
    roleIds: Array<Scalars['String']>;
};

export type DeleteManagersInput = {
    ids: Array<Scalars['String']>;
};

export type CreatePackageInput = {
    materialType: PackageMaterialType;
    count: Scalars['Int'];
    invoicePrice: Scalars['Float'];
    storeTrackingNumber: Scalars['String'];
    storeName: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    categoryId: Scalars['String'];
    file?: Maybe<Scalars['Upload']>;
};

export type CreatePackageByAdminInput = {
    status?: Maybe<PackageStatus>;
    materialType: PackageMaterialType;
    count: Scalars['Int'];
    invoicePrice?: Maybe<Scalars['Float']>;
    storeTrackingNumber: Scalars['String'];
    storeName: Scalars['String'];
    width?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    length?: Maybe<Scalars['Float']>;
    weight?: Maybe<Scalars['Float']>;
    description?: Maybe<Scalars['String']>;
    customerId: Scalars['String'];
    countryId: Scalars['String'];
    categoryId: Scalars['String'];
    estimatedAt?: Maybe<Scalars['DateTime']>;
    file?: Maybe<Scalars['Upload']>;
    invoicePriceUpdated: Scalars['Boolean'];
};

export type UpdatePackageByAdminInput = {
    id: Scalars['String'];
    customerId: Scalars['String'];
    status: PackageStatus;
    airWaybill?: Maybe<Scalars['String']>;
    parcelNumber?: Maybe<Scalars['String']>;
    materialType: PackageMaterialType;
    count: Scalars['Int'];
    invoicePrice: Scalars['Float'];
    storeTrackingNumber: Scalars['String'];
    storeName: Scalars['String'];
    width?: Maybe<Scalars['Float']>;
    height?: Maybe<Scalars['Float']>;
    length?: Maybe<Scalars['Float']>;
    weight?: Maybe<Scalars['Float']>;
    description?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    cargoCompany?: Maybe<Scalars['String']>;
    returnId?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    categoryId: Scalars['String'];
    estimatedAt?: Maybe<Scalars['DateTime']>;
    file?: Maybe<Scalars['Upload']>;
};

export type UpdatePackageInput = {
    id: Scalars['String'];
    materialType: PackageMaterialType;
    count: Scalars['Int'];
    invoicePrice: Scalars['Float'];
    storeTrackingNumber: Scalars['String'];
    storeName: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    cargoCompany?: Maybe<Scalars['String']>;
    returnId?: Maybe<Scalars['String']>;
    categoryId: Scalars['String'];
    file?: Maybe<Scalars['Upload']>;
};

export type DeletePackagesInput = {
    ids: Array<Scalars['String']>;
};

export type BulkPackageSendSmsInput = {
    content: Scalars['String'];
    packageIds: Array<Scalars['String']>;
};

export type UpdateMultiplePackageGeneralStatusInput = {
    ids: Array<Scalars['String']>;
    status: PackageStatus;
};

export type UpdateBulkPackageInvoicePriceUpdatedInput = {
    ids: Array<Scalars['String']>;
};

export type UpdateMultiplePackageDeliveryDateInput = {
    ids: Array<Scalars['String']>;
    estimatedAt: Scalars['DateTime'];
};

export type ReturnPackageInput = {
    id: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    returnId: Scalars['String'];
    cargoCompany: Scalars['String'];
};

export type ConfirmReturnPackageInput = {
    id: Scalars['String'];
};

export type UpdateBulkPackageParcelNumberInput = {
    ids: Array<Scalars['String']>;
    parcelNumber: Scalars['String'];
};

export type UpdateBulkPackageShelfInput = {
    ids: Array<Scalars['String']>;
    shelfId: Scalars['String'];
};

export type UpdateBulkPackageInternalShelfInput = {
    ids: Array<Scalars['String']>;
    shelfId: Scalars['String'];
};

export type ExportManifestInput = {
    where?: Maybe<PackageWhereInput>;
};

export type CreatePurchaseOrderByAdminInput = {
    status?: Maybe<PurchaseOrderStatus>;
    invoicePrice: Scalars['Float'];
    storeCargoPrice: Scalars['Float'];
    isExpedited: Scalars['Boolean'];
    storeLink: Scalars['String'];
    customerId: Scalars['String'];
    countryId: Scalars['String'];
    count: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['String']>;
};

export type CreatePurchaseOrdersInput = {
    purchaseOrderItems: Array<CreatePurchaseOrderItemInput>;
};

export type CreatePurchaseOrderItemInput = {
    invoicePrice: Scalars['Float'];
    storeCargoPrice: Scalars['Float'];
    isExpedited: Scalars['Boolean'];
    storeLink: Scalars['String'];
    countryId: Scalars['String'];
    count: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['String']>;
};

export type UpdatePurchaseOrderByAdminInput = {
    id: Scalars['String'];
    customerId: Scalars['String'];
    status: PurchaseOrderStatus;
    invoicePrice: Scalars['Float'];
    storeCargoPrice: Scalars['Float'];
    isExpedited: Scalars['Boolean'];
    storeLink: Scalars['String'];
    countryId: Scalars['String'];
    count: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['String']>;
    falkonTrackingNumber?: Maybe<Scalars['String']>;
    storeTrackingNumber?: Maybe<Scalars['String']>;
    materialType?: Maybe<Scalars['String']>;
};

export type UpdatePurchaseOrderInput = {
    id: Scalars['String'];
    invoicePrice: Scalars['Float'];
    storeCargoPrice: Scalars['Float'];
    isExpedited: Scalars['Boolean'];
    storeLink: Scalars['String'];
    countryId: Scalars['String'];
    count: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['String']>;
};

export type DeletePurchaseOrdersInput = {
    ids: Array<Scalars['String']>;
};

export type BulkPurchaseOrderSendSmsInput = {
    content: Scalars['String'];
    purchaseOrderIds: Array<Scalars['String']>;
};

export type IncreaseBalanceByCardInput = {
    amount: Scalars['Float'];
};

export type IncreaseBalanceInCashInput = {
    customerId: Scalars['String'];
    amount: Scalars['Float'];
};

export type PayInput = {
    packageIds?: Maybe<Array<Scalars['String']>>;
    purchaseOrderIds?: Maybe<Array<Scalars['String']>>;
    byBalance: Scalars['Boolean'];
    promoCode?: Maybe<Scalars['String']>;
    courierOrderId?: Maybe<Scalars['String']>;
};

export type PayByAdminInput = {
    packageIds?: Maybe<Array<Scalars['String']>>;
    purchaseOrderIds?: Maybe<Array<Scalars['String']>>;
    customerId: Scalars['String'];
    byBalance: Scalars['Boolean'];
    promoCode?: Maybe<Scalars['String']>;
};

export type RefundByAdminInput = {
    packageIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateExchangeRateInput = {
    id: Scalars['String'];
    price: Scalars['Float'];
};

export type CreateBlogPostInput = {
    title: Scalars['String'];
    body: Scalars['String'];
    tags?: Maybe<Array<Scalars['String']>>;
    coverImage?: Maybe<Scalars['Upload']>;
};

export type UpdateBlogPostInput = {
    id: Scalars['String'];
    title: Scalars['String'];
    body: Scalars['String'];
    tags?: Maybe<Array<Scalars['String']>>;
};

export type UploadBlogPostCoverImageInput = {
    id: Scalars['String'];
    coverImage: Scalars['Upload'];
};

export type ViewBlogPostInput = {
    blogPostId: Scalars['String'];
};

export type SendToSmartCustomsInput = {
    ids: Array<Scalars['String']>;
};

export type RemoveDeclarationInput = {
    id: Scalars['String'];
};

export type CheckToSmartCustomsStatusInput = {
    ids: Array<Scalars['String']>;
};

export type AddToBoxInput = {
    ids: Array<Scalars['String']>;
    parcelNumber: Scalars['String'];
};

export type CancelAddToBoxInput = {
    ids: Array<Scalars['String']>;
};

export type CancelDepeshInput = {
    ids: Array<Scalars['String']>;
};

export type DepeshInput = {
    ids: Array<Scalars['String']>;
    airWaybill: Scalars['String'];
    parcelNumber?: Maybe<Scalars['String']>;
};

export type CreateMessageInput = {
    name: Scalars['String'];
    email: Scalars['String'];
    message: Scalars['String'];
    code?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['String']>;
};

export type MarkAsReadInput = {
    id: Scalars['String'];
    isRead: Scalars['Boolean'];
};

export type CreatePromoCodeByAdminInput = {
    code: Scalars['String'];
    type: PromoCodeType;
    value: Scalars['Float'];
    usageLimit?: Maybe<Scalars['Int']>;
    scope?: Maybe<Array<PromoCodeScope>>;
    maxAmount?: Maybe<Scalars['Float']>;
    maxItem?: Maybe<Scalars['Int']>;
    expiresAt?: Maybe<Scalars['DateTime']>;
};

export enum PromoCodeType {
    Amount = 'AMOUNT',
    Percentage = 'PERCENTAGE',
}

export type UpdatePromoCodeByAdminInput = {
    id: Scalars['String'];
    code?: Maybe<Scalars['String']>;
    type: PromoCodeType;
    value: Scalars['Int'];
    usageLimit?: Maybe<Scalars['Int']>;
    scope: Array<PromoCodeScope>;
    maxAmount?: Maybe<Scalars['Int']>;
    maxItem?: Maybe<Scalars['Int']>;
    expiresAt?: Maybe<Scalars['DateTime']>;
    isActive: Scalars['Boolean'];
};

export type CreateDeliveryZoneInput = {
    name: Scalars['String'];
    standartPrice?: Maybe<Scalars['Float']>;
    expressPrice?: Maybe<Scalars['Float']>;
    deliveryCityId: Scalars['ID'];
};

export type UpdateDeliveryZoneInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    standartPrice?: Maybe<Scalars['Float']>;
    expressPrice?: Maybe<Scalars['Float']>;
    deliveryCityId?: Maybe<Scalars['String']>;
};

export type DeleteDeliveryZoneInput = {
    id: Scalars['ID'];
};

export type CreateDeliveryCityInput = {
    name: Scalars['String'];
};

export type UpdateDeliveryCityInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type DeleteDeliveryCityInput = {
    id: Scalars['ID'];
};

export type CreateDeliveryAddressByAdminInput = {
    title: Scalars['String'];
    deliveryCityId: Scalars['ID'];
    deliveryZoneId: Scalars['ID'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    customerId: Scalars['ID'];
};

export type UpdateDeliveryAddressByAdminInput = {
    id: Scalars['ID'];
    title: Scalars['String'];
    deliveryCityId: Scalars['ID'];
    deliveryZoneId: Scalars['ID'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    customerId: Scalars['ID'];
};

export type DeleteDeliveryAddressByAdminInput = {
    id: Scalars['ID'];
};

export type CreateDeliveryAddressInput = {
    title: Scalars['String'];
    deliveryCityId: Scalars['ID'];
    deliveryZoneId: Scalars['ID'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
};

export type UpdateDeliveryAddressInput = {
    id: Scalars['ID'];
    title: Scalars['String'];
    deliveryCityId: Scalars['ID'];
    deliveryZoneId: Scalars['ID'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
};

export type DeleteDeliveryAddressInput = {
    id: Scalars['ID'];
};

export type CreateCourierOrderInput = {
    deliveryAddressId: Scalars['ID'];
    deliveryType: DeliveryType;
    packageIds: Array<Scalars['String']>;
    byBalance: Scalars['Boolean'];
};

export type DeleteCourierOrderInput = {
    id: Scalars['ID'];
};

export type CreateCourierOrderByAdminInput = {
    deliveryAddressId: Scalars['ID'];
    deliveryType: DeliveryType;
    packageIds: Array<Scalars['String']>;
    customerId: Scalars['ID'];
};

export type UpdateCourierOrderByAdminInput = {
    id: Scalars['ID'];
    deliveryAddressId: Scalars['ID'];
    deliveryType: DeliveryType;
};

export type AssignPackagesToCourierOrderByAdminInput = {
    id: Scalars['ID'];
    customerId: Scalars['ID'];
    packageIds: Array<Scalars['String']>;
};

export type CreateContainerInput = {
    name: Scalars['String'];
    flightId: Scalars['ID'];
};

export type AssignPackagesToContainerInput = {
    packageIds: Array<Scalars['String']>;
    containerId: Scalars['ID'];
};

export type DeleteContainerInput = {
    id: Scalars['ID'];
};

export type UnAssignPackageFromContainerInput = {
    packageId: Scalars['ID'];
    containerId: Scalars['ID'];
};

export type UpdateContainerInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type CreateFlightInput = {
    name: Scalars['String'];
};

export type UpdateFlightInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    cwb?: Maybe<Scalars['String']>;
};

export type ApplyingPackagesToCwbInput = {
    id: Scalars['ID'];
    cwb: Scalars['String'];
};

export type DeleteFlightInput = {
    id: Scalars['ID'];
};

export type FinishFlightInput = {
    id: Scalars['ID'];
};

export type ActivateAccountMutationVariables = Exact<{
    input: ActivateAccountInput;
}>;

export type ActivateAccountMutation = { __typename?: 'Mutation' } & {
    activateAccount: { __typename?: 'ActivateAccountPayload' } & Pick<ActivateAccountPayload, 'success'>;
};

export type CompleteSocialRegistrationMutationVariables = Exact<{
    input: CompleteSocialRegistrationInput;
}>;

export type CompleteSocialRegistrationMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'completeSocialRegistration'
>;

export type FacebookLoginMutationVariables = Exact<{ [key: string]: never }>;

export type FacebookLoginMutation = { __typename?: 'Mutation' } & {
    facebookLogin: { __typename?: 'AccessTokenPayload' } & Pick<AccessTokenPayload, 'accessToken'>;
};

export type GoogleLoginMutationVariables = Exact<{ [key: string]: never }>;

export type GoogleLoginMutation = { __typename?: 'Mutation' } & {
    googleLogin: { __typename?: 'AccessTokenPayload' } & Pick<AccessTokenPayload, 'accessToken'>;
};

export type SignInMutationVariables = Exact<{
    credentials: CredentialsInput;
}>;

export type SignInMutation = { __typename?: 'Mutation' } & {
    login: { __typename?: 'AccessTokenPayload' } & Pick<AccessTokenPayload, 'accessToken'>;
};

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
    resetPassword: { __typename?: 'ResetPasswordPayload' } & Pick<ResetPasswordPayload, 'success'>;
};

export type SendPasswordResetEmailMutationVariables = Exact<{
    input: ForgotPasswordInput;
}>;

export type SendPasswordResetEmailMutation = { __typename?: 'Mutation' } & {
    sendPasswordResetEmail: { __typename?: 'ForgotPasswordPayload' } & Pick<ForgotPasswordPayload, 'sent'>;
};

export type CreateMessageMutationVariables = Exact<{
    message: CreateMessageInput;
}>;

export type CreateMessageMutation = { __typename?: 'Mutation' } & {
    createMessage: { __typename?: 'CreateMessagePayload' } & {
        message: { __typename?: 'Message' } & Pick<
            Message,
            | 'id'
            | 'name'
            | 'email'
            | 'message'
            | 'phoneNumber'
            | 'isAnswered'
            | 'isRead'
            | 'code'
            | 'createdAt'
            | 'parentId'
        >;
    };
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
    changePassword: { __typename?: 'ChangePasswordPayload' } & Pick<ChangePasswordPayload, 'success'>;
};

export type CreatePackageMutationVariables = Exact<{
    input: CreatePackageInput;
}>;

export type CreatePackageMutation = { __typename?: 'Mutation' } & {
    createPackage: { __typename?: 'CreatePackagePayload' } & {
        package: { __typename?: 'Package' } & Pick<Package, 'id'>;
    };
};

export type CreatePurchaseOrdersMutationVariables = Exact<{
    input: CreatePurchaseOrdersInput;
}>;

export type CreatePurchaseOrdersMutation = { __typename?: 'Mutation' } & {
    createPurchaseOrders: { __typename?: 'CreatePurchaseOrdersPayload' } & {
        purchaseOrders: Array<{ __typename?: 'PurchaseOrder' } & Pick<PurchaseOrder, 'count'>>;
    };
};

export type DeletePackagesMutationVariables = Exact<{
    input: DeletePackagesInput;
}>;

export type DeletePackagesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deletePackages'>;

export type DeletePurchaseOrdersMutationVariables = Exact<{
    input: DeletePurchaseOrdersInput;
}>;

export type DeletePurchaseOrdersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deletePurchaseOrders'>;

export type IncreaseBalanceByCardMutationVariables = Exact<{
    input: IncreaseBalanceByCardInput;
}>;

export type IncreaseBalanceByCardMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'increaseBalanceByCard'>;

export type PayMutationVariables = Exact<{
    input: PayInput;
}>;

export type PayMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'pay'>;

export type RegisterCustomerMutationVariables = Exact<{
    customer: RegisterCustomerInput;
}>;

export type RegisterCustomerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'registerCustomer'>;

export type ReturnPackageMutationVariables = Exact<{
    input: ReturnPackageInput;
}>;

export type ReturnPackageMutation = { __typename?: 'Mutation' } & {
    returnPackage: { __typename?: 'ReturnPackagePayload' } & {
        package: { __typename?: 'Package' } & { category: { __typename?: 'Category' } & Pick<Category, 'id'> };
    };
};

export type UpdateCustomerMutationVariables = Exact<{
    customer: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = { __typename?: 'Mutation' } & {
    updateCustomer: { __typename?: 'UpdateCustomerPayload' } & {
        customer: { __typename?: 'Customer' } & Pick<Customer, 'id'>;
    };
};

export type UpdatePackageMutationVariables = Exact<{
    input: UpdatePackageInput;
}>;

export type UpdatePackageMutation = { __typename?: 'Mutation' } & {
    updatePackage: { __typename?: 'UpdatePackagePayload' } & {
        package: { __typename?: 'Package' } & Pick<Package, 'id'>;
    };
};

export type UpdatePurchaseOrderMutationVariables = Exact<{
    input: UpdatePurchaseOrderInput;
}>;

export type UpdatePurchaseOrderMutation = { __typename?: 'Mutation' } & {
    updatePurchaseOrder: { __typename?: 'UpdatePurchaseOrderPayload' } & {
        purchaseOrder: { __typename?: 'PurchaseOrder' } & Pick<PurchaseOrder, 'id'>;
    };
};

export type AddToBoxMutationVariables = Exact<{
    input: AddToBoxInput;
}>;

export type AddToBoxMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'addToBox'>;

export type AssignPackagesToContainerMutationVariables = Exact<{
    input: AssignPackagesToContainerInput;
}>;

export type AssignPackagesToContainerMutation = { __typename?: 'Mutation' } & {
    assignPackagesToContainer: { __typename?: 'AssignPackagesToContainerPayload' } & {
        container: { __typename?: 'Container' } & {
            packages: { __typename?: 'PackageConnection' } & {
                edges: Array<
                    { __typename?: 'PackageEdge' } & {
                        node: { __typename?: 'Package' } & Pick<Package, 'id' | 'falkonTrackingNumber' | 'weight'>;
                    }
                >;
            };
        };
    };
};

export type CancelAddToBoxMutationVariables = Exact<{
    input: CancelAddToBoxInput;
}>;

export type CancelAddToBoxMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'cancelAddToBox'>;

export type CancelDepeshMutationVariables = Exact<{
    input: CancelDepeshInput;
}>;

export type CancelDepeshMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'cancelDepesh'>;

export type CheckToSmartCustomsStatusMutationVariables = Exact<{
    input: CheckToSmartCustomsStatusInput;
}>;

export type CheckToSmartCustomsStatusMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'checkToSmartCustomsStatus'
>;

export type CreateBranchMutationVariables = Exact<{
    branch: CreateBranchInput;
}>;

export type CreateBranchMutation = { __typename?: 'Mutation' } & {
    createBranch: { __typename?: 'CreateBranchPayload' } & { branch: { __typename?: 'Branch' } & Pick<Branch, 'id'> };
};

export type CreateCategoryMutationVariables = Exact<{
    category: CreateCategoryInput;
}>;

export type CreateCategoryMutation = { __typename?: 'Mutation' } & {
    createCategory: { __typename?: 'CreateCategoryPayload' } & {
        category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'iconId' | 'color' | 'createdAt'> & {
                children: { __typename?: 'CategoryConnection' } & {
                    edges: Array<
                        { __typename?: 'CategoryEdge' } & {
                            node: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'iconId' | 'color'>;
                        }
                    >;
                };
            };
    };
};

export type CreateContainerMutationVariables = Exact<{
    input: CreateContainerInput;
}>;

export type CreateContainerMutation = { __typename?: 'Mutation' } & {
    createContainer: { __typename?: 'CreateContainerPayload' } & {
        container: { __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'packageCount' | 'totalWeight'>;
    };
};

export type RegisterCustomerByAdminMutationVariables = Exact<{
    customer: RegisterCustomerByAdminInput;
}>;

export type RegisterCustomerByAdminMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'registerCustomerByAdmin'>;

export type CreateExternalWarehouseMutationVariables = Exact<{
    input: CreateExternalWarehouseInput;
}>;

export type CreateExternalWarehouseMutation = { __typename?: 'Mutation' } & {
    createExternalWarehouse: { __typename?: 'CreateExternalWarehousePayload' } & {
        externalWarehouse: { __typename?: 'ExternalWarehouse' } & Pick<ExternalWarehouse, 'id' | 'name'> & {
                externalShelfs?: Maybe<
                    { __typename?: 'ShelfConnection' } & {
                        edges: Array<
                            { __typename?: 'ShelfEdge' } & {
                                node: { __typename?: 'Shelf' } & Pick<Shelf, 'id' | 'name'>;
                            }
                        >;
                    }
                >;
            };
    };
};

export type CreateFlightMutationVariables = Exact<{
    input: CreateFlightInput;
}>;

export type CreateFlightMutation = { __typename?: 'Mutation' } & {
    createFlight: { __typename?: 'CreateFlightPayload' } & { flight: { __typename?: 'Flight' } & Pick<Flight, 'id'> };
};

export type CreateInternalWarehouseMutationVariables = Exact<{
    input: CreateInternalWarehouseInput;
}>;

export type CreateInternalWarehouseMutation = { __typename?: 'Mutation' } & {
    createInternalWarehouse: { __typename?: 'CreateInternalWarehousePayload' } & {
        internalWarehouse: { __typename?: 'Warehouse' } & Pick<Warehouse, 'name' | 'id'> & {
                internalShelfs?: Maybe<
                    { __typename?: 'InternalShelfConnection' } & {
                        edges: Array<
                            { __typename?: 'InternalShelfEdge' } & {
                                node: { __typename?: 'InternalShelf' } & Pick<InternalShelf, 'id' | 'name'>;
                            }
                        >;
                    }
                >;
            };
    };
};

export type CreateInternalShelfMutationVariables = Exact<{
    input: CreateInternalShelfInput;
}>;

export type CreateInternalShelfMutation = { __typename?: 'Mutation' } & {
    createInternalShelf: { __typename?: 'CreateInternalShelfPayload' } & {
        shelf: { __typename?: 'InternalShelf' } & Pick<InternalShelf, 'id' | 'name'>;
    };
};

export type CreateShelfMutationVariables = Exact<{
    input: CreateShelfInput;
}>;

export type CreateShelfMutation = { __typename?: 'Mutation' } & {
    createShelf: { __typename?: 'CreateShelfPayload' } & { shelf: { __typename?: 'Shelf' } & Pick<Shelf, 'id'> };
};

export type CreatePackageByAdminMutationVariables = Exact<{
    input: CreatePackageByAdminInput;
}>;

export type CreatePackageByAdminMutation = { __typename?: 'Mutation' } & {
    createPackageByAdmin: { __typename?: 'CreatePackageByAdminPayload' } & {
        package: { __typename?: 'Package' } & Pick<
            Package,
            'id' | 'count' | 'weight' | 'invoicePrice' | 'deliveryPrice' | 'declaredValue' | 'falkonTrackingNumber'
        > & {
                store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name'>;
                country: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>;
                customer: { __typename?: 'Customer' } & Pick<Customer, 'code' | 'idFin'> & {
                        branches: { __typename?: 'BranchConnection' } & {
                            edges: Array<
                                { __typename?: 'BranchEdge' } & {
                                    node: { __typename?: 'Branch' } & Pick<Branch, 'name'>;
                                }
                            >;
                        };
                        user: { __typename?: 'User' } & Pick<
                            User,
                            'firstName' | 'lastName' | 'fullName' | 'phoneNumber' | 'address'
                        >;
                    };
            };
    };
};

export type CreatePostMutationVariables = Exact<{
    blogPost: CreateBlogPostInput;
}>;

export type CreatePostMutation = { __typename?: 'Mutation' } & {
    createPost: { __typename?: 'CreateBlogPostPayload' } & { post: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id'> };
};

export type CreatePurchaseOrderByAdminMutationVariables = Exact<{
    input: CreatePurchaseOrderByAdminInput;
}>;

export type CreatePurchaseOrderByAdminMutation = { __typename?: 'Mutation' } & {
    createPurchaseOrderByAdmin: { __typename?: 'CreatePurchaseOrderByAdminPayload' } & {
        purchaseOrder: { __typename?: 'PurchaseOrder' } & Pick<PurchaseOrder, 'id'>;
    };
};

export type CreateStoreMutationVariables = Exact<{
    store: CreateStoreInput;
}>;

export type CreateStoreMutation = { __typename?: 'Mutation' } & {
    createStore: { __typename?: 'CreateStorePayload' } & {
        store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name' | 'link'> & {
                categories: { __typename?: 'CategoryConnection' } & {
                    edges: Array<
                        { __typename?: 'CategoryEdge' } & {
                            node: { __typename?: 'Category' } & Pick<Category, 'id' | 'name'>;
                        }
                    >;
                };
            };
    };
};

export type CreateTariffMutationVariables = Exact<{
    tariff: CreateTariffInput;
}>;

export type CreateTariffMutation = { __typename?: 'Mutation' } & {
    createTariff: { __typename?: 'CreateTariffPayload' } & { tariff: { __typename?: 'Tariff' } & Pick<Tariff, 'id'> };
};

export type CreateRoleMutationVariables = Exact<{
    input: CreateRoleInput;
}>;

export type CreateRoleMutation = { __typename?: 'Mutation' } & {
    createRole: { __typename?: 'CreateRolePayload' } & { role: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'> };
};

export type CreateWeightMutationVariables = Exact<{
    weight: CreateWeightInput;
}>;

export type CreateWeightMutation = { __typename?: 'Mutation' } & {
    createWeight: { __typename?: 'CreateWeightPayload' } & {
        weight: { __typename?: 'Weight' } & Pick<Weight, 'id' | 'name' | 'min' | 'max'>;
    };
};

export type DeleteBlogPostMutationVariables = Exact<{
    deleteBlogPostId: Scalars['String'];
}>;

export type DeleteBlogPostMutation = { __typename?: 'Mutation' } & {
    deleteBlogPost: { __typename?: 'DeleteBlogPostPayload' } & Pick<DeleteBlogPostPayload, 'id'>;
};

export type DeleteCategoryMutationVariables = Exact<{
    deleteCategoryId: Scalars['String'];
}>;

export type DeleteCategoryMutation = { __typename?: 'Mutation' } & {
    deleteCategory: { __typename?: 'DeleteCategoryPayload' } & Pick<DeleteCategoryPayload, 'id'>;
};

export type DeleteContainerMutationVariables = Exact<{
    input: DeleteContainerInput;
}>;

export type DeleteContainerMutation = { __typename?: 'Mutation' } & {
    deleteContainer: { __typename?: 'DeleteContainerPayload' } & Pick<DeleteContainerPayload, 'id'>;
};

export type DeleteCustomersMutationVariables = Exact<{
    input: DeleteCustomersInput;
}>;

export type DeleteCustomersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteCustomers'>;

export type DeleteExternalWarehouseMutationVariables = Exact<{
    deleteExternalWarehouseId: Scalars['String'];
}>;

export type DeleteExternalWarehouseMutation = { __typename?: 'Mutation' } & {
    deleteExternalWarehouse: { __typename?: 'DeleteExternalWarehousePayload' } & Pick<
        DeleteExternalWarehousePayload,
        'id'
    >;
};

export type DeleteFlightMutationVariables = Exact<{
    input: DeleteFlightInput;
}>;

export type DeleteFlightMutation = { __typename?: 'Mutation' } & {
    deleteFlight: { __typename?: 'DeleteFlightPayload' } & Pick<DeleteFlightPayload, 'id'>;
};

export type DeleteInternalShelfMutationVariables = Exact<{
    deleteInternalShelfId: Scalars['String'];
}>;

export type DeleteInternalShelfMutation = { __typename?: 'Mutation' } & {
    deleteInternalShelf: { __typename?: 'DeleteInternalShelfPayload' } & Pick<DeleteInternalShelfPayload, 'id'>;
};

export type DeleteInternalWarehouseMutationVariables = Exact<{
    input: DeleteInternalWarehouseInput;
}>;

export type DeleteInternalWarehouseMutation = { __typename?: 'Mutation' } & {
    deleteInternalWarehouse: { __typename?: 'DeleteInternalWarehousePayload' } & Pick<
        DeleteInternalWarehousePayload,
        'id'
    >;
};

export type DeleteMessageMutationVariables = Exact<{
    deleteMessageId: Scalars['String'];
}>;

export type DeleteMessageMutation = { __typename?: 'Mutation' } & {
    deleteMessage: { __typename?: 'DeleteMessagePayload' } & Pick<DeleteMessagePayload, 'id'>;
};

export type DeletePackagesByAdminMutationVariables = Exact<{
    input: DeletePackagesInput;
}>;

export type DeletePackagesByAdminMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deletePackagesByAdmin'>;

export type DeletePurchaseOrdersByAdminMutationVariables = Exact<{
    input: DeletePurchaseOrdersInput;
}>;

export type DeletePurchaseOrdersByAdminMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'deletePurchaseOrdersByAdmin'
>;

export type DeleteShelfMutationVariables = Exact<{
    deleteShelfId: Scalars['String'];
}>;

export type DeleteShelfMutation = { __typename?: 'Mutation' } & {
    deleteShelf: { __typename?: 'DeleteShelfPayload' } & Pick<DeleteShelfPayload, 'id'>;
};

export type DeleteStoreMutationVariables = Exact<{
    deleteStoreId: Scalars['String'];
}>;

export type DeleteStoreMutation = { __typename?: 'Mutation' } & {
    deleteStore: { __typename?: 'DeleteStorePayload' } & Pick<DeleteStorePayload, 'id'>;
};

export type DeleteRoleMutationVariables = Exact<{
    deleteRoleId: Scalars['String'];
}>;

export type DeleteRoleMutation = { __typename?: 'Mutation' } & {
    deleteRole: { __typename?: 'DeleteRolePayload' } & Pick<DeleteRolePayload, 'id'>;
};

export type DeleteWeightMutationVariables = Exact<{
    deleteWeightId: Scalars['String'];
}>;

export type DeleteWeightMutation = { __typename?: 'Mutation' } & {
    deleteWeight: { __typename?: 'DeleteWeightPayload' } & Pick<DeleteWeightPayload, 'id'>;
};

export type DepeshMutationVariables = Exact<{
    input: DepeshInput;
}>;

export type DepeshMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'depesh'>;

export type UpdateCategoryMutationVariables = Exact<{
    category: UpdateCategoryInput;
}>;

export type UpdateCategoryMutation = { __typename?: 'Mutation' } & {
    updateCategory: { __typename?: 'CreateCategoryPayload' } & {
        category: { __typename?: 'Category' } & Pick<Category, 'id'>;
    };
};

export type UpdateStoreMutationVariables = Exact<{
    store: UpdateStoreInput;
}>;

export type UpdateStoreMutation = { __typename?: 'Mutation' } & {
    updateStore: { __typename?: 'UpdateStorePayload' } & { store: { __typename?: 'Store' } & Pick<Store, 'id'> };
};

export type ExportManifestMutationVariables = Exact<{
    input: ExportManifestInput;
}>;

export type ExportManifestMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'exportManifest'>;

export type IncreaseBalanceInCashMutationVariables = Exact<{
    input: IncreaseBalanceInCashInput;
}>;

export type IncreaseBalanceInCashMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'increaseBalanceInCash'>;

export type MarkAsReadMutationVariables = Exact<{
    input: MarkAsReadInput;
}>;

export type MarkAsReadMutation = { __typename?: 'Mutation' } & {
    markAsRead: { __typename?: 'MarkAsReadPayload' } & Pick<MarkAsReadPayload, 'success'>;
};

export type MultiplePayMutationVariables = Exact<{
    input: PayByAdminInput;
}>;

export type MultiplePayMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'payByAdmin'>;

export type RefreshExchangeRatesMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshExchangeRatesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'refreshExchangeRate'>;

export type RegisterManagerMutationVariables = Exact<{
    manager: RegisterManagerInput;
}>;

export type RegisterManagerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'registerManager'>;

export type RemoveDeclarationMutationVariables = Exact<{
    input: RemoveDeclarationInput;
}>;

export type RemoveDeclarationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeDeclaration'>;

export type RemoveManagerMutationVariables = Exact<{
    input: DeleteManagersInput;
}>;

export type RemoveManagerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteManagers'>;

export type SendToSmartCustomMutationVariables = Exact<{
    input: SendToSmartCustomsInput;
}>;

export type SendToSmartCustomMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'sendToSmartCustom'>;

export type UpdateMultipleCronJobActivationsMutationVariables = Exact<{
    input: UpdateMultipleCronJobActivationsInput;
}>;

export type UpdateMultipleCronJobActivationsMutation = { __typename?: 'Mutation' } & {
    updateMultipleCronJobActivations: { __typename?: 'UpdateMultipleCronJobActivationsPayload' } & Pick<
        UpdateMultipleCronJobActivationsPayload,
        'success'
    >;
};

export type UnAssignPackageFromContainerMutationVariables = Exact<{
    input: UnAssignPackageFromContainerInput;
}>;

export type UnAssignPackageFromContainerMutation = { __typename?: 'Mutation' } & {
    unAssignPackageFromContainer: { __typename?: 'UnAssignPackageFromContainerPayload' } & Pick<
        UnAssignPackageFromContainerPayload,
        'containerId' | 'packageId'
    >;
};

export type UpdateBlogPostMutationVariables = Exact<{
    blogPost: UpdateBlogPostInput;
}>;

export type UpdateBlogPostMutation = { __typename?: 'Mutation' } & {
    updateBlogPost: { __typename?: 'UpdateBlogPostPayload' } & {
        blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id'>;
    };
};

export type UpdateBranchMutationVariables = Exact<{
    branch: UpdateBranchInput;
}>;

export type UpdateBranchMutation = { __typename?: 'Mutation' } & {
    updateBranch: { __typename?: 'UpdateBranchPayload' } & { branch: { __typename?: 'Branch' } & Pick<Branch, 'id'> };
};

export type UpdateBulkPackageDeliveryDateMutationVariables = Exact<{
    input: UpdateMultiplePackageDeliveryDateInput;
}>;

export type UpdateBulkPackageDeliveryDateMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'updateBulkPackageDeliveryDate'
>;

export type UpdateBulkPackageGeneralStatusMutationVariables = Exact<{
    input: UpdateMultiplePackageGeneralStatusInput;
}>;

export type UpdateBulkPackageGeneralStatusMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'updateBulkPackageGeneralStatus'
>;

export type UpdateBulkPackageInternalShelfMutationVariables = Exact<{
    input: UpdateBulkPackageInternalShelfInput;
}>;

export type UpdateBulkPackageInternalShelfMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'updateBulkPackageInternalShelf'
>;

export type UpdateBulkPackageShelfMutationVariables = Exact<{
    input: UpdateBulkPackageShelfInput;
}>;

export type UpdateBulkPackageShelfMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateBulkPackageShelf'>;

export type UpdateCustomerByAdminMutationVariables = Exact<{
    customer: UpdateCustomerByAdminInput;
}>;

export type UpdateCustomerByAdminMutation = { __typename?: 'Mutation' } & {
    updateCustomerByAdmin: { __typename?: 'UpdateCustomerByAdminPayload' } & {
        customer: { __typename?: 'Customer' } & Pick<Customer, 'id'>;
    };
};

export type UpdateExchangeRateMutationVariables = Exact<{
    input: UpdateExchangeRateInput;
}>;

export type UpdateExchangeRateMutation = { __typename?: 'Mutation' } & {
    updateExchangeRate: { __typename?: 'UpdateExchangeRatePayload' } & Pick<UpdateExchangeRatePayload, 'success'>;
};

export type UpdateExternalWarehouseMutationVariables = Exact<{
    input: UpdateExternalWarehouseInput;
}>;

export type UpdateExternalWarehouseMutation = { __typename?: 'Mutation' } & {
    updateExternalWarehouse: { __typename?: 'UpdateExternalWarehousePayload' } & {
        externalWarehouse: { __typename?: 'ExternalWarehouse' } & Pick<ExternalWarehouse, 'id' | 'name'>;
    };
};

export type UpdateFlightMutationVariables = Exact<{
    input: UpdateFlightInput;
}>;

export type UpdateFlightMutation = { __typename?: 'Mutation' } & {
    updateFlight: { __typename?: 'UpdateFlightPayload' } & {
        flight: { __typename?: 'Flight' } & Pick<Flight, 'name' | 'id'>;
    };
};

export type UpdateInternalShelfMutationVariables = Exact<{
    shelf: UpdateInternalShelfInput;
}>;

export type UpdateInternalShelfMutation = { __typename?: 'Mutation' } & {
    updateInternalShelf: { __typename?: 'UpdateInternalShelfPayload' } & {
        shelf: { __typename?: 'InternalShelf' } & Pick<InternalShelf, 'id' | 'name'>;
    };
};

export type UpdateInternalWarehouseMutationVariables = Exact<{
    input: UpdateInternalWarehouseInput;
}>;

export type UpdateInternalWarehouseMutation = { __typename?: 'Mutation' } & {
    updateInternalWarehouse: { __typename?: 'UpdateInternalWarehousePayload' } & {
        internalWarehouse: { __typename?: 'Warehouse' } & Pick<Warehouse, 'id' | 'name'>;
    };
};

export type UpdateManagerByAdminMutationVariables = Exact<{
    manager: UpdateManagerByAdminInput;
}>;

export type UpdateManagerByAdminMutation = { __typename?: 'Mutation' } & {
    updateManagerByAdmin: { __typename?: 'UpdateManagerByAdminPayload' } & {
        manager: { __typename?: 'Manager' } & Pick<Manager, 'id'>;
    };
};

export type UpdatePurchaseOrderByAdminMutationVariables = Exact<{
    input: UpdatePurchaseOrderByAdminInput;
}>;

export type UpdatePurchaseOrderByAdminMutation = { __typename?: 'Mutation' } & {
    updatePurchaseOrderByAdmin: { __typename?: 'UpdatePurchaseOrderByAdminPayload' } & {
        purchaseOrder: { __typename?: 'PurchaseOrder' } & Pick<PurchaseOrder, 'id'>;
    };
};

export type UpdatePurchaseOrderSettingMutationVariables = Exact<{
    input: UpdateConfigurationInput;
}>;

export type UpdatePurchaseOrderSettingMutation = { __typename?: 'Mutation' } & {
    updateConfiguration: { __typename?: 'UpdateConfigurationPayload' } & {
        configuration: { __typename?: 'Configuration' } & Pick<Configuration, 'id'>;
    };
};

export type UpdateShelfMutationVariables = Exact<{
    shelf: UpdateShelfInput;
}>;

export type UpdateShelfMutation = { __typename?: 'Mutation' } & {
    updateShelf: { __typename?: 'UpdateShelfPayload' } & { shelf: { __typename?: 'Shelf' } & Pick<Shelf, 'id'> };
};

export type UpdateTariffMutationVariables = Exact<{
    tariff: UpdateTariffInput;
}>;

export type UpdateTariffMutation = { __typename?: 'Mutation' } & {
    updateTariff: { __typename?: 'UpdateTariffPayload' } & { tariff: { __typename?: 'Tariff' } & Pick<Tariff, 'id'> };
};

export type UpdatePackagebyAdminMutationVariables = Exact<{
    input: UpdatePackageByAdminInput;
}>;

export type UpdatePackagebyAdminMutation = { __typename?: 'Mutation' } & {
    updatePackageByAdmin: { __typename?: 'UpdatePackageByAdminPayload' } & {
        package: { __typename?: 'Package' } & Pick<Package, 'id'>;
    };
};

export type UpdateRoleMutationVariables = Exact<{
    input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = { __typename?: 'Mutation' } & {
    updateRole: { __typename?: 'UpdateRolePayload' } & { role: { __typename?: 'Role' } & Pick<Role, 'id'> };
};

export type UpdateWeightMutationVariables = Exact<{
    weight: UpdateWeightInput;
}>;

export type UpdateWeightMutation = { __typename?: 'Mutation' } & {
    updateWeight: { __typename?: 'UpdateWeightPayload' } & {
        weight: { __typename?: 'Weight' } & Pick<Weight, 'name' | 'min' | 'max' | 'id'>;
    };
};

export type UploadBlogPostCoverImageMutationVariables = Exact<{
    blogPost: UploadBlogPostCoverImageInput;
}>;

export type UploadBlogPostCoverImageMutation = { __typename?: 'Mutation' } & {
    uploadBlogPostCoverImage: { __typename?: 'UploadBlogPostCoverImagePayload' } & {
        blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id'>;
    };
};

export type TransactionsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<TransactionWhereInput>;
}>;

export type TransactionsQuery = { __typename?: 'Query' } & {
    transactions: { __typename?: 'TransactionConnection' } & Pick<TransactionConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'TransactionEdge' } & {
                    node: { __typename?: 'Transaction' } & Pick<
                        Transaction,
                        'id' | 'amount' | 'type' | 'createdAt' | 'status'
                    > & {
                            salesOrderLines?: Maybe<
                                { __typename?: 'SalesOrderLineConnection' } & {
                                    edges: Array<
                                        { __typename?: 'SalesOrderLineEdge' } & {
                                            node: { __typename?: 'SalesOrderLine' } & Pick<SalesOrderLine, 'type'> & {
                                                    package?: Maybe<
                                                        { __typename?: 'Package' } & Pick<
                                                            Package,
                                                            'id' | 'falkonTrackingNumber'
                                                        >
                                                    >;
                                                    purchaseOrder?: Maybe<
                                                        { __typename?: 'PurchaseOrder' } & Pick<
                                                            PurchaseOrder,
                                                            'id' | 'purchaseOrderNumber'
                                                        >
                                                    >;
                                                };
                                        }
                                    >;
                                }
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'page' | 'totalPages'>;
        };
};

export type BlogPostQueryVariables = Exact<{
    blogPostId?: Maybe<Scalars['String']>;
}>;

export type BlogPostQuery = { __typename?: 'Query' } & {
    blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'title' | 'body' | 'tags' | 'createdAt'> & {
            coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'url' | 'name'>;
            relatedBlogPosts: { __typename?: 'BlogPostConnection' } & {
                edges: Array<
                    { __typename?: 'BlogPostEdge' } & {
                        node: { __typename?: 'BlogPost' } & Pick<BlogPost, 'createdAt' | 'title'> & {
                                coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'url'>;
                            };
                    }
                >;
            };
        };
};

export type BlogPostDetailQueryVariables = Exact<{
    slug?: Maybe<Scalars['String']>;
}>;

export type BlogPostDetailQuery = { __typename?: 'Query' } & {
    blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'slug' | 'title' | 'body' | 'createdAt' | 'tags'> & {
            coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>;
            relatedBlogPosts: { __typename?: 'BlogPostConnection' } & {
                edges: Array<
                    { __typename?: 'BlogPostEdge' } & {
                        node: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'slug' | 'createdAt' | 'title'> & {
                                coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>;
                            };
                    }
                >;
            };
        };
};

export type BlogPostsQueryVariables = Exact<{ [key: string]: never }>;

export type BlogPostsQuery = { __typename?: 'Query' } & {
    blogPosts: { __typename?: 'BlogPostConnection' } & {
        edges: Array<
            { __typename?: 'BlogPostEdge' } & {
                node: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'slug' | 'createdAt' | 'title'> & {
                        coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'url'>;
                    };
            }
        >;
    };
};

export type BranchQueryVariables = Exact<{
    branchId: Scalars['String'];
}>;

export type BranchQuery = { __typename?: 'Query' } & {
    branch: { __typename?: 'Branch' } & Pick<Branch, 'id' | 'address' | 'name' | 'price'>;
};

export type BranchesQueryVariables = Exact<{ [key: string]: never }>;

export type BranchesQuery = { __typename?: 'Query' } & {
    branches: { __typename?: 'BranchConnection' } & {
        edges: Array<
            { __typename?: 'BranchEdge' } & {
                node: { __typename?: 'Branch' } & Pick<Branch, 'id' | 'address' | 'price' | 'name'>;
            }
        >;
    };
};

export type CategoriesQueryVariables = Exact<{
    where?: Maybe<CategoryWhereInput>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type CategoriesQuery = { __typename?: 'Query' } & {
    categories: { __typename?: 'CategoryConnection' } & Pick<CategoryConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
            >;
            edges: Array<
                { __typename?: 'CategoryEdge' } & {
                    node: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'iconId' | 'color'> & {
                            icon?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'url' | 'id'>>;
                            parent?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
                        };
                }
            >;
        };
};

export type GetCategoryByIdQueryVariables = Exact<{
    categoryId: Scalars['String'];
}>;

export type GetCategoryByIdQuery = { __typename?: 'Query' } & {
    category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'iconId' | 'color'> & {
            icon?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'name' | 'url'>>;
            parent?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
        };
};

export type ConfigurationsQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigurationsQuery = { __typename?: 'Query' } & {
    configurations: { __typename?: 'ConfigurationConnection' } & {
        edges: Array<
            { __typename?: 'ConfigurationEdge' } & {
                node: { __typename?: 'Configuration' } & Pick<Configuration, 'type' | 'price' | 'id'>;
            }
        >;
    };
};

export type RegionsQueryVariables = Exact<{ [key: string]: never }>;

export type RegionsQuery = { __typename?: 'Query' } & {
    regions: { __typename?: 'RegionConnection' } & {
        edges: Array<
            { __typename?: 'RegionEdge' } & {
                node: { __typename?: 'Region' } & Pick<Region, 'id' | 'name' | 'active'> & {
                        currencies: { __typename?: 'CurrencyConnection' } & {
                            edges: Array<
                                { __typename?: 'CurrencyEdge' } & {
                                    node: { __typename?: 'Currency' } & Pick<
                                        Currency,
                                        'id' | 'name' | 'abbreviation' | 'rate'
                                    >;
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export type MyBonusesQueryVariables = Exact<{ [key: string]: never }>;

export type MyBonusesQuery = { __typename?: 'Query' } & {
    myBonuses: { __typename?: 'CustomerBonusConnection' } & {
        edges: Array<
            { __typename?: 'CustomerBonusEdge' } & {
                node: { __typename?: 'CustomerBonus' } & Pick<CustomerBonus, 'createdAt' | 'isVerified'> & {
                        bonus?: Maybe<{ __typename?: 'Bonus' } & Pick<Bonus, 'amount'>>;
                    };
            }
        >;
    };
};

export type CustomersQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<CustomerWhereInput>;
}>;

export type CustomersQuery = { __typename?: 'Query' } & {
    customers?: Maybe<
        { __typename?: 'CustomerConnection' } & Pick<CustomerConnection, 'totalCount'> & {
                edges: Array<
                    { __typename?: 'CustomerEdge' } & {
                        node: { __typename?: 'Customer' } & Pick<
                            Customer,
                            | 'id'
                            | 'userId'
                            | 'code'
                            | 'balance'
                            | 'idFin'
                            | 'idSerialNumber'
                            | 'createdAt'
                            | 'isRegistered'
                            | 'status'
                        > & {
                                branches: { __typename?: 'BranchConnection' } & {
                                    edges: Array<
                                        { __typename?: 'BranchEdge' } & {
                                            node: { __typename?: 'Branch' } & Pick<Branch, 'name' | 'price'>;
                                        }
                                    >;
                                };
                                user: { __typename?: 'User' } & Pick<
                                    User,
                                    'fullName' | 'email' | 'phoneNumber' | 'gender' | 'address' | 'lastLogin'
                                >;
                                customerBonuses?: Maybe<
                                    { __typename?: 'CustomerBonusConnection' } & {
                                        edges: Array<
                                            { __typename?: 'CustomerBonusEdge' } & {
                                                node: { __typename?: 'CustomerBonus' } & {
                                                    referrerCustomer?: Maybe<
                                                        { __typename?: 'Customer' } & Pick<Customer, 'code'> & {
                                                                user: { __typename?: 'User' } & Pick<User, 'fullName'>;
                                                            }
                                                    >;
                                                };
                                            }
                                        >;
                                    }
                                >;
                            };
                    }
                >;
                pageInfo: { __typename?: 'PageInfo' } & Pick<
                    PageInfo,
                    'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
                >;
            }
    >;
};

export type DasboardsQueryVariables = Exact<{ [key: string]: never }>;

export type DasboardsQuery = { __typename?: 'Query' } & {
    dasboards: { __typename?: 'ReportConnection' } & {
        edges: Array<
            { __typename?: 'ReportEdge' } & {
                node: { __typename?: 'Report' } & Pick<Report, 'id' | 'code' | 'embedUrl'>;
            }
        >;
    };
};

export type ContainerQueryVariables = Exact<{
    containerId: Scalars['String'];
}>;

export type ContainerQuery = { __typename?: 'Query' } & {
    container: { __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'totalWeight' | 'packageCount'> & {
            packages: { __typename?: 'PackageConnection' } & {
                edges: Array<
                    { __typename?: 'PackageEdge' } & {
                        node: { __typename?: 'Package' } & Pick<
                            Package,
                            | 'airWaybill'
                            | 'cargoCompany'
                            | 'categoryId'
                            | 'containerId'
                            | 'count'
                            | 'countryId'
                            | 'createdAt'
                            | 'createdById'
                            | 'customerId'
                            | 'declaredValue'
                            | 'deliveryPrice'
                            | 'description'
                            | 'falkonTrackingNumber'
                            | 'flightId'
                            | 'id'
                            | 'internalShelfId'
                            | 'invoicePrice'
                            | 'materialType'
                            | 'packageNumber'
                            | 'parcelNumber'
                            | 'paymentStatus'
                            | 'regNumber'
                            | 'smartCustomStatus'
                            | 'status'
                            | 'storeTrackingNumber'
                            | 'invoicePriceUpdated'
                            | 'weight'
                            | 'volumetricWeight'
                            | 'estimatedAt'
                        > & {
                                country: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> & {
                                        currencies: { __typename?: 'CurrencyConnection' } & {
                                            edges: Array<
                                                { __typename?: 'CurrencyEdge' } & {
                                                    node: { __typename?: 'Currency' } & Pick<
                                                        Currency,
                                                        'abbreviation' | 'id'
                                                    >;
                                                }
                                            >;
                                        };
                                    };
                                category: { __typename?: 'Category' } & Pick<Category, 'name' | 'id'>;
                                store: { __typename?: 'Store' } & Pick<Store, 'name' | 'id' | 'link'>;
                                customer: { __typename?: 'Customer' } & Pick<Customer, 'id' | 'code'> & {
                                        user: { __typename?: 'User' } & Pick<
                                            User,
                                            'id' | 'lastName' | 'fullName' | 'firstName'
                                        >;
                                        branches: { __typename?: 'BranchConnection' } & {
                                            edges: Array<
                                                { __typename?: 'BranchEdge' } & {
                                                    node: { __typename?: 'Branch' } & Pick<
                                                        Branch,
                                                        'id' | 'name' | 'price'
                                                    >;
                                                }
                                            >;
                                        };
                                    };
                                externalShelf?: Maybe<{ __typename?: 'Shelf' } & Pick<Shelf, 'id' | 'name'>>;
                                internalShelf?: Maybe<
                                    { __typename?: 'InternalShelf' } & Pick<InternalShelf, 'id' | 'name'>
                                >;
                            };
                    }
                >;
            };
        };
};

export type ContainersQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ContainerWhereInput>;
}>;

export type ContainersQuery = { __typename?: 'Query' } & {
    containers: { __typename?: 'ContainerConnection' } & Pick<ContainerConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'ContainerEdge' } & {
                    node: { __typename?: 'Container' } & Pick<
                        Container,
                        'createdAt' | 'flightId' | 'id' | 'name' | 'packageCount' | 'totalWeight' | 'version'
                    >;
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
        };
};

export type CronJobsQueryVariables = Exact<{ [key: string]: never }>;

export type CronJobsQuery = { __typename?: 'Query' } & {
    cronJobs: { __typename?: 'CronJobConnection' } & {
        edges: Array<
            { __typename?: 'CronJobEdge' } & {
                node: { __typename?: 'CronJob' } & Pick<CronJob, 'id' | 'name' | 'isActivated'>;
            }
        >;
    };
};

export type CustomerQueryVariables = Exact<{
    customerId: Scalars['String'];
}>;

export type CustomerQuery = { __typename?: 'Query' } & {
    customer?: Maybe<
        { __typename?: 'Customer' } & Pick<
            Customer,
            'id' | 'userId' | 'code' | 'balance' | 'idFin' | 'idSerialNumber' | 'createdAt' | 'isRegistered' | 'status'
        > & {
                branches: { __typename?: 'BranchConnection' } & {
                    edges: Array<
                        { __typename?: 'BranchEdge' } & {
                            node: { __typename?: 'Branch' } & Pick<Branch, 'id' | 'name' | 'price'>;
                        }
                    >;
                };
                user: { __typename?: 'User' } & Pick<
                    User,
                    | 'firstName'
                    | 'lastName'
                    | 'emailVerified'
                    | 'fullName'
                    | 'email'
                    | 'phoneNumber'
                    | 'gender'
                    | 'address'
                    | 'lastLogin'
                    | 'dateOfBirth'
                >;
                customerBonuses?: Maybe<
                    { __typename?: 'CustomerBonusConnection' } & {
                        edges: Array<
                            { __typename?: 'CustomerBonusEdge' } & {
                                node: { __typename?: 'CustomerBonus' } & {
                                    referrerCustomer?: Maybe<
                                        { __typename?: 'Customer' } & {
                                            user: { __typename?: 'User' } & Pick<User, 'fullName'>;
                                        }
                                    >;
                                };
                            }
                        >;
                    }
                >;
            }
    >;
};

export type DeleteTariffMutationVariables = Exact<{
    deleteTariffId: Scalars['String'];
}>;

export type DeleteTariffMutation = { __typename?: 'Mutation' } & {
    deleteTariff: { __typename?: 'DeleteTariffPayload' } & Pick<DeleteTariffPayload, 'id'>;
};

export type ExchangeRatesQueryVariables = Exact<{ [key: string]: never }>;

export type ExchangeRatesQuery = { __typename?: 'Query' } & {
    exchangeRates: { __typename?: 'ExchangeRateConnection' } & {
        edges: Array<
            { __typename?: 'ExchangeRateEdge' } & {
                node: { __typename?: 'ExchangeRate' } & Pick<ExchangeRate, 'id' | 'to' | 'from' | 'rate'>;
            }
        >;
    };
};

export type ExternalWarehousesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ExternalWarehouseWhereInput>;
}>;

export type ExternalWarehousesQuery = { __typename?: 'Query' } & {
    externalWarehouses: { __typename?: 'ExternalWarehouseConnection' } & Pick<
        ExternalWarehouseConnection,
        'totalCount'
    > & {
            edges: Array<
                { __typename?: 'ExternalWarehouseEdge' } & {
                    node: { __typename?: 'ExternalWarehouse' } & Pick<ExternalWarehouse, 'id' | 'name'> & {
                            externalShelfs?: Maybe<
                                { __typename?: 'ShelfConnection' } & Pick<ShelfConnection, 'totalCount'> & {
                                        edges: Array<
                                            { __typename?: 'ShelfEdge' } & {
                                                node: { __typename?: 'Shelf' } & Pick<Shelf, 'id' | 'name'>;
                                            }
                                        >;
                                    }
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'page' | 'totalPages'>;
        };
};

export type FlightQueryVariables = Exact<{
    flightId: Scalars['String'];
}>;

export type FlightQuery = { __typename?: 'Query' } & {
    flight: { __typename?: 'Flight' } & Pick<
        Flight,
        'id' | 'name' | 'packageCount' | 'totalWeight' | 'containerCount' | 'createdAt' | 'cwb'
    > & {
            containers: { __typename?: 'ContainerConnection' } & {
                edges: Array<
                    { __typename?: 'ContainerEdge' } & {
                        node: { __typename?: 'Container' } & Pick<
                            Container,
                            'id' | 'name' | 'packageCount' | 'totalWeight'
                        >;
                    }
                >;
            };
        };
};

export type FlightsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<FlightWhereInput>;
}>;

export type FlightsQuery = { __typename?: 'Query' } & {
    flights: { __typename?: 'FlightConnection' } & Pick<FlightConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'FlightEdge' } & {
                    node: { __typename?: 'Flight' } & Pick<
                        Flight,
                        | 'id'
                        | 'name'
                        | 'packageCount'
                        | 'version'
                        | 'totalWeight'
                        | 'cwb'
                        | 'createdAt'
                        | 'completedAt'
                        | 'containerCount'
                    > & {
                            packages: { __typename?: 'PackageConnection' } & Pick<PackageConnection, 'totalCount'> & {
                                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                                        PageInfo,
                                        'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
                                    >;
                                };
                            containers: { __typename?: 'ContainerConnection' } & Pick<
                                ContainerConnection,
                                'totalCount'
                            > & {
                                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                                        PageInfo,
                                        'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
                                    >;
                                };
                        };
                }
            >;
        };
};

export type InternalShelfsQueryVariables = Exact<{
    where?: Maybe<InternalShelfWhereInput>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type InternalShelfsQuery = { __typename?: 'Query' } & {
    internalShelfs: { __typename?: 'InternalShelfConnection' } & Pick<InternalShelfConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'InternalShelfEdge' } & {
                    node: { __typename?: 'InternalShelf' } & Pick<
                        InternalShelf,
                        'id' | 'name' | 'internalWarehouseId'
                    > & { internalWarehouse: { __typename?: 'Warehouse' } & Pick<Warehouse, 'name' | 'id'> };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'page' | 'totalPages'>;
        };
};

export type InternalWarehousesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<InternalWarehouseWhereInput>;
}>;

export type InternalWarehousesQuery = { __typename?: 'Query' } & {
    internalWarehouses: { __typename?: 'InternalWarehouseConnection' } & Pick<
        InternalWarehouseConnection,
        'totalCount'
    > & {
            edges: Array<
                { __typename?: 'InternalWarehouseEdge' } & {
                    node: { __typename?: 'Warehouse' } & Pick<Warehouse, 'id' | 'name'> & {
                            internalShelfs?: Maybe<
                                { __typename?: 'InternalShelfConnection' } & Pick<
                                    InternalShelfConnection,
                                    'totalCount'
                                > & {
                                        edges: Array<
                                            { __typename?: 'InternalShelfEdge' } & {
                                                node: { __typename?: 'InternalShelf' } & Pick<
                                                    InternalShelf,
                                                    'id' | 'name'
                                                >;
                                            }
                                        >;
                                    }
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'page' | 'totalPages'>;
        };
};

export type ManagerQueryVariables = Exact<{
    managerId: Scalars['String'];
}>;

export type ManagerQuery = { __typename?: 'Query' } & {
    manager: { __typename?: 'Manager' } & Pick<Manager, 'countryId'> & {
            user: { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'phoneNumber' | 'gender'> & {
                    roles: { __typename?: 'RoleConnection' } & {
                        edges: Array<
                            { __typename?: 'RoleEdge' } & { node: { __typename?: 'Role' } & Pick<Role, 'id'> }
                        >;
                    };
                };
        };
};

export type ManagersQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type ManagersQuery = { __typename?: 'Query' } & {
    managers: { __typename?: 'ManagerConnection' } & Pick<ManagerConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'ManagerEdge' } & {
                    node: { __typename?: 'Manager' } & Pick<Manager, 'id' | 'createdAt'> & {
                            user: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'lastLogin'> & {
                                    roles: { __typename?: 'RoleConnection' } & {
                                        edges: Array<
                                            { __typename?: 'RoleEdge' } & {
                                                node: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'>;
                                            }
                                        >;
                                    };
                                    customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'id' | 'createdAt'>>;
                                };
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
            >;
        };
};

export type PackagesByAdminWithoutPaginationQueryVariables = Exact<{
    where?: Maybe<PackageWhereInput>;
}>;

export type PackagesByAdminWithoutPaginationQuery = { __typename?: 'Query' } & {
    packagesByAdmin: { __typename?: 'PackageConnection' } & {
        edges: Array<
            { __typename?: 'PackageEdge' } & {
                node: { __typename?: 'Package' } & Pick<
                    Package,
                    'id' | 'falkonTrackingNumber' | 'storeTrackingNumber' | 'customerId' | 'weight'
                > & {
                        customer: { __typename?: 'Customer' } & Pick<Customer, 'code'> & {
                                branches: { __typename?: 'BranchConnection' } & {
                                    edges: Array<
                                        { __typename?: 'BranchEdge' } & {
                                            node: { __typename?: 'Branch' } & Pick<Branch, 'name'>;
                                        }
                                    >;
                                };
                                user: { __typename?: 'User' } & Pick<User, 'fullName' | 'phoneNumber' | 'address'>;
                            };
                    };
            }
        >;
    };
};

export type PackagesByAdminQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<PackageWhereInput>;
    orderBy?: Maybe<PackageOrderBy>;
}>;

export type PackagesByAdminQuery = { __typename?: 'Query' } & {
    packagesByAdmin: { __typename?: 'PackageConnection' } & Pick<PackageConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'PackageEdge' } & {
                    node: { __typename?: 'Package' } & Pick<
                        Package,
                        | 'id'
                        | 'createdAt'
                        | 'parcelNumber'
                        | 'returnId'
                        | 'returnedAt'
                        | 'cargoCompany'
                        | 'note'
                        | 'airWaybill'
                        | 'invoicePriceUpdated'
                        | 'falkonTrackingNumber'
                        | 'storeTrackingNumber'
                        | 'volumetricWeight'
                        | 'customerId'
                        | 'regNumber'
                        | 'weight'
                        | 'count'
                        | 'invoicePrice'
                        | 'deliveryPrice'
                        | 'declaredValue'
                        | 'status'
                        | 'smartCustomStatus'
                        | 'estimatedAt'
                        | 'paymentStatus'
                    > & {
                            purchaseOrder?: Maybe<
                                { __typename?: 'PurchaseOrder' } & Pick<PurchaseOrder, 'id' | 'purchaseOrderNumber'>
                            >;
                            customer: { __typename?: 'Customer' } & Pick<Customer, 'code' | 'idFin'> & {
                                    branches: { __typename?: 'BranchConnection' } & {
                                        edges: Array<
                                            { __typename?: 'BranchEdge' } & {
                                                node: { __typename?: 'Branch' } & Pick<Branch, 'name'>;
                                            }
                                        >;
                                    };
                                    user: { __typename?: 'User' } & Pick<User, 'fullName' | 'phoneNumber' | 'address'>;
                                };
                            country: { __typename?: 'Region' } & Pick<Region, 'name'> & {
                                    currencies: { __typename?: 'CurrencyConnection' } & {
                                        edges: Array<
                                            { __typename?: 'CurrencyEdge' } & {
                                                node: { __typename?: 'Currency' } & Pick<
                                                    Currency,
                                                    'rate' | 'abbreviation'
                                                >;
                                            }
                                        >;
                                    };
                                };
                            store: { __typename?: 'Store' } & Pick<Store, 'name'>;
                            externalShelf?: Maybe<{ __typename?: 'Shelf' } & Pick<Shelf, 'id' | 'name'>>;
                            internalShelf?: Maybe<
                                { __typename?: 'InternalShelf' } & Pick<InternalShelf, 'id' | 'name'>
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
            >;
        };
};

export type PackagesTerminalQueryVariables = Exact<{
    where?: Maybe<PackageWhereInput>;
}>;

export type PackagesTerminalQuery = { __typename?: 'Query' } & {
    packagesByAdmin: { __typename?: 'PackageConnection' } & {
        edges: Array<
            { __typename?: 'PackageEdge' } & {
                node: { __typename?: 'Package' } & Pick<
                    Package,
                    | 'falkonTrackingNumber'
                    | 'weight'
                    | 'count'
                    | 'invoicePrice'
                    | 'deliveryPrice'
                    | 'declaredValue'
                    | 'regNumber'
                    | 'id'
                    | 'smartCustomStatus'
                > & {
                        store: { __typename?: 'Store' } & Pick<Store, 'name'>;
                        customer: { __typename?: 'Customer' } & Pick<Customer, 'idFin' | 'code'> & {
                                user: { __typename?: 'User' } & Pick<
                                    User,
                                    'firstName' | 'lastName' | 'fullName' | 'phoneNumber' | 'address'
                                >;
                                branches: { __typename?: 'BranchConnection' } & {
                                    edges: Array<
                                        { __typename?: 'BranchEdge' } & {
                                            node: { __typename?: 'Branch' } & Pick<Branch, 'name'>;
                                        }
                                    >;
                                };
                            };
                        country: { __typename?: 'Region' } & Pick<Region, 'name'>;
                    };
            }
        >;
    };
};

export type PurchaseOrdersCountQueryVariables = Exact<{ [key: string]: never }>;

export type PurchaseOrdersCountQuery = { __typename?: 'Query' } & Pick<Query, 'purchaseOrdersCount'>;

export type PurchaseOrdersByAdminQueryVariables = Exact<{
    where?: Maybe<PurchaseOrderWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<PurchaseOrderOrderBy>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type PurchaseOrdersByAdminQuery = { __typename?: 'Query' } & {
    purchaseOrdersByAdmin: { __typename?: 'PurchaseOrderConnection' } & Pick<PurchaseOrderConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'PurchaseOrderEdge' } & {
                    node: { __typename?: 'PurchaseOrder' } & Pick<
                        PurchaseOrder,
                        | 'id'
                        | 'createdAt'
                        | 'customerId'
                        | 'totalPrice'
                        | 'invoicePrice'
                        | 'storeCargoPrice'
                        | 'status'
                        | 'storeLink'
                        | 'count'
                        | 'color'
                        | 'description'
                        | 'size'
                        | 'purchaseOrderNumber'
                        | 'expeditedServicePrice'
                    > & {
                            package?: Maybe<
                                { __typename?: 'Package' } & Pick<
                                    Package,
                                    'falkonTrackingNumber' | 'storeTrackingNumber' | 'categoryId'
                                >
                            >;
                            customer: { __typename?: 'Customer' } & Pick<Customer, 'code'> & {
                                    user: { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'fullName'>;
                                };
                            country: { __typename?: 'Region' } & Pick<Region, 'name'> & {
                                    currencies: { __typename?: 'CurrencyConnection' } & {
                                        edges: Array<
                                            { __typename?: 'CurrencyEdge' } & {
                                                node: { __typename?: 'Currency' } & Pick<
                                                    Currency,
                                                    'rate' | 'abbreviation'
                                                >;
                                            }
                                        >;
                                    };
                                };
                            statuses: { __typename?: 'PurchaseOrderStatusHistoryConnection' } & {
                                edges: Array<
                                    { __typename?: 'PurchaseOrderStatusHistoryEdge' } & {
                                        node: { __typename?: 'PurchaseOrderStatusHistory' } & Pick<
                                            PurchaseOrderStatusHistory,
                                            'id' | 'status' | 'createdAt'
                                        >;
                                    }
                                >;
                            };
                            store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name'>;
                        };
                }
            >;
        };
};

export type ShelfsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<ShelfWhereInput>;
}>;

export type ShelfsQuery = { __typename?: 'Query' } & {
    shelfs: { __typename?: 'ShelfConnection' } & Pick<ShelfConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'ShelfEdge' } & {
                    node: { __typename?: 'Shelf' } & Pick<Shelf, 'id' | 'name' | 'externalWarehouseId'> & {
                            externalWarehouse: { __typename?: 'ExternalWarehouse' } & Pick<
                                ExternalWarehouse,
                                'id' | 'name'
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'page' | 'totalPages'>;
        };
};

export type TariffQueryVariables = Exact<{
    tariffId: Scalars['String'];
}>;

export type TariffQuery = { __typename?: 'Query' } & {
    tariff: { __typename?: 'Tariff' } & Pick<
        Tariff,
        'id' | 'countryId' | 'isActive' | 'price' | 'type' | 'weightId'
    > & { country: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> };
};

export type TariffsQueryVariables = Exact<{ [key: string]: never }>;

export type TariffsQuery = { __typename?: 'Query' } & {
    tariffs: { __typename?: 'TariffConnection' } & {
        edges: Array<
            { __typename?: 'TariffEdge' } & {
                node: { __typename?: 'Tariff' } & Pick<
                    Tariff,
                    'id' | 'isActive' | 'price' | 'type' | 'countryId' | 'weightId'
                > & {
                        weight: { __typename?: 'Weight' } & Pick<Weight, 'id' | 'name' | 'min' | 'max'>;
                        country: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>;
                    };
            }
        >;
    };
};

export type TransactionsByAdminQueryVariables = Exact<{
    where?: Maybe<TransactionWhereInput>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type TransactionsByAdminQuery = { __typename?: 'Query' } & {
    transactions: { __typename?: 'TransactionConnection' } & Pick<TransactionConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'TransactionEdge' } & {
                    node: { __typename?: 'Transaction' } & Pick<
                        Transaction,
                        | 'id'
                        | 'customerId'
                        | 'createdAt'
                        | 'amount'
                        | 'type'
                        | 'status'
                        | 'salesOrderId'
                        | 'sessionId'
                        | 'orderId'
                    > & {
                            customer: { __typename?: 'Customer' } & Pick<Customer, 'userId' | 'code'> & {
                                    user: { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName'>;
                                };
                            salesOrderLines?: Maybe<
                                { __typename?: 'SalesOrderLineConnection' } & {
                                    edges: Array<
                                        { __typename?: 'SalesOrderLineEdge' } & {
                                            node: { __typename?: 'SalesOrderLine' } & Pick<SalesOrderLine, 'type'> & {
                                                    package?: Maybe<
                                                        { __typename?: 'Package' } & Pick<
                                                            Package,
                                                            'id' | 'falkonTrackingNumber'
                                                        >
                                                    >;
                                                    purchaseOrder?: Maybe<
                                                        { __typename?: 'PurchaseOrder' } & Pick<
                                                            PurchaseOrder,
                                                            'id' | 'purchaseOrderNumber'
                                                        >
                                                    >;
                                                };
                                        }
                                    >;
                                }
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
        };
};

export type UserPermissionsQueryVariables = Exact<{
    where: UserPermissionWhereInput;
}>;

export type UserPermissionsQuery = { __typename?: 'Query' } & {
    userPermissions?: Maybe<
        { __typename?: 'UserPermissionConnection' } & {
            edges: Array<
                { __typename?: 'UserPermissionEdge' } & {
                    node: { __typename?: 'UserPermission' } & Pick<UserPermission, 'id' | 'userId' | 'permissionId'> & {
                            permission: { __typename?: 'Permission' } & Pick<Permission, 'id' | 'name'> & {
                                    parameters?: Maybe<
                                        { __typename?: 'PermissionParameterConnection' } & {
                                            edges: Array<
                                                { __typename?: 'PermissionParameterEdge' } & {
                                                    node: { __typename?: 'PermissionParameter' } & Pick<
                                                        PermissionParameter,
                                                        'id' | 'value'
                                                    >;
                                                }
                                            >;
                                        }
                                    >;
                                };
                        };
                }
            >;
        }
    >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            'id' | 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'dateOfBirth' | 'gender' | 'fullName'
        > & {
                customer?: Maybe<
                    { __typename?: 'Customer' } & Pick<
                        Customer,
                        'idFin' | 'idSerialNumber' | 'balance' | 'mounthlyLimit' | 'totalBonusAmount' | 'code'
                    > & {
                            branches: { __typename?: 'BranchConnection' } & {
                                edges: Array<
                                    { __typename?: 'BranchEdge' } & {
                                        node: { __typename?: 'Branch' } & Pick<Branch, 'name' | 'id'>;
                                    }
                                >;
                            };
                        }
                >;
                avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'name' | 'url'>>;
            }
    >;
};

export type MessagesQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
}>;

export type MessagesQuery = { __typename?: 'Query' } & {
    messages: { __typename?: 'MessageConnection' } & Pick<MessageConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'MessageEdge' } & {
                    node: { __typename?: 'Message' } & Pick<
                        Message,
                        | 'id'
                        | 'name'
                        | 'message'
                        | 'phoneNumber'
                        | 'isAnswered'
                        | 'code'
                        | 'createdAt'
                        | 'isRead'
                        | 'email'
                    > & {
                            children?: Maybe<
                                { __typename?: 'MessageConnection' } & {
                                    edges: Array<
                                        { __typename?: 'MessageEdge' } & {
                                            node: { __typename?: 'Message' } & Pick<Message, 'message' | 'createdAt'>;
                                        }
                                    >;
                                }
                            >;
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
        };
};

export type PackageQueryVariables = Exact<{
    packageId: Scalars['String'];
}>;

export type PackageQuery = { __typename?: 'Query' } & {
    package: { __typename?: 'Package' } & Pick<
        Package,
        | 'airWaybill'
        | 'note'
        | 'cargoCompany'
        | 'returnId'
        | 'parcelNumber'
        | 'storeTrackingNumber'
        | 'categoryId'
        | 'customerId'
        | 'count'
        | 'countryId'
        | 'createdAt'
        | 'updatedAt'
        | 'declaredValue'
        | 'deliveryPrice'
        | 'description'
        | 'estimatedAt'
        | 'falkonTrackingNumber'
        | 'height'
        | 'id'
        | 'invoicePrice'
        | 'length'
        | 'materialType'
        | 'smartCustomStatus'
        | 'paymentStatus'
        | 'status'
        | 'weight'
        | 'width'
    > & {
            category: { __typename?: 'Category' } & Pick<Category, 'name'>;
            purchaseOrder?: Maybe<{ __typename?: 'PurchaseOrder' } & Pick<PurchaseOrder, 'id' | 'purchaseOrderNumber'>>;
            store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name'>;
            country: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> & {
                    currencies: { __typename?: 'CurrencyConnection' } & {
                        edges: Array<
                            { __typename?: 'CurrencyEdge' } & {
                                node: { __typename?: 'Currency' } & Pick<Currency, 'rate' | 'abbreviation' | 'name'>;
                            }
                        >;
                    };
                };
            createdBy: { __typename?: 'User' } & Pick<User, 'fullName'> & {
                    customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'code' | 'id'>>;
                };
            file?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'size' | 'name' | 'url' | 'id'>>;
            customer: { __typename?: 'Customer' } & Pick<Customer, 'code'> & {
                    branches: { __typename?: 'BranchConnection' } & {
                        edges: Array<
                            { __typename?: 'BranchEdge' } & { node: { __typename?: 'Branch' } & Pick<Branch, 'price'> }
                        >;
                    };
                    user: { __typename?: 'User' } & Pick<User, 'fullName'>;
                };
        };
};

export type PackagesQueryVariables = Exact<{
    where?: Maybe<PackageWhereInput>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type PackagesQuery = { __typename?: 'Query' } & {
    packages: { __typename?: 'PackageConnection' } & Pick<PackageConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'PackageEdge' } & {
                    node: { __typename?: 'Package' } & Pick<
                        Package,
                        | 'id'
                        | 'status'
                        | 'smartCustomStatus'
                        | 'paymentStatus'
                        | 'materialType'
                        | 'count'
                        | 'invoicePrice'
                        | 'deliveryPrice'
                        | 'volumetricWeight'
                        | 'declaredValue'
                        | 'falkonTrackingNumber'
                        | 'storeTrackingNumber'
                        | 'airWaybill'
                        | 'parcelNumber'
                        | 'regNumber'
                        | 'packageNumber'
                        | 'width'
                        | 'height'
                        | 'length'
                        | 'weight'
                        | 'description'
                        | 'customerId'
                        | 'countryId'
                        | 'storeId'
                        | 'categoryId'
                        | 'fileId'
                        | 'createdById'
                        | 'updatedById'
                        | 'createdAt'
                        | 'updatedAt'
                        | 'estimatedAt'
                        | 'invoicePriceUpdated'
                        | 'shelfId'
                    > & {
                            customer: { __typename?: 'Customer' } & Pick<Customer, 'id' | 'code'>;
                            country: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> & {
                                    currencies: { __typename?: 'CurrencyConnection' } & {
                                        edges: Array<
                                            { __typename?: 'CurrencyEdge' } & {
                                                node: { __typename?: 'Currency' } & Pick<
                                                    Currency,
                                                    'rate' | 'abbreviation' | 'name'
                                                >;
                                            }
                                        >;
                                    };
                                };
                            createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'>;
                            updatedBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'fullName'>>;
                            store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name'>;
                            category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name'>;
                            file?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url' | 'name'>>;
                            statuses: { __typename?: 'PackageStatusHistoryConnection' } & {
                                edges: Array<
                                    { __typename?: 'PackageStatusHistoryEdge' } & {
                                        node: { __typename?: 'PackageStatusHistory' } & Pick<
                                            PackageStatusHistory,
                                            'id' | 'status' | 'createdById' | 'createdAt'
                                        >;
                                    }
                                >;
                            };
                        };
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'page' | 'totalPages' | 'hasNextPage' | 'hasPreviousPage'
            >;
        };
};

export type PermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsQuery = { __typename?: 'Query' } & {
    permissions?: Maybe<
        { __typename?: 'PermissionConnection' } & {
            edges: Array<
                { __typename?: 'PermissionEdge' } & {
                    node: { __typename?: 'Permission' } & Pick<Permission, 'id' | 'name' | 'description'> & {
                            parameters?: Maybe<
                                { __typename?: 'PermissionParameterConnection' } & {
                                    edges: Array<
                                        { __typename?: 'PermissionParameterEdge' } & {
                                            node: { __typename?: 'PermissionParameter' } & Pick<
                                                PermissionParameter,
                                                'id' | 'type' | 'value' | 'description' | 'permissionId'
                                            >;
                                        }
                                    >;
                                }
                            >;
                        };
                }
            >;
        }
    >;
};

export type PurchaseOrderQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type PurchaseOrderQuery = { __typename?: 'Query' } & {
    purchaseOrder: { __typename?: 'PurchaseOrder' } & Pick<
        PurchaseOrder,
        | 'id'
        | 'createdAt'
        | 'totalPrice'
        | 'invoicePrice'
        | 'storeCargoPrice'
        | 'status'
        | 'storeLink'
        | 'count'
        | 'color'
        | 'description'
        | 'size'
        | 'countryId'
        | 'expeditedServicePrice'
        | 'customerId'
    > & {
            package?: Maybe<
                { __typename?: 'Package' } & Pick<
                    Package,
                    'falkonTrackingNumber' | 'storeTrackingNumber' | 'categoryId' | 'materialType'
                >
            >;
            customer: { __typename?: 'Customer' } & Pick<Customer, 'id' | 'code'> & {
                    user: { __typename?: 'User' } & Pick<User, 'id' | 'fullName'>;
                };
        };
};

export type PurchaseOrdersQueryVariables = Exact<{
    where?: Maybe<PurchaseOrderWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<PurchaseOrderOrderBy>;
}>;

export type PurchaseOrdersQuery = { __typename?: 'Query' } & {
    purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & Pick<PurchaseOrderConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'PurchaseOrderEdge' } & {
                    node: { __typename?: 'PurchaseOrder' } & Pick<
                        PurchaseOrder,
                        | 'id'
                        | 'createdAt'
                        | 'customerId'
                        | 'totalPrice'
                        | 'invoicePrice'
                        | 'storeCargoPrice'
                        | 'status'
                        | 'storeLink'
                        | 'count'
                        | 'color'
                        | 'description'
                        | 'size'
                        | 'purchaseOrderNumber'
                        | 'expeditedServicePrice'
                    > & {
                            package?: Maybe<
                                { __typename?: 'Package' } & Pick<
                                    Package,
                                    'falkonTrackingNumber' | 'storeTrackingNumber' | 'categoryId'
                                >
                            >;
                            customer: { __typename?: 'Customer' } & Pick<Customer, 'code'> & {
                                    user: { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'fullName'>;
                                };
                            country: { __typename?: 'Region' } & Pick<Region, 'name'> & {
                                    currencies: { __typename?: 'CurrencyConnection' } & {
                                        edges: Array<
                                            { __typename?: 'CurrencyEdge' } & {
                                                node: { __typename?: 'Currency' } & Pick<
                                                    Currency,
                                                    'rate' | 'abbreviation'
                                                >;
                                            }
                                        >;
                                    };
                                };
                            statuses: { __typename?: 'PurchaseOrderStatusHistoryConnection' } & {
                                edges: Array<
                                    { __typename?: 'PurchaseOrderStatusHistoryEdge' } & {
                                        node: { __typename?: 'PurchaseOrderStatusHistory' } & Pick<
                                            PurchaseOrderStatusHistory,
                                            'id' | 'status' | 'createdAt'
                                        >;
                                    }
                                >;
                            };
                            store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name'>;
                        };
                }
            >;
        };
};

export type PurchaseSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type PurchaseSettingsQuery = { __typename?: 'Query' } & {
    configurations: { __typename?: 'ConfigurationConnection' } & {
        edges: Array<
            { __typename?: 'ConfigurationEdge' } & {
                node: { __typename?: 'Configuration' } & Pick<Configuration, 'id' | 'type' | 'price'>;
            }
        >;
    };
};

export type RatesQueryVariables = Exact<{ [key: string]: never }>;

export type RatesQuery = { __typename?: 'Query' } & {
    exchangeRates: { __typename?: 'ExchangeRateConnection' } & {
        edges: Array<
            { __typename?: 'ExchangeRateEdge' } & {
                node: { __typename?: 'ExchangeRate' } & Pick<ExchangeRate, 'from' | 'to' | 'rate'>;
            }
        >;
    };
};

export type ReturningPackagesQueryVariables = Exact<{ [key: string]: never }>;

export type ReturningPackagesQuery = { __typename?: 'Query' } & {
    returningPackages: { __typename?: 'PackageConnection' } & {
        edges: Array<
            { __typename?: 'PackageEdge' } & {
                node: { __typename?: 'Package' } & Pick<
                    Package,
                    | 'falkonTrackingNumber'
                    | 'id'
                    | 'invoicePrice'
                    | 'status'
                    | 'weight'
                    | 'volumetricWeight'
                    | 'deliveryPrice'
                > & {
                        store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name' | 'link'>;
                        category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'iconId' | 'parentId'>;
                        statuses: { __typename?: 'PackageStatusHistoryConnection' } & {
                            edges: Array<
                                { __typename?: 'PackageStatusHistoryEdge' } & {
                                    node: { __typename?: 'PackageStatusHistory' } & Pick<
                                        PackageStatusHistory,
                                        'id' | 'status' | 'createdById' | 'packageId'
                                    >;
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export type RoleQueryVariables = Exact<{
    roleId: Scalars['String'];
}>;

export type RoleQuery = { __typename?: 'Query' } & {
    role?: Maybe<
        { __typename?: 'Role' } & Pick<Role, 'id' | 'name'> & {
                rolePermissions?: Maybe<
                    { __typename?: 'RolePermissionConnection' } & {
                        edges: Array<
                            { __typename?: 'RolePermissionEdge' } & {
                                node: { __typename?: 'RolePermission' } & Pick<
                                    RolePermission,
                                    'id' | 'parameterId' | 'permissionId' | 'roleId'
                                > & {
                                        parameter: { __typename?: 'PermissionParameter' } & Pick<
                                            PermissionParameter,
                                            'id' | 'permissionId' | 'description' | 'type' | 'value'
                                        >;
                                    };
                            }
                        >;
                    }
                >;
            }
    >;
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = { __typename?: 'Query' } & {
    roles?: Maybe<
        { __typename?: 'RoleConnection' } & {
            edges: Array<
                { __typename?: 'RoleEdge' } & {
                    node: { __typename?: 'Role' } & Pick<Role, 'id' | 'name'> & {
                            rolePermissions?: Maybe<
                                { __typename?: 'RolePermissionConnection' } & {
                                    edges: Array<
                                        { __typename?: 'RolePermissionEdge' } & {
                                            node: { __typename?: 'RolePermission' } & Pick<
                                                RolePermission,
                                                'id' | 'parameterId' | 'permissionId' | 'roleId'
                                            > & {
                                                    parameter: { __typename?: 'PermissionParameter' } & Pick<
                                                        PermissionParameter,
                                                        'id' | 'description' | 'permissionId' | 'type' | 'value'
                                                    >;
                                                };
                                        }
                                    >;
                                }
                            >;
                        };
                }
            >;
        }
    >;
};

export type CheckSmsBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type CheckSmsBalanceQuery = { __typename?: 'Query' } & Pick<Query, 'checkSMSBalance'>;

export type GetStoreByIdQueryVariables = Exact<{
    storeId: Scalars['String'];
}>;

export type GetStoreByIdQuery = { __typename?: 'Query' } & {
    store: { __typename?: 'Store' } & Pick<Store, 'id' | 'name' | 'link' | 'isMerchantStore'> & {
            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'name' | 'url'>>;
            categories: { __typename?: 'CategoryConnection' } & {
                edges: Array<
                    { __typename?: 'CategoryEdge' } & {
                        node: { __typename?: 'Category' } & Pick<Category, 'id' | 'name'>;
                    }
                >;
            };
        };
};

export type StoresQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    where?: Maybe<StoreWhereInput>;
}>;

export type StoresQuery = { __typename?: 'Query' } & {
    stores: { __typename?: 'StoreConnection' } & Pick<StoreConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'totalPages' | 'page'
            >;
            edges: Array<
                { __typename?: 'StoreEdge' } & {
                    node: { __typename?: 'Store' } & Pick<Store, 'id' | 'name' | 'link'> & {
                            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'url'>>;
                            categories: { __typename?: 'CategoryConnection' } & {
                                edges: Array<
                                    { __typename?: 'CategoryEdge' } & {
                                        node: { __typename?: 'Category' } & Pick<
                                            Category,
                                            'id' | 'name' | 'iconId' | 'color'
                                        >;
                                    }
                                >;
                            };
                        };
                }
            >;
        };
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = { __typename?: 'Query' } & {
    customers?: Maybe<
        { __typename?: 'CustomerConnection' } & {
            edges: Array<
                { __typename?: 'CustomerEdge' } & {
                    node: { __typename?: 'Customer' } & Pick<Customer, 'code'> & {
                            user: { __typename?: 'User' } & Pick<User, 'fullName'>;
                        };
                }
            >;
        }
    >;
};

export type GetWeightByIdQueryVariables = Exact<{
    weightId: Scalars['String'];
}>;

export type GetWeightByIdQuery = { __typename?: 'Query' } & {
    weight: { __typename?: 'Weight' } & Pick<Weight, 'id' | 'name' | 'min' | 'max'>;
};

export type WeightsQueryVariables = Exact<{ [key: string]: never }>;

export type WeightsQuery = { __typename?: 'Query' } & {
    weights: { __typename?: 'WeightConnection' } & {
        edges: Array<
            { __typename?: 'WeightEdge' } & {
                node: { __typename?: 'Weight' } & Pick<Weight, 'id' | 'name' | 'min' | 'max'>;
            }
        >;
    };
};

export const ActivateAccountDocument = gql`
    mutation ActivateAccount($input: ActivateAccountInput!) {
        activateAccount(input: $input) {
            success
        }
    }
`;
export type ActivateAccountMutationFn = Apollo.MutationFunction<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>,
) {
    return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
        ActivateAccountDocument,
        baseOptions,
    );
}
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
>;
export const CompleteSocialRegistrationDocument = gql`
    mutation CompleteSocialRegistration($input: CompleteSocialRegistrationInput!) {
        completeSocialRegistration(input: $input)
    }
`;
export type CompleteSocialRegistrationMutationFn = Apollo.MutationFunction<
    CompleteSocialRegistrationMutation,
    CompleteSocialRegistrationMutationVariables
>;

/**
 * __useCompleteSocialRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteSocialRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSocialRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSocialRegistrationMutation, { data, loading, error }] = useCompleteSocialRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteSocialRegistrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteSocialRegistrationMutation,
        CompleteSocialRegistrationMutationVariables
    >,
) {
    return Apollo.useMutation<CompleteSocialRegistrationMutation, CompleteSocialRegistrationMutationVariables>(
        CompleteSocialRegistrationDocument,
        baseOptions,
    );
}
export type CompleteSocialRegistrationMutationHookResult = ReturnType<typeof useCompleteSocialRegistrationMutation>;
export type CompleteSocialRegistrationMutationResult = Apollo.MutationResult<CompleteSocialRegistrationMutation>;
export type CompleteSocialRegistrationMutationOptions = Apollo.BaseMutationOptions<
    CompleteSocialRegistrationMutation,
    CompleteSocialRegistrationMutationVariables
>;
export const FacebookLoginDocument = gql`
    mutation FacebookLogin {
        facebookLogin {
            accessToken
        }
    }
`;
export type FacebookLoginMutationFn = Apollo.MutationFunction<FacebookLoginMutation, FacebookLoginMutationVariables>;

/**
 * __useFacebookLoginMutation__
 *
 * To run a mutation, you first call `useFacebookLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookLoginMutation, { data, loading, error }] = useFacebookLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useFacebookLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<FacebookLoginMutation, FacebookLoginMutationVariables>,
) {
    return Apollo.useMutation<FacebookLoginMutation, FacebookLoginMutationVariables>(
        FacebookLoginDocument,
        baseOptions,
    );
}
export type FacebookLoginMutationHookResult = ReturnType<typeof useFacebookLoginMutation>;
export type FacebookLoginMutationResult = Apollo.MutationResult<FacebookLoginMutation>;
export type FacebookLoginMutationOptions = Apollo.BaseMutationOptions<
    FacebookLoginMutation,
    FacebookLoginMutationVariables
>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin {
        googleLogin {
            accessToken
        }
    }
`;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useGoogleLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>,
) {
    return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, baseOptions);
}
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($credentials: CredentialsInput!) {
        login(credentials: $credentials) {
            accessToken
        }
    }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
    return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, baseOptions);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
        ResetPasswordDocument,
        baseOptions,
    );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($input: ForgotPasswordInput!) {
        sendPasswordResetEmail(input: $input) {
            sent
        }
    }
`;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>,
) {
    return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(
        SendPasswordResetEmailDocument,
        baseOptions,
    );
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($message: CreateMessageInput!) {
        createMessage(message: $message) {
            message {
                id
                name
                email
                message
                phoneNumber
                isAnswered
                isRead
                code
                createdAt
                parentId
            }
        }
    }
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>,
) {
    return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
        CreateMessageDocument,
        baseOptions,
    );
}
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
    CreateMessageMutation,
    CreateMessageMutationVariables
>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            success
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
        ChangePasswordDocument,
        baseOptions,
    );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const CreatePackageDocument = gql`
    mutation CreatePackage($input: CreatePackageInput!) {
        createPackage(input: $input) {
            package {
                id
            }
        }
    }
`;
export type CreatePackageMutationFn = Apollo.MutationFunction<CreatePackageMutation, CreatePackageMutationVariables>;

/**
 * __useCreatePackageMutation__
 *
 * To run a mutation, you first call `useCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageMutation, { data, loading, error }] = useCreatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackageMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePackageMutation, CreatePackageMutationVariables>,
) {
    return Apollo.useMutation<CreatePackageMutation, CreatePackageMutationVariables>(
        CreatePackageDocument,
        baseOptions,
    );
}
export type CreatePackageMutationHookResult = ReturnType<typeof useCreatePackageMutation>;
export type CreatePackageMutationResult = Apollo.MutationResult<CreatePackageMutation>;
export type CreatePackageMutationOptions = Apollo.BaseMutationOptions<
    CreatePackageMutation,
    CreatePackageMutationVariables
>;
export const CreatePurchaseOrdersDocument = gql`
    mutation CreatePurchaseOrders($input: CreatePurchaseOrdersInput!) {
        createPurchaseOrders(input: $input) {
            purchaseOrders {
                count
            }
        }
    }
`;
export type CreatePurchaseOrdersMutationFn = Apollo.MutationFunction<
    CreatePurchaseOrdersMutation,
    CreatePurchaseOrdersMutationVariables
>;

/**
 * __useCreatePurchaseOrdersMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseOrdersMutation, { data, loading, error }] = useCreatePurchaseOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePurchaseOrdersMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePurchaseOrdersMutation, CreatePurchaseOrdersMutationVariables>,
) {
    return Apollo.useMutation<CreatePurchaseOrdersMutation, CreatePurchaseOrdersMutationVariables>(
        CreatePurchaseOrdersDocument,
        baseOptions,
    );
}
export type CreatePurchaseOrdersMutationHookResult = ReturnType<typeof useCreatePurchaseOrdersMutation>;
export type CreatePurchaseOrdersMutationResult = Apollo.MutationResult<CreatePurchaseOrdersMutation>;
export type CreatePurchaseOrdersMutationOptions = Apollo.BaseMutationOptions<
    CreatePurchaseOrdersMutation,
    CreatePurchaseOrdersMutationVariables
>;
export const DeletePackagesDocument = gql`
    mutation DeletePackages($input: DeletePackagesInput!) {
        deletePackages(input: $input)
    }
`;
export type DeletePackagesMutationFn = Apollo.MutationFunction<DeletePackagesMutation, DeletePackagesMutationVariables>;

/**
 * __useDeletePackagesMutation__
 *
 * To run a mutation, you first call `useDeletePackagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackagesMutation, { data, loading, error }] = useDeletePackagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePackagesMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePackagesMutation, DeletePackagesMutationVariables>,
) {
    return Apollo.useMutation<DeletePackagesMutation, DeletePackagesMutationVariables>(
        DeletePackagesDocument,
        baseOptions,
    );
}
export type DeletePackagesMutationHookResult = ReturnType<typeof useDeletePackagesMutation>;
export type DeletePackagesMutationResult = Apollo.MutationResult<DeletePackagesMutation>;
export type DeletePackagesMutationOptions = Apollo.BaseMutationOptions<
    DeletePackagesMutation,
    DeletePackagesMutationVariables
>;
export const DeletePurchaseOrdersDocument = gql`
    mutation DeletePurchaseOrders($input: DeletePurchaseOrdersInput!) {
        deletePurchaseOrders(input: $input)
    }
`;
export type DeletePurchaseOrdersMutationFn = Apollo.MutationFunction<
    DeletePurchaseOrdersMutation,
    DeletePurchaseOrdersMutationVariables
>;

/**
 * __useDeletePurchaseOrdersMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseOrdersMutation, { data, loading, error }] = useDeletePurchaseOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePurchaseOrdersMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePurchaseOrdersMutation, DeletePurchaseOrdersMutationVariables>,
) {
    return Apollo.useMutation<DeletePurchaseOrdersMutation, DeletePurchaseOrdersMutationVariables>(
        DeletePurchaseOrdersDocument,
        baseOptions,
    );
}
export type DeletePurchaseOrdersMutationHookResult = ReturnType<typeof useDeletePurchaseOrdersMutation>;
export type DeletePurchaseOrdersMutationResult = Apollo.MutationResult<DeletePurchaseOrdersMutation>;
export type DeletePurchaseOrdersMutationOptions = Apollo.BaseMutationOptions<
    DeletePurchaseOrdersMutation,
    DeletePurchaseOrdersMutationVariables
>;
export const IncreaseBalanceByCardDocument = gql`
    mutation IncreaseBalanceByCard($input: IncreaseBalanceByCardInput!) {
        increaseBalanceByCard(input: $input)
    }
`;
export type IncreaseBalanceByCardMutationFn = Apollo.MutationFunction<
    IncreaseBalanceByCardMutation,
    IncreaseBalanceByCardMutationVariables
>;

/**
 * __useIncreaseBalanceByCardMutation__
 *
 * To run a mutation, you first call `useIncreaseBalanceByCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncreaseBalanceByCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [increaseBalanceByCardMutation, { data, loading, error }] = useIncreaseBalanceByCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncreaseBalanceByCardMutation(
    baseOptions?: Apollo.MutationHookOptions<IncreaseBalanceByCardMutation, IncreaseBalanceByCardMutationVariables>,
) {
    return Apollo.useMutation<IncreaseBalanceByCardMutation, IncreaseBalanceByCardMutationVariables>(
        IncreaseBalanceByCardDocument,
        baseOptions,
    );
}
export type IncreaseBalanceByCardMutationHookResult = ReturnType<typeof useIncreaseBalanceByCardMutation>;
export type IncreaseBalanceByCardMutationResult = Apollo.MutationResult<IncreaseBalanceByCardMutation>;
export type IncreaseBalanceByCardMutationOptions = Apollo.BaseMutationOptions<
    IncreaseBalanceByCardMutation,
    IncreaseBalanceByCardMutationVariables
>;
export const PayDocument = gql`
    mutation Pay($input: PayInput!) {
        pay(input: $input)
    }
`;
export type PayMutationFn = Apollo.MutationFunction<PayMutation, PayMutationVariables>;

/**
 * __usePayMutation__
 *
 * To run a mutation, you first call `usePayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payMutation, { data, loading, error }] = usePayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayMutation(baseOptions?: Apollo.MutationHookOptions<PayMutation, PayMutationVariables>) {
    return Apollo.useMutation<PayMutation, PayMutationVariables>(PayDocument, baseOptions);
}
export type PayMutationHookResult = ReturnType<typeof usePayMutation>;
export type PayMutationResult = Apollo.MutationResult<PayMutation>;
export type PayMutationOptions = Apollo.BaseMutationOptions<PayMutation, PayMutationVariables>;
export const RegisterCustomerDocument = gql`
    mutation RegisterCustomer($customer: RegisterCustomerInput!) {
        registerCustomer(customer: $customer)
    }
`;
export type RegisterCustomerMutationFn = Apollo.MutationFunction<
    RegisterCustomerMutation,
    RegisterCustomerMutationVariables
>;

/**
 * __useRegisterCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerMutation, { data, loading, error }] = useRegisterCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useRegisterCustomerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>,
) {
    return Apollo.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(
        RegisterCustomerDocument,
        baseOptions,
    );
}
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = Apollo.MutationResult<RegisterCustomerMutation>;
export type RegisterCustomerMutationOptions = Apollo.BaseMutationOptions<
    RegisterCustomerMutation,
    RegisterCustomerMutationVariables
>;
export const ReturnPackageDocument = gql`
    mutation ReturnPackage($input: ReturnPackageInput!) {
        returnPackage(input: $input) {
            package {
                category {
                    id
                }
            }
        }
    }
`;
export type ReturnPackageMutationFn = Apollo.MutationFunction<ReturnPackageMutation, ReturnPackageMutationVariables>;

/**
 * __useReturnPackageMutation__
 *
 * To run a mutation, you first call `useReturnPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnPackageMutation, { data, loading, error }] = useReturnPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReturnPackageMutation(
    baseOptions?: Apollo.MutationHookOptions<ReturnPackageMutation, ReturnPackageMutationVariables>,
) {
    return Apollo.useMutation<ReturnPackageMutation, ReturnPackageMutationVariables>(
        ReturnPackageDocument,
        baseOptions,
    );
}
export type ReturnPackageMutationHookResult = ReturnType<typeof useReturnPackageMutation>;
export type ReturnPackageMutationResult = Apollo.MutationResult<ReturnPackageMutation>;
export type ReturnPackageMutationOptions = Apollo.BaseMutationOptions<
    ReturnPackageMutation,
    ReturnPackageMutationVariables
>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($customer: UpdateCustomerInput!) {
        updateCustomer(customer: $customer) {
            customer {
                id
            }
        }
    }
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>,
) {
    return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
        UpdateCustomerDocument,
        baseOptions,
    );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
>;
export const UpdatePackageDocument = gql`
    mutation UpdatePackage($input: UpdatePackageInput!) {
        updatePackage(input: $input) {
            package {
                id
            }
        }
    }
`;
export type UpdatePackageMutationFn = Apollo.MutationFunction<UpdatePackageMutation, UpdatePackageMutationVariables>;

/**
 * __useUpdatePackageMutation__
 *
 * To run a mutation, you first call `useUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageMutation, { data, loading, error }] = useUpdatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackageMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePackageMutation, UpdatePackageMutationVariables>,
) {
    return Apollo.useMutation<UpdatePackageMutation, UpdatePackageMutationVariables>(
        UpdatePackageDocument,
        baseOptions,
    );
}
export type UpdatePackageMutationHookResult = ReturnType<typeof useUpdatePackageMutation>;
export type UpdatePackageMutationResult = Apollo.MutationResult<UpdatePackageMutation>;
export type UpdatePackageMutationOptions = Apollo.BaseMutationOptions<
    UpdatePackageMutation,
    UpdatePackageMutationVariables
>;
export const UpdatePurchaseOrderDocument = gql`
    mutation UpdatePurchaseOrder($input: UpdatePurchaseOrderInput!) {
        updatePurchaseOrder(input: $input) {
            purchaseOrder {
                id
            }
        }
    }
`;
export type UpdatePurchaseOrderMutationFn = Apollo.MutationFunction<
    UpdatePurchaseOrderMutation,
    UpdatePurchaseOrderMutationVariables
>;

/**
 * __useUpdatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderMutation, { data, loading, error }] = useUpdatePurchaseOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchaseOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>,
) {
    return Apollo.useMutation<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>(
        UpdatePurchaseOrderDocument,
        baseOptions,
    );
}
export type UpdatePurchaseOrderMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationResult = Apollo.MutationResult<UpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseOrderMutation,
    UpdatePurchaseOrderMutationVariables
>;
export const AddToBoxDocument = gql`
    mutation AddToBox($input: AddToBoxInput!) {
        addToBox(input: $input)
    }
`;
export type AddToBoxMutationFn = Apollo.MutationFunction<AddToBoxMutation, AddToBoxMutationVariables>;

/**
 * __useAddToBoxMutation__
 *
 * To run a mutation, you first call `useAddToBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBoxMutation, { data, loading, error }] = useAddToBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToBoxMutation(
    baseOptions?: Apollo.MutationHookOptions<AddToBoxMutation, AddToBoxMutationVariables>,
) {
    return Apollo.useMutation<AddToBoxMutation, AddToBoxMutationVariables>(AddToBoxDocument, baseOptions);
}
export type AddToBoxMutationHookResult = ReturnType<typeof useAddToBoxMutation>;
export type AddToBoxMutationResult = Apollo.MutationResult<AddToBoxMutation>;
export type AddToBoxMutationOptions = Apollo.BaseMutationOptions<AddToBoxMutation, AddToBoxMutationVariables>;
export const AssignPackagesToContainerDocument = gql`
    mutation AssignPackagesToContainer($input: AssignPackagesToContainerInput!) {
        assignPackagesToContainer(input: $input) {
            container {
                packages {
                    edges {
                        node {
                            id
                            falkonTrackingNumber
                            weight
                        }
                    }
                }
            }
        }
    }
`;
export type AssignPackagesToContainerMutationFn = Apollo.MutationFunction<
    AssignPackagesToContainerMutation,
    AssignPackagesToContainerMutationVariables
>;

/**
 * __useAssignPackagesToContainerMutation__
 *
 * To run a mutation, you first call `useAssignPackagesToContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPackagesToContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPackagesToContainerMutation, { data, loading, error }] = useAssignPackagesToContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignPackagesToContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AssignPackagesToContainerMutation,
        AssignPackagesToContainerMutationVariables
    >,
) {
    return Apollo.useMutation<AssignPackagesToContainerMutation, AssignPackagesToContainerMutationVariables>(
        AssignPackagesToContainerDocument,
        baseOptions,
    );
}
export type AssignPackagesToContainerMutationHookResult = ReturnType<typeof useAssignPackagesToContainerMutation>;
export type AssignPackagesToContainerMutationResult = Apollo.MutationResult<AssignPackagesToContainerMutation>;
export type AssignPackagesToContainerMutationOptions = Apollo.BaseMutationOptions<
    AssignPackagesToContainerMutation,
    AssignPackagesToContainerMutationVariables
>;
export const CancelAddToBoxDocument = gql`
    mutation CancelAddToBox($input: CancelAddToBoxInput!) {
        cancelAddToBox(input: $input)
    }
`;
export type CancelAddToBoxMutationFn = Apollo.MutationFunction<CancelAddToBoxMutation, CancelAddToBoxMutationVariables>;

/**
 * __useCancelAddToBoxMutation__
 *
 * To run a mutation, you first call `useCancelAddToBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAddToBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAddToBoxMutation, { data, loading, error }] = useCancelAddToBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAddToBoxMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelAddToBoxMutation, CancelAddToBoxMutationVariables>,
) {
    return Apollo.useMutation<CancelAddToBoxMutation, CancelAddToBoxMutationVariables>(
        CancelAddToBoxDocument,
        baseOptions,
    );
}
export type CancelAddToBoxMutationHookResult = ReturnType<typeof useCancelAddToBoxMutation>;
export type CancelAddToBoxMutationResult = Apollo.MutationResult<CancelAddToBoxMutation>;
export type CancelAddToBoxMutationOptions = Apollo.BaseMutationOptions<
    CancelAddToBoxMutation,
    CancelAddToBoxMutationVariables
>;
export const CancelDepeshDocument = gql`
    mutation CancelDepesh($input: CancelDepeshInput!) {
        cancelDepesh(input: $input)
    }
`;
export type CancelDepeshMutationFn = Apollo.MutationFunction<CancelDepeshMutation, CancelDepeshMutationVariables>;

/**
 * __useCancelDepeshMutation__
 *
 * To run a mutation, you first call `useCancelDepeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDepeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDepeshMutation, { data, loading, error }] = useCancelDepeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDepeshMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelDepeshMutation, CancelDepeshMutationVariables>,
) {
    return Apollo.useMutation<CancelDepeshMutation, CancelDepeshMutationVariables>(CancelDepeshDocument, baseOptions);
}
export type CancelDepeshMutationHookResult = ReturnType<typeof useCancelDepeshMutation>;
export type CancelDepeshMutationResult = Apollo.MutationResult<CancelDepeshMutation>;
export type CancelDepeshMutationOptions = Apollo.BaseMutationOptions<
    CancelDepeshMutation,
    CancelDepeshMutationVariables
>;
export const CheckToSmartCustomsStatusDocument = gql`
    mutation CheckToSmartCustomsStatus($input: CheckToSmartCustomsStatusInput!) {
        checkToSmartCustomsStatus(input: $input)
    }
`;
export type CheckToSmartCustomsStatusMutationFn = Apollo.MutationFunction<
    CheckToSmartCustomsStatusMutation,
    CheckToSmartCustomsStatusMutationVariables
>;

/**
 * __useCheckToSmartCustomsStatusMutation__
 *
 * To run a mutation, you first call `useCheckToSmartCustomsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckToSmartCustomsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkToSmartCustomsStatusMutation, { data, loading, error }] = useCheckToSmartCustomsStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckToSmartCustomsStatusMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CheckToSmartCustomsStatusMutation,
        CheckToSmartCustomsStatusMutationVariables
    >,
) {
    return Apollo.useMutation<CheckToSmartCustomsStatusMutation, CheckToSmartCustomsStatusMutationVariables>(
        CheckToSmartCustomsStatusDocument,
        baseOptions,
    );
}
export type CheckToSmartCustomsStatusMutationHookResult = ReturnType<typeof useCheckToSmartCustomsStatusMutation>;
export type CheckToSmartCustomsStatusMutationResult = Apollo.MutationResult<CheckToSmartCustomsStatusMutation>;
export type CheckToSmartCustomsStatusMutationOptions = Apollo.BaseMutationOptions<
    CheckToSmartCustomsStatusMutation,
    CheckToSmartCustomsStatusMutationVariables
>;
export const CreateBranchDocument = gql`
    mutation createBranch($branch: CreateBranchInput!) {
        createBranch(branch: $branch) {
            branch {
                id
            }
        }
    }
`;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *   },
 * });
 */
export function useCreateBranchMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>,
) {
    return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, baseOptions);
}
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<
    CreateBranchMutation,
    CreateBranchMutationVariables
>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($category: CreateCategoryInput!) {
        createCategory(category: $category) {
            category {
                id
                name
                iconId
                color
                createdAt
                children {
                    edges {
                        node {
                            id
                            name
                            iconId
                            color
                        }
                    }
                }
            }
        }
    }
`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>,
) {
    return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(
        CreateCategoryDocument,
        baseOptions,
    );
}
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
>;
export const CreateContainerDocument = gql`
    mutation CreateContainer($input: CreateContainerInput!) {
        createContainer(input: $input) {
            container {
                id
                name
                packageCount
                totalWeight
            }
        }
    }
`;
export type CreateContainerMutationFn = Apollo.MutationFunction<
    CreateContainerMutation,
    CreateContainerMutationVariables
>;

/**
 * __useCreateContainerMutation__
 *
 * To run a mutation, you first call `useCreateContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContainerMutation, { data, loading, error }] = useCreateContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateContainerMutation, CreateContainerMutationVariables>,
) {
    return Apollo.useMutation<CreateContainerMutation, CreateContainerMutationVariables>(
        CreateContainerDocument,
        baseOptions,
    );
}
export type CreateContainerMutationHookResult = ReturnType<typeof useCreateContainerMutation>;
export type CreateContainerMutationResult = Apollo.MutationResult<CreateContainerMutation>;
export type CreateContainerMutationOptions = Apollo.BaseMutationOptions<
    CreateContainerMutation,
    CreateContainerMutationVariables
>;
export const RegisterCustomerByAdminDocument = gql`
    mutation registerCustomerByAdmin($customer: RegisterCustomerByAdminInput!) {
        registerCustomerByAdmin(customer: $customer)
    }
`;
export type RegisterCustomerByAdminMutationFn = Apollo.MutationFunction<
    RegisterCustomerByAdminMutation,
    RegisterCustomerByAdminMutationVariables
>;

/**
 * __useRegisterCustomerByAdminMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerByAdminMutation, { data, loading, error }] = useRegisterCustomerByAdminMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useRegisterCustomerByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterCustomerByAdminMutation, RegisterCustomerByAdminMutationVariables>,
) {
    return Apollo.useMutation<RegisterCustomerByAdminMutation, RegisterCustomerByAdminMutationVariables>(
        RegisterCustomerByAdminDocument,
        baseOptions,
    );
}
export type RegisterCustomerByAdminMutationHookResult = ReturnType<typeof useRegisterCustomerByAdminMutation>;
export type RegisterCustomerByAdminMutationResult = Apollo.MutationResult<RegisterCustomerByAdminMutation>;
export type RegisterCustomerByAdminMutationOptions = Apollo.BaseMutationOptions<
    RegisterCustomerByAdminMutation,
    RegisterCustomerByAdminMutationVariables
>;
export const CreateExternalWarehouseDocument = gql`
    mutation CreateExternalWarehouse($input: CreateExternalWarehouseInput!) {
        createExternalWarehouse(input: $input) {
            externalWarehouse {
                id
                name
                externalShelfs {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
export type CreateExternalWarehouseMutationFn = Apollo.MutationFunction<
    CreateExternalWarehouseMutation,
    CreateExternalWarehouseMutationVariables
>;

/**
 * __useCreateExternalWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateExternalWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalWarehouseMutation, { data, loading, error }] = useCreateExternalWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalWarehouseMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateExternalWarehouseMutation, CreateExternalWarehouseMutationVariables>,
) {
    return Apollo.useMutation<CreateExternalWarehouseMutation, CreateExternalWarehouseMutationVariables>(
        CreateExternalWarehouseDocument,
        baseOptions,
    );
}
export type CreateExternalWarehouseMutationHookResult = ReturnType<typeof useCreateExternalWarehouseMutation>;
export type CreateExternalWarehouseMutationResult = Apollo.MutationResult<CreateExternalWarehouseMutation>;
export type CreateExternalWarehouseMutationOptions = Apollo.BaseMutationOptions<
    CreateExternalWarehouseMutation,
    CreateExternalWarehouseMutationVariables
>;
export const CreateFlightDocument = gql`
    mutation CreateFlight($input: CreateFlightInput!) {
        createFlight(input: $input) {
            flight {
                id
            }
        }
    }
`;
export type CreateFlightMutationFn = Apollo.MutationFunction<CreateFlightMutation, CreateFlightMutationVariables>;

/**
 * __useCreateFlightMutation__
 *
 * To run a mutation, you first call `useCreateFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlightMutation, { data, loading, error }] = useCreateFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFlightMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateFlightMutation, CreateFlightMutationVariables>,
) {
    return Apollo.useMutation<CreateFlightMutation, CreateFlightMutationVariables>(CreateFlightDocument, baseOptions);
}
export type CreateFlightMutationHookResult = ReturnType<typeof useCreateFlightMutation>;
export type CreateFlightMutationResult = Apollo.MutationResult<CreateFlightMutation>;
export type CreateFlightMutationOptions = Apollo.BaseMutationOptions<
    CreateFlightMutation,
    CreateFlightMutationVariables
>;
export const CreateInternalWarehouseDocument = gql`
    mutation CreateInternalWarehouse($input: CreateInternalWarehouseInput!) {
        createInternalWarehouse(input: $input) {
            internalWarehouse {
                name
                id
                internalShelfs {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
export type CreateInternalWarehouseMutationFn = Apollo.MutationFunction<
    CreateInternalWarehouseMutation,
    CreateInternalWarehouseMutationVariables
>;

/**
 * __useCreateInternalWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateInternalWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInternalWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInternalWarehouseMutation, { data, loading, error }] = useCreateInternalWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInternalWarehouseMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateInternalWarehouseMutation, CreateInternalWarehouseMutationVariables>,
) {
    return Apollo.useMutation<CreateInternalWarehouseMutation, CreateInternalWarehouseMutationVariables>(
        CreateInternalWarehouseDocument,
        baseOptions,
    );
}
export type CreateInternalWarehouseMutationHookResult = ReturnType<typeof useCreateInternalWarehouseMutation>;
export type CreateInternalWarehouseMutationResult = Apollo.MutationResult<CreateInternalWarehouseMutation>;
export type CreateInternalWarehouseMutationOptions = Apollo.BaseMutationOptions<
    CreateInternalWarehouseMutation,
    CreateInternalWarehouseMutationVariables
>;
export const CreateInternalShelfDocument = gql`
    mutation CreateInternalShelf($input: CreateInternalShelfInput!) {
        createInternalShelf(input: $input) {
            shelf {
                id
                name
            }
        }
    }
`;
export type CreateInternalShelfMutationFn = Apollo.MutationFunction<
    CreateInternalShelfMutation,
    CreateInternalShelfMutationVariables
>;

/**
 * __useCreateInternalShelfMutation__
 *
 * To run a mutation, you first call `useCreateInternalShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInternalShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInternalShelfMutation, { data, loading, error }] = useCreateInternalShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInternalShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateInternalShelfMutation, CreateInternalShelfMutationVariables>,
) {
    return Apollo.useMutation<CreateInternalShelfMutation, CreateInternalShelfMutationVariables>(
        CreateInternalShelfDocument,
        baseOptions,
    );
}
export type CreateInternalShelfMutationHookResult = ReturnType<typeof useCreateInternalShelfMutation>;
export type CreateInternalShelfMutationResult = Apollo.MutationResult<CreateInternalShelfMutation>;
export type CreateInternalShelfMutationOptions = Apollo.BaseMutationOptions<
    CreateInternalShelfMutation,
    CreateInternalShelfMutationVariables
>;
export const CreateShelfDocument = gql`
    mutation CreateShelf($input: CreateShelfInput!) {
        createShelf(input: $input) {
            shelf {
                id
            }
        }
    }
`;
export type CreateShelfMutationFn = Apollo.MutationFunction<CreateShelfMutation, CreateShelfMutationVariables>;

/**
 * __useCreateShelfMutation__
 *
 * To run a mutation, you first call `useCreateShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShelfMutation, { data, loading, error }] = useCreateShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateShelfMutation, CreateShelfMutationVariables>,
) {
    return Apollo.useMutation<CreateShelfMutation, CreateShelfMutationVariables>(CreateShelfDocument, baseOptions);
}
export type CreateShelfMutationHookResult = ReturnType<typeof useCreateShelfMutation>;
export type CreateShelfMutationResult = Apollo.MutationResult<CreateShelfMutation>;
export type CreateShelfMutationOptions = Apollo.BaseMutationOptions<CreateShelfMutation, CreateShelfMutationVariables>;
export const CreatePackageByAdminDocument = gql`
    mutation CreatePackageByAdmin($input: CreatePackageByAdminInput!) {
        createPackageByAdmin(input: $input) {
            package {
                id
                count
                weight
                invoicePrice
                deliveryPrice
                declaredValue
                falkonTrackingNumber
                store {
                    id
                    name
                }
                country {
                    id
                    name
                }
                customer {
                    code
                    branches {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                    idFin
                    user {
                        firstName
                        lastName
                        fullName
                        phoneNumber
                        address
                    }
                }
            }
        }
    }
`;
export type CreatePackageByAdminMutationFn = Apollo.MutationFunction<
    CreatePackageByAdminMutation,
    CreatePackageByAdminMutationVariables
>;

/**
 * __useCreatePackageByAdminMutation__
 *
 * To run a mutation, you first call `useCreatePackageByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageByAdminMutation, { data, loading, error }] = useCreatePackageByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackageByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePackageByAdminMutation, CreatePackageByAdminMutationVariables>,
) {
    return Apollo.useMutation<CreatePackageByAdminMutation, CreatePackageByAdminMutationVariables>(
        CreatePackageByAdminDocument,
        baseOptions,
    );
}
export type CreatePackageByAdminMutationHookResult = ReturnType<typeof useCreatePackageByAdminMutation>;
export type CreatePackageByAdminMutationResult = Apollo.MutationResult<CreatePackageByAdminMutation>;
export type CreatePackageByAdminMutationOptions = Apollo.BaseMutationOptions<
    CreatePackageByAdminMutation,
    CreatePackageByAdminMutationVariables
>;
export const CreatePostDocument = gql`
    mutation CreatePost($blogPost: CreateBlogPostInput!) {
        createPost(blogPost: $blogPost) {
            post {
                id
            }
        }
    }
`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useCreatePostMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>,
) {
    return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
}
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const CreatePurchaseOrderByAdminDocument = gql`
    mutation CreatePurchaseOrderByAdmin($input: CreatePurchaseOrderByAdminInput!) {
        createPurchaseOrderByAdmin(input: $input) {
            purchaseOrder {
                id
            }
        }
    }
`;
export type CreatePurchaseOrderByAdminMutationFn = Apollo.MutationFunction<
    CreatePurchaseOrderByAdminMutation,
    CreatePurchaseOrderByAdminMutationVariables
>;

/**
 * __useCreatePurchaseOrderByAdminMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseOrderByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseOrderByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseOrderByAdminMutation, { data, loading, error }] = useCreatePurchaseOrderByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePurchaseOrderByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePurchaseOrderByAdminMutation,
        CreatePurchaseOrderByAdminMutationVariables
    >,
) {
    return Apollo.useMutation<CreatePurchaseOrderByAdminMutation, CreatePurchaseOrderByAdminMutationVariables>(
        CreatePurchaseOrderByAdminDocument,
        baseOptions,
    );
}
export type CreatePurchaseOrderByAdminMutationHookResult = ReturnType<typeof useCreatePurchaseOrderByAdminMutation>;
export type CreatePurchaseOrderByAdminMutationResult = Apollo.MutationResult<CreatePurchaseOrderByAdminMutation>;
export type CreatePurchaseOrderByAdminMutationOptions = Apollo.BaseMutationOptions<
    CreatePurchaseOrderByAdminMutation,
    CreatePurchaseOrderByAdminMutationVariables
>;
export const CreateStoreDocument = gql`
    mutation CreateStore($store: CreateStoreInput!) {
        createStore(store: $store) {
            store {
                id
                name
                link
                categories {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      store: // value for 'store'
 *   },
 * });
 */
export function useCreateStoreMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>,
) {
    return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, baseOptions);
}
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const CreateTariffDocument = gql`
    mutation CreateTariff($tariff: CreateTariffInput!) {
        createTariff(tariff: $tariff) {
            tariff {
                id
            }
        }
    }
`;
export type CreateTariffMutationFn = Apollo.MutationFunction<CreateTariffMutation, CreateTariffMutationVariables>;

/**
 * __useCreateTariffMutation__
 *
 * To run a mutation, you first call `useCreateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffMutation, { data, loading, error }] = useCreateTariffMutation({
 *   variables: {
 *      tariff: // value for 'tariff'
 *   },
 * });
 */
export function useCreateTariffMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateTariffMutation, CreateTariffMutationVariables>,
) {
    return Apollo.useMutation<CreateTariffMutation, CreateTariffMutationVariables>(CreateTariffDocument, baseOptions);
}
export type CreateTariffMutationHookResult = ReturnType<typeof useCreateTariffMutation>;
export type CreateTariffMutationResult = Apollo.MutationResult<CreateTariffMutation>;
export type CreateTariffMutationOptions = Apollo.BaseMutationOptions<
    CreateTariffMutation,
    CreateTariffMutationVariables
>;
export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleInput!) {
        createRole(input: $input) {
            role {
                id
                name
            }
        }
    }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>,
) {
    return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const CreateWeightDocument = gql`
    mutation CreateWeight($weight: CreateWeightInput!) {
        createWeight(weight: $weight) {
            weight {
                id
                name
                min
                max
            }
        }
    }
`;
export type CreateWeightMutationFn = Apollo.MutationFunction<CreateWeightMutation, CreateWeightMutationVariables>;

/**
 * __useCreateWeightMutation__
 *
 * To run a mutation, you first call `useCreateWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeightMutation, { data, loading, error }] = useCreateWeightMutation({
 *   variables: {
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCreateWeightMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateWeightMutation, CreateWeightMutationVariables>,
) {
    return Apollo.useMutation<CreateWeightMutation, CreateWeightMutationVariables>(CreateWeightDocument, baseOptions);
}
export type CreateWeightMutationHookResult = ReturnType<typeof useCreateWeightMutation>;
export type CreateWeightMutationResult = Apollo.MutationResult<CreateWeightMutation>;
export type CreateWeightMutationOptions = Apollo.BaseMutationOptions<
    CreateWeightMutation,
    CreateWeightMutationVariables
>;
export const DeleteBlogPostDocument = gql`
    mutation DeleteBlogPost($deleteBlogPostId: String!) {
        deleteBlogPost(id: $deleteBlogPostId) {
            id
        }
    }
`;
export type DeleteBlogPostMutationFn = Apollo.MutationFunction<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;

/**
 * __useDeleteBlogPostMutation__
 *
 * To run a mutation, you first call `useDeleteBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogPostMutation, { data, loading, error }] = useDeleteBlogPostMutation({
 *   variables: {
 *      deleteBlogPostId: // value for 'deleteBlogPostId'
 *   },
 * });
 */
export function useDeleteBlogPostMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>,
) {
    return Apollo.useMutation<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>(
        DeleteBlogPostDocument,
        baseOptions,
    );
}
export type DeleteBlogPostMutationHookResult = ReturnType<typeof useDeleteBlogPostMutation>;
export type DeleteBlogPostMutationResult = Apollo.MutationResult<DeleteBlogPostMutation>;
export type DeleteBlogPostMutationOptions = Apollo.BaseMutationOptions<
    DeleteBlogPostMutation,
    DeleteBlogPostMutationVariables
>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($deleteCategoryId: String!) {
        deleteCategory(id: $deleteCategoryId) {
            id
        }
    }
`;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      deleteCategoryId: // value for 'deleteCategoryId'
 *   },
 * });
 */
export function useDeleteCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>,
) {
    return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(
        DeleteCategoryDocument,
        baseOptions,
    );
}
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
    DeleteCategoryMutation,
    DeleteCategoryMutationVariables
>;
export const DeleteContainerDocument = gql`
    mutation DeleteContainer($input: DeleteContainerInput!) {
        deleteContainer(input: $input) {
            id
        }
    }
`;
export type DeleteContainerMutationFn = Apollo.MutationFunction<
    DeleteContainerMutation,
    DeleteContainerMutationVariables
>;

/**
 * __useDeleteContainerMutation__
 *
 * To run a mutation, you first call `useDeleteContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContainerMutation, { data, loading, error }] = useDeleteContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteContainerMutation, DeleteContainerMutationVariables>,
) {
    return Apollo.useMutation<DeleteContainerMutation, DeleteContainerMutationVariables>(
        DeleteContainerDocument,
        baseOptions,
    );
}
export type DeleteContainerMutationHookResult = ReturnType<typeof useDeleteContainerMutation>;
export type DeleteContainerMutationResult = Apollo.MutationResult<DeleteContainerMutation>;
export type DeleteContainerMutationOptions = Apollo.BaseMutationOptions<
    DeleteContainerMutation,
    DeleteContainerMutationVariables
>;
export const DeleteCustomersDocument = gql`
    mutation DeleteCustomers($input: DeleteCustomersInput!) {
        deleteCustomers(input: $input)
    }
`;
export type DeleteCustomersMutationFn = Apollo.MutationFunction<
    DeleteCustomersMutation,
    DeleteCustomersMutationVariables
>;

/**
 * __useDeleteCustomersMutation__
 *
 * To run a mutation, you first call `useDeleteCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomersMutation, { data, loading, error }] = useDeleteCustomersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomersMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCustomersMutation, DeleteCustomersMutationVariables>,
) {
    return Apollo.useMutation<DeleteCustomersMutation, DeleteCustomersMutationVariables>(
        DeleteCustomersDocument,
        baseOptions,
    );
}
export type DeleteCustomersMutationHookResult = ReturnType<typeof useDeleteCustomersMutation>;
export type DeleteCustomersMutationResult = Apollo.MutationResult<DeleteCustomersMutation>;
export type DeleteCustomersMutationOptions = Apollo.BaseMutationOptions<
    DeleteCustomersMutation,
    DeleteCustomersMutationVariables
>;
export const DeleteExternalWarehouseDocument = gql`
    mutation DeleteExternalWarehouse($deleteExternalWarehouseId: String!) {
        deleteExternalWarehouse(id: $deleteExternalWarehouseId) {
            id
        }
    }
`;
export type DeleteExternalWarehouseMutationFn = Apollo.MutationFunction<
    DeleteExternalWarehouseMutation,
    DeleteExternalWarehouseMutationVariables
>;

/**
 * __useDeleteExternalWarehouseMutation__
 *
 * To run a mutation, you first call `useDeleteExternalWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalWarehouseMutation, { data, loading, error }] = useDeleteExternalWarehouseMutation({
 *   variables: {
 *      deleteExternalWarehouseId: // value for 'deleteExternalWarehouseId'
 *   },
 * });
 */
export function useDeleteExternalWarehouseMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteExternalWarehouseMutation, DeleteExternalWarehouseMutationVariables>,
) {
    return Apollo.useMutation<DeleteExternalWarehouseMutation, DeleteExternalWarehouseMutationVariables>(
        DeleteExternalWarehouseDocument,
        baseOptions,
    );
}
export type DeleteExternalWarehouseMutationHookResult = ReturnType<typeof useDeleteExternalWarehouseMutation>;
export type DeleteExternalWarehouseMutationResult = Apollo.MutationResult<DeleteExternalWarehouseMutation>;
export type DeleteExternalWarehouseMutationOptions = Apollo.BaseMutationOptions<
    DeleteExternalWarehouseMutation,
    DeleteExternalWarehouseMutationVariables
>;
export const DeleteFlightDocument = gql`
    mutation DeleteFlight($input: DeleteFlightInput!) {
        deleteFlight(input: $input) {
            id
        }
    }
`;
export type DeleteFlightMutationFn = Apollo.MutationFunction<DeleteFlightMutation, DeleteFlightMutationVariables>;

/**
 * __useDeleteFlightMutation__
 *
 * To run a mutation, you first call `useDeleteFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFlightMutation, { data, loading, error }] = useDeleteFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFlightMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteFlightMutation, DeleteFlightMutationVariables>,
) {
    return Apollo.useMutation<DeleteFlightMutation, DeleteFlightMutationVariables>(DeleteFlightDocument, baseOptions);
}
export type DeleteFlightMutationHookResult = ReturnType<typeof useDeleteFlightMutation>;
export type DeleteFlightMutationResult = Apollo.MutationResult<DeleteFlightMutation>;
export type DeleteFlightMutationOptions = Apollo.BaseMutationOptions<
    DeleteFlightMutation,
    DeleteFlightMutationVariables
>;
export const DeleteInternalShelfDocument = gql`
    mutation DeleteInternalShelf($deleteInternalShelfId: String!) {
        deleteInternalShelf(id: $deleteInternalShelfId) {
            id
        }
    }
`;
export type DeleteInternalShelfMutationFn = Apollo.MutationFunction<
    DeleteInternalShelfMutation,
    DeleteInternalShelfMutationVariables
>;

/**
 * __useDeleteInternalShelfMutation__
 *
 * To run a mutation, you first call `useDeleteInternalShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInternalShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInternalShelfMutation, { data, loading, error }] = useDeleteInternalShelfMutation({
 *   variables: {
 *      deleteInternalShelfId: // value for 'deleteInternalShelfId'
 *   },
 * });
 */
export function useDeleteInternalShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteInternalShelfMutation, DeleteInternalShelfMutationVariables>,
) {
    return Apollo.useMutation<DeleteInternalShelfMutation, DeleteInternalShelfMutationVariables>(
        DeleteInternalShelfDocument,
        baseOptions,
    );
}
export type DeleteInternalShelfMutationHookResult = ReturnType<typeof useDeleteInternalShelfMutation>;
export type DeleteInternalShelfMutationResult = Apollo.MutationResult<DeleteInternalShelfMutation>;
export type DeleteInternalShelfMutationOptions = Apollo.BaseMutationOptions<
    DeleteInternalShelfMutation,
    DeleteInternalShelfMutationVariables
>;
export const DeleteInternalWarehouseDocument = gql`
    mutation DeleteInternalWarehouse($input: DeleteInternalWarehouseInput!) {
        deleteInternalWarehouse(input: $input) {
            id
        }
    }
`;
export type DeleteInternalWarehouseMutationFn = Apollo.MutationFunction<
    DeleteInternalWarehouseMutation,
    DeleteInternalWarehouseMutationVariables
>;

/**
 * __useDeleteInternalWarehouseMutation__
 *
 * To run a mutation, you first call `useDeleteInternalWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInternalWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInternalWarehouseMutation, { data, loading, error }] = useDeleteInternalWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInternalWarehouseMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteInternalWarehouseMutation, DeleteInternalWarehouseMutationVariables>,
) {
    return Apollo.useMutation<DeleteInternalWarehouseMutation, DeleteInternalWarehouseMutationVariables>(
        DeleteInternalWarehouseDocument,
        baseOptions,
    );
}
export type DeleteInternalWarehouseMutationHookResult = ReturnType<typeof useDeleteInternalWarehouseMutation>;
export type DeleteInternalWarehouseMutationResult = Apollo.MutationResult<DeleteInternalWarehouseMutation>;
export type DeleteInternalWarehouseMutationOptions = Apollo.BaseMutationOptions<
    DeleteInternalWarehouseMutation,
    DeleteInternalWarehouseMutationVariables
>;
export const DeleteMessageDocument = gql`
    mutation DeleteMessage($deleteMessageId: String!) {
        deleteMessage(id: $deleteMessageId) {
            id
        }
    }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      deleteMessageId: // value for 'deleteMessageId'
 *   },
 * });
 */
export function useDeleteMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>,
) {
    return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(
        DeleteMessageDocument,
        baseOptions,
    );
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
>;
export const DeletePackagesByAdminDocument = gql`
    mutation deletePackagesByAdmin($input: DeletePackagesInput!) {
        deletePackagesByAdmin(input: $input)
    }
`;
export type DeletePackagesByAdminMutationFn = Apollo.MutationFunction<
    DeletePackagesByAdminMutation,
    DeletePackagesByAdminMutationVariables
>;

/**
 * __useDeletePackagesByAdminMutation__
 *
 * To run a mutation, you first call `useDeletePackagesByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackagesByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackagesByAdminMutation, { data, loading, error }] = useDeletePackagesByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePackagesByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePackagesByAdminMutation, DeletePackagesByAdminMutationVariables>,
) {
    return Apollo.useMutation<DeletePackagesByAdminMutation, DeletePackagesByAdminMutationVariables>(
        DeletePackagesByAdminDocument,
        baseOptions,
    );
}
export type DeletePackagesByAdminMutationHookResult = ReturnType<typeof useDeletePackagesByAdminMutation>;
export type DeletePackagesByAdminMutationResult = Apollo.MutationResult<DeletePackagesByAdminMutation>;
export type DeletePackagesByAdminMutationOptions = Apollo.BaseMutationOptions<
    DeletePackagesByAdminMutation,
    DeletePackagesByAdminMutationVariables
>;
export const DeletePurchaseOrdersByAdminDocument = gql`
    mutation deletePurchaseOrdersByAdmin($input: DeletePurchaseOrdersInput!) {
        deletePurchaseOrdersByAdmin(input: $input)
    }
`;
export type DeletePurchaseOrdersByAdminMutationFn = Apollo.MutationFunction<
    DeletePurchaseOrdersByAdminMutation,
    DeletePurchaseOrdersByAdminMutationVariables
>;

/**
 * __useDeletePurchaseOrdersByAdminMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseOrdersByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseOrdersByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseOrdersByAdminMutation, { data, loading, error }] = useDeletePurchaseOrdersByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePurchaseOrdersByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePurchaseOrdersByAdminMutation,
        DeletePurchaseOrdersByAdminMutationVariables
    >,
) {
    return Apollo.useMutation<DeletePurchaseOrdersByAdminMutation, DeletePurchaseOrdersByAdminMutationVariables>(
        DeletePurchaseOrdersByAdminDocument,
        baseOptions,
    );
}
export type DeletePurchaseOrdersByAdminMutationHookResult = ReturnType<typeof useDeletePurchaseOrdersByAdminMutation>;
export type DeletePurchaseOrdersByAdminMutationResult = Apollo.MutationResult<DeletePurchaseOrdersByAdminMutation>;
export type DeletePurchaseOrdersByAdminMutationOptions = Apollo.BaseMutationOptions<
    DeletePurchaseOrdersByAdminMutation,
    DeletePurchaseOrdersByAdminMutationVariables
>;
export const DeleteShelfDocument = gql`
    mutation DeleteShelf($deleteShelfId: String!) {
        deleteShelf(id: $deleteShelfId) {
            id
        }
    }
`;
export type DeleteShelfMutationFn = Apollo.MutationFunction<DeleteShelfMutation, DeleteShelfMutationVariables>;

/**
 * __useDeleteShelfMutation__
 *
 * To run a mutation, you first call `useDeleteShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShelfMutation, { data, loading, error }] = useDeleteShelfMutation({
 *   variables: {
 *      deleteShelfId: // value for 'deleteShelfId'
 *   },
 * });
 */
export function useDeleteShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteShelfMutation, DeleteShelfMutationVariables>,
) {
    return Apollo.useMutation<DeleteShelfMutation, DeleteShelfMutationVariables>(DeleteShelfDocument, baseOptions);
}
export type DeleteShelfMutationHookResult = ReturnType<typeof useDeleteShelfMutation>;
export type DeleteShelfMutationResult = Apollo.MutationResult<DeleteShelfMutation>;
export type DeleteShelfMutationOptions = Apollo.BaseMutationOptions<DeleteShelfMutation, DeleteShelfMutationVariables>;
export const DeleteStoreDocument = gql`
    mutation DeleteStore($deleteStoreId: String!) {
        deleteStore(id: $deleteStoreId) {
            id
        }
    }
`;
export type DeleteStoreMutationFn = Apollo.MutationFunction<DeleteStoreMutation, DeleteStoreMutationVariables>;

/**
 * __useDeleteStoreMutation__
 *
 * To run a mutation, you first call `useDeleteStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreMutation, { data, loading, error }] = useDeleteStoreMutation({
 *   variables: {
 *      deleteStoreId: // value for 'deleteStoreId'
 *   },
 * });
 */
export function useDeleteStoreMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteStoreMutation, DeleteStoreMutationVariables>,
) {
    return Apollo.useMutation<DeleteStoreMutation, DeleteStoreMutationVariables>(DeleteStoreDocument, baseOptions);
}
export type DeleteStoreMutationHookResult = ReturnType<typeof useDeleteStoreMutation>;
export type DeleteStoreMutationResult = Apollo.MutationResult<DeleteStoreMutation>;
export type DeleteStoreMutationOptions = Apollo.BaseMutationOptions<DeleteStoreMutation, DeleteStoreMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($deleteRoleId: String!) {
        deleteRole(id: $deleteRoleId) {
            id
        }
    }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      deleteRoleId: // value for 'deleteRoleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>,
) {
    return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const DeleteWeightDocument = gql`
    mutation DeleteWeight($deleteWeightId: String!) {
        deleteWeight(id: $deleteWeightId) {
            id
        }
    }
`;
export type DeleteWeightMutationFn = Apollo.MutationFunction<DeleteWeightMutation, DeleteWeightMutationVariables>;

/**
 * __useDeleteWeightMutation__
 *
 * To run a mutation, you first call `useDeleteWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWeightMutation, { data, loading, error }] = useDeleteWeightMutation({
 *   variables: {
 *      deleteWeightId: // value for 'deleteWeightId'
 *   },
 * });
 */
export function useDeleteWeightMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteWeightMutation, DeleteWeightMutationVariables>,
) {
    return Apollo.useMutation<DeleteWeightMutation, DeleteWeightMutationVariables>(DeleteWeightDocument, baseOptions);
}
export type DeleteWeightMutationHookResult = ReturnType<typeof useDeleteWeightMutation>;
export type DeleteWeightMutationResult = Apollo.MutationResult<DeleteWeightMutation>;
export type DeleteWeightMutationOptions = Apollo.BaseMutationOptions<
    DeleteWeightMutation,
    DeleteWeightMutationVariables
>;
export const DepeshDocument = gql`
    mutation depesh($input: DepeshInput!) {
        depesh(input: $input)
    }
`;
export type DepeshMutationFn = Apollo.MutationFunction<DepeshMutation, DepeshMutationVariables>;

/**
 * __useDepeshMutation__
 *
 * To run a mutation, you first call `useDepeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depeshMutation, { data, loading, error }] = useDepeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepeshMutation(baseOptions?: Apollo.MutationHookOptions<DepeshMutation, DepeshMutationVariables>) {
    return Apollo.useMutation<DepeshMutation, DepeshMutationVariables>(DepeshDocument, baseOptions);
}
export type DepeshMutationHookResult = ReturnType<typeof useDepeshMutation>;
export type DepeshMutationResult = Apollo.MutationResult<DepeshMutation>;
export type DepeshMutationOptions = Apollo.BaseMutationOptions<DepeshMutation, DepeshMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($category: UpdateCategoryInput!) {
        updateCategory(category: $category) {
            category {
                id
            }
        }
    }
`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>,
) {
    return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(
        UpdateCategoryDocument,
        baseOptions,
    );
}
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables
>;
export const UpdateStoreDocument = gql`
    mutation UpdateStore($store: UpdateStoreInput!) {
        updateStore(store: $store) {
            store {
                id
            }
        }
    }
`;
export type UpdateStoreMutationFn = Apollo.MutationFunction<UpdateStoreMutation, UpdateStoreMutationVariables>;

/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      store: // value for 'store'
 *   },
 * });
 */
export function useUpdateStoreMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>,
) {
    return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, baseOptions);
}
export type UpdateStoreMutationHookResult = ReturnType<typeof useUpdateStoreMutation>;
export type UpdateStoreMutationResult = Apollo.MutationResult<UpdateStoreMutation>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<UpdateStoreMutation, UpdateStoreMutationVariables>;
export const ExportManifestDocument = gql`
    mutation ExportManifest($input: ExportManifestInput!) {
        exportManifest(input: $input)
    }
`;
export type ExportManifestMutationFn = Apollo.MutationFunction<ExportManifestMutation, ExportManifestMutationVariables>;

/**
 * __useExportManifestMutation__
 *
 * To run a mutation, you first call `useExportManifestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportManifestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportManifestMutation, { data, loading, error }] = useExportManifestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportManifestMutation(
    baseOptions?: Apollo.MutationHookOptions<ExportManifestMutation, ExportManifestMutationVariables>,
) {
    return Apollo.useMutation<ExportManifestMutation, ExportManifestMutationVariables>(
        ExportManifestDocument,
        baseOptions,
    );
}
export type ExportManifestMutationHookResult = ReturnType<typeof useExportManifestMutation>;
export type ExportManifestMutationResult = Apollo.MutationResult<ExportManifestMutation>;
export type ExportManifestMutationOptions = Apollo.BaseMutationOptions<
    ExportManifestMutation,
    ExportManifestMutationVariables
>;
export const IncreaseBalanceInCashDocument = gql`
    mutation IncreaseBalanceInCash($input: IncreaseBalanceInCashInput!) {
        increaseBalanceInCash(input: $input)
    }
`;
export type IncreaseBalanceInCashMutationFn = Apollo.MutationFunction<
    IncreaseBalanceInCashMutation,
    IncreaseBalanceInCashMutationVariables
>;

/**
 * __useIncreaseBalanceInCashMutation__
 *
 * To run a mutation, you first call `useIncreaseBalanceInCashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncreaseBalanceInCashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [increaseBalanceInCashMutation, { data, loading, error }] = useIncreaseBalanceInCashMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncreaseBalanceInCashMutation(
    baseOptions?: Apollo.MutationHookOptions<IncreaseBalanceInCashMutation, IncreaseBalanceInCashMutationVariables>,
) {
    return Apollo.useMutation<IncreaseBalanceInCashMutation, IncreaseBalanceInCashMutationVariables>(
        IncreaseBalanceInCashDocument,
        baseOptions,
    );
}
export type IncreaseBalanceInCashMutationHookResult = ReturnType<typeof useIncreaseBalanceInCashMutation>;
export type IncreaseBalanceInCashMutationResult = Apollo.MutationResult<IncreaseBalanceInCashMutation>;
export type IncreaseBalanceInCashMutationOptions = Apollo.BaseMutationOptions<
    IncreaseBalanceInCashMutation,
    IncreaseBalanceInCashMutationVariables
>;
export const MarkAsReadDocument = gql`
    mutation MarkAsRead($input: MarkAsReadInput!) {
        markAsRead(input: $input) {
            success
        }
    }
`;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAsReadMutation(
    baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>,
) {
    return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, baseOptions);
}
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const MultiplePayDocument = gql`
    mutation MultiplePay($input: PayByAdminInput!) {
        payByAdmin(input: $input)
    }
`;
export type MultiplePayMutationFn = Apollo.MutationFunction<MultiplePayMutation, MultiplePayMutationVariables>;

/**
 * __useMultiplePayMutation__
 *
 * To run a mutation, you first call `useMultiplePayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiplePayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiplePayMutation, { data, loading, error }] = useMultiplePayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultiplePayMutation(
    baseOptions?: Apollo.MutationHookOptions<MultiplePayMutation, MultiplePayMutationVariables>,
) {
    return Apollo.useMutation<MultiplePayMutation, MultiplePayMutationVariables>(MultiplePayDocument, baseOptions);
}
export type MultiplePayMutationHookResult = ReturnType<typeof useMultiplePayMutation>;
export type MultiplePayMutationResult = Apollo.MutationResult<MultiplePayMutation>;
export type MultiplePayMutationOptions = Apollo.BaseMutationOptions<MultiplePayMutation, MultiplePayMutationVariables>;
export const RefreshExchangeRatesDocument = gql`
    mutation RefreshExchangeRates {
        refreshExchangeRate
    }
`;
export type RefreshExchangeRatesMutationFn = Apollo.MutationFunction<
    RefreshExchangeRatesMutation,
    RefreshExchangeRatesMutationVariables
>;

/**
 * __useRefreshExchangeRatesMutation__
 *
 * To run a mutation, you first call `useRefreshExchangeRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExchangeRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExchangeRatesMutation, { data, loading, error }] = useRefreshExchangeRatesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshExchangeRatesMutation(
    baseOptions?: Apollo.MutationHookOptions<RefreshExchangeRatesMutation, RefreshExchangeRatesMutationVariables>,
) {
    return Apollo.useMutation<RefreshExchangeRatesMutation, RefreshExchangeRatesMutationVariables>(
        RefreshExchangeRatesDocument,
        baseOptions,
    );
}
export type RefreshExchangeRatesMutationHookResult = ReturnType<typeof useRefreshExchangeRatesMutation>;
export type RefreshExchangeRatesMutationResult = Apollo.MutationResult<RefreshExchangeRatesMutation>;
export type RefreshExchangeRatesMutationOptions = Apollo.BaseMutationOptions<
    RefreshExchangeRatesMutation,
    RefreshExchangeRatesMutationVariables
>;
export const RegisterManagerDocument = gql`
    mutation registerManager($manager: RegisterManagerInput!) {
        registerManager(manager: $manager)
    }
`;
export type RegisterManagerMutationFn = Apollo.MutationFunction<
    RegisterManagerMutation,
    RegisterManagerMutationVariables
>;

/**
 * __useRegisterManagerMutation__
 *
 * To run a mutation, you first call `useRegisterManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerManagerMutation, { data, loading, error }] = useRegisterManagerMutation({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useRegisterManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterManagerMutation, RegisterManagerMutationVariables>,
) {
    return Apollo.useMutation<RegisterManagerMutation, RegisterManagerMutationVariables>(
        RegisterManagerDocument,
        baseOptions,
    );
}
export type RegisterManagerMutationHookResult = ReturnType<typeof useRegisterManagerMutation>;
export type RegisterManagerMutationResult = Apollo.MutationResult<RegisterManagerMutation>;
export type RegisterManagerMutationOptions = Apollo.BaseMutationOptions<
    RegisterManagerMutation,
    RegisterManagerMutationVariables
>;
export const RemoveDeclarationDocument = gql`
    mutation RemoveDeclaration($input: RemoveDeclarationInput!) {
        removeDeclaration(input: $input)
    }
`;
export type RemoveDeclarationMutationFn = Apollo.MutationFunction<
    RemoveDeclarationMutation,
    RemoveDeclarationMutationVariables
>;

/**
 * __useRemoveDeclarationMutation__
 *
 * To run a mutation, you first call `useRemoveDeclarationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeclarationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeclarationMutation, { data, loading, error }] = useRemoveDeclarationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDeclarationMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveDeclarationMutation, RemoveDeclarationMutationVariables>,
) {
    return Apollo.useMutation<RemoveDeclarationMutation, RemoveDeclarationMutationVariables>(
        RemoveDeclarationDocument,
        baseOptions,
    );
}
export type RemoveDeclarationMutationHookResult = ReturnType<typeof useRemoveDeclarationMutation>;
export type RemoveDeclarationMutationResult = Apollo.MutationResult<RemoveDeclarationMutation>;
export type RemoveDeclarationMutationOptions = Apollo.BaseMutationOptions<
    RemoveDeclarationMutation,
    RemoveDeclarationMutationVariables
>;
export const RemoveManagerDocument = gql`
    mutation RemoveManager($input: DeleteManagersInput!) {
        deleteManagers(input: $input)
    }
`;
export type RemoveManagerMutationFn = Apollo.MutationFunction<RemoveManagerMutation, RemoveManagerMutationVariables>;

/**
 * __useRemoveManagerMutation__
 *
 * To run a mutation, you first call `useRemoveManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerMutation, { data, loading, error }] = useRemoveManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveManagerMutation, RemoveManagerMutationVariables>,
) {
    return Apollo.useMutation<RemoveManagerMutation, RemoveManagerMutationVariables>(
        RemoveManagerDocument,
        baseOptions,
    );
}
export type RemoveManagerMutationHookResult = ReturnType<typeof useRemoveManagerMutation>;
export type RemoveManagerMutationResult = Apollo.MutationResult<RemoveManagerMutation>;
export type RemoveManagerMutationOptions = Apollo.BaseMutationOptions<
    RemoveManagerMutation,
    RemoveManagerMutationVariables
>;
export const SendToSmartCustomDocument = gql`
    mutation SendToSmartCustom($input: SendToSmartCustomsInput!) {
        sendToSmartCustom(input: $input)
    }
`;
export type SendToSmartCustomMutationFn = Apollo.MutationFunction<
    SendToSmartCustomMutation,
    SendToSmartCustomMutationVariables
>;

/**
 * __useSendToSmartCustomMutation__
 *
 * To run a mutation, you first call `useSendToSmartCustomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToSmartCustomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToSmartCustomMutation, { data, loading, error }] = useSendToSmartCustomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendToSmartCustomMutation(
    baseOptions?: Apollo.MutationHookOptions<SendToSmartCustomMutation, SendToSmartCustomMutationVariables>,
) {
    return Apollo.useMutation<SendToSmartCustomMutation, SendToSmartCustomMutationVariables>(
        SendToSmartCustomDocument,
        baseOptions,
    );
}
export type SendToSmartCustomMutationHookResult = ReturnType<typeof useSendToSmartCustomMutation>;
export type SendToSmartCustomMutationResult = Apollo.MutationResult<SendToSmartCustomMutation>;
export type SendToSmartCustomMutationOptions = Apollo.BaseMutationOptions<
    SendToSmartCustomMutation,
    SendToSmartCustomMutationVariables
>;
export const UpdateMultipleCronJobActivationsDocument = gql`
    mutation UpdateMultipleCronJobActivations($input: UpdateMultipleCronJobActivationsInput!) {
        updateMultipleCronJobActivations(input: $input) {
            success
        }
    }
`;
export type UpdateMultipleCronJobActivationsMutationFn = Apollo.MutationFunction<
    UpdateMultipleCronJobActivationsMutation,
    UpdateMultipleCronJobActivationsMutationVariables
>;

/**
 * __useUpdateMultipleCronJobActivationsMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleCronJobActivationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleCronJobActivationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleCronJobActivationsMutation, { data, loading, error }] = useUpdateMultipleCronJobActivationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMultipleCronJobActivationsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMultipleCronJobActivationsMutation,
        UpdateMultipleCronJobActivationsMutationVariables
    >,
) {
    return Apollo.useMutation<
        UpdateMultipleCronJobActivationsMutation,
        UpdateMultipleCronJobActivationsMutationVariables
    >(UpdateMultipleCronJobActivationsDocument, baseOptions);
}
export type UpdateMultipleCronJobActivationsMutationHookResult = ReturnType<
    typeof useUpdateMultipleCronJobActivationsMutation
>;
export type UpdateMultipleCronJobActivationsMutationResult = Apollo.MutationResult<
    UpdateMultipleCronJobActivationsMutation
>;
export type UpdateMultipleCronJobActivationsMutationOptions = Apollo.BaseMutationOptions<
    UpdateMultipleCronJobActivationsMutation,
    UpdateMultipleCronJobActivationsMutationVariables
>;
export const UnAssignPackageFromContainerDocument = gql`
    mutation UnAssignPackageFromContainer($input: UnAssignPackageFromContainerInput!) {
        unAssignPackageFromContainer(input: $input) {
            containerId
            packageId
        }
    }
`;
export type UnAssignPackageFromContainerMutationFn = Apollo.MutationFunction<
    UnAssignPackageFromContainerMutation,
    UnAssignPackageFromContainerMutationVariables
>;

/**
 * __useUnAssignPackageFromContainerMutation__
 *
 * To run a mutation, you first call `useUnAssignPackageFromContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAssignPackageFromContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAssignPackageFromContainerMutation, { data, loading, error }] = useUnAssignPackageFromContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnAssignPackageFromContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UnAssignPackageFromContainerMutation,
        UnAssignPackageFromContainerMutationVariables
    >,
) {
    return Apollo.useMutation<UnAssignPackageFromContainerMutation, UnAssignPackageFromContainerMutationVariables>(
        UnAssignPackageFromContainerDocument,
        baseOptions,
    );
}
export type UnAssignPackageFromContainerMutationHookResult = ReturnType<typeof useUnAssignPackageFromContainerMutation>;
export type UnAssignPackageFromContainerMutationResult = Apollo.MutationResult<UnAssignPackageFromContainerMutation>;
export type UnAssignPackageFromContainerMutationOptions = Apollo.BaseMutationOptions<
    UnAssignPackageFromContainerMutation,
    UnAssignPackageFromContainerMutationVariables
>;
export const UpdateBlogPostDocument = gql`
    mutation UpdateBlogPost($blogPost: UpdateBlogPostInput!) {
        updateBlogPost(blogPost: $blogPost) {
            blogPost {
                id
            }
        }
    }
`;
export type UpdateBlogPostMutationFn = Apollo.MutationFunction<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;

/**
 * __useUpdateBlogPostMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostMutation, { data, loading, error }] = useUpdateBlogPostMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useUpdateBlogPostMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>,
) {
    return Apollo.useMutation<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>(
        UpdateBlogPostDocument,
        baseOptions,
    );
}
export type UpdateBlogPostMutationHookResult = ReturnType<typeof useUpdateBlogPostMutation>;
export type UpdateBlogPostMutationResult = Apollo.MutationResult<UpdateBlogPostMutation>;
export type UpdateBlogPostMutationOptions = Apollo.BaseMutationOptions<
    UpdateBlogPostMutation,
    UpdateBlogPostMutationVariables
>;
export const UpdateBranchDocument = gql`
    mutation updateBranch($branch: UpdateBranchInput!) {
        updateBranch(branch: $branch) {
            branch {
                id
            }
        }
    }
`;
export type UpdateBranchMutationFn = Apollo.MutationFunction<UpdateBranchMutation, UpdateBranchMutationVariables>;

/**
 * __useUpdateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchMutation, { data, loading, error }] = useUpdateBranchMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *   },
 * });
 */
export function useUpdateBranchMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateBranchMutation, UpdateBranchMutationVariables>,
) {
    return Apollo.useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(UpdateBranchDocument, baseOptions);
}
export type UpdateBranchMutationHookResult = ReturnType<typeof useUpdateBranchMutation>;
export type UpdateBranchMutationResult = Apollo.MutationResult<UpdateBranchMutation>;
export type UpdateBranchMutationOptions = Apollo.BaseMutationOptions<
    UpdateBranchMutation,
    UpdateBranchMutationVariables
>;
export const UpdateBulkPackageDeliveryDateDocument = gql`
    mutation updateBulkPackageDeliveryDate($input: UpdateMultiplePackageDeliveryDateInput!) {
        updateBulkPackageDeliveryDate(input: $input)
    }
`;
export type UpdateBulkPackageDeliveryDateMutationFn = Apollo.MutationFunction<
    UpdateBulkPackageDeliveryDateMutation,
    UpdateBulkPackageDeliveryDateMutationVariables
>;

/**
 * __useUpdateBulkPackageDeliveryDateMutation__
 *
 * To run a mutation, you first call `useUpdateBulkPackageDeliveryDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkPackageDeliveryDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkPackageDeliveryDateMutation, { data, loading, error }] = useUpdateBulkPackageDeliveryDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBulkPackageDeliveryDateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBulkPackageDeliveryDateMutation,
        UpdateBulkPackageDeliveryDateMutationVariables
    >,
) {
    return Apollo.useMutation<UpdateBulkPackageDeliveryDateMutation, UpdateBulkPackageDeliveryDateMutationVariables>(
        UpdateBulkPackageDeliveryDateDocument,
        baseOptions,
    );
}
export type UpdateBulkPackageDeliveryDateMutationHookResult = ReturnType<
    typeof useUpdateBulkPackageDeliveryDateMutation
>;
export type UpdateBulkPackageDeliveryDateMutationResult = Apollo.MutationResult<UpdateBulkPackageDeliveryDateMutation>;
export type UpdateBulkPackageDeliveryDateMutationOptions = Apollo.BaseMutationOptions<
    UpdateBulkPackageDeliveryDateMutation,
    UpdateBulkPackageDeliveryDateMutationVariables
>;
export const UpdateBulkPackageGeneralStatusDocument = gql`
    mutation updateBulkPackageGeneralStatus($input: UpdateMultiplePackageGeneralStatusInput!) {
        updateBulkPackageGeneralStatus(input: $input)
    }
`;
export type UpdateBulkPackageGeneralStatusMutationFn = Apollo.MutationFunction<
    UpdateBulkPackageGeneralStatusMutation,
    UpdateBulkPackageGeneralStatusMutationVariables
>;

/**
 * __useUpdateBulkPackageGeneralStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBulkPackageGeneralStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkPackageGeneralStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkPackageGeneralStatusMutation, { data, loading, error }] = useUpdateBulkPackageGeneralStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBulkPackageGeneralStatusMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBulkPackageGeneralStatusMutation,
        UpdateBulkPackageGeneralStatusMutationVariables
    >,
) {
    return Apollo.useMutation<UpdateBulkPackageGeneralStatusMutation, UpdateBulkPackageGeneralStatusMutationVariables>(
        UpdateBulkPackageGeneralStatusDocument,
        baseOptions,
    );
}
export type UpdateBulkPackageGeneralStatusMutationHookResult = ReturnType<
    typeof useUpdateBulkPackageGeneralStatusMutation
>;
export type UpdateBulkPackageGeneralStatusMutationResult = Apollo.MutationResult<
    UpdateBulkPackageGeneralStatusMutation
>;
export type UpdateBulkPackageGeneralStatusMutationOptions = Apollo.BaseMutationOptions<
    UpdateBulkPackageGeneralStatusMutation,
    UpdateBulkPackageGeneralStatusMutationVariables
>;
export const UpdateBulkPackageInternalShelfDocument = gql`
    mutation UpdateBulkPackageInternalShelf($input: UpdateBulkPackageInternalShelfInput!) {
        updateBulkPackageInternalShelf(input: $input)
    }
`;
export type UpdateBulkPackageInternalShelfMutationFn = Apollo.MutationFunction<
    UpdateBulkPackageInternalShelfMutation,
    UpdateBulkPackageInternalShelfMutationVariables
>;

/**
 * __useUpdateBulkPackageInternalShelfMutation__
 *
 * To run a mutation, you first call `useUpdateBulkPackageInternalShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkPackageInternalShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkPackageInternalShelfMutation, { data, loading, error }] = useUpdateBulkPackageInternalShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBulkPackageInternalShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBulkPackageInternalShelfMutation,
        UpdateBulkPackageInternalShelfMutationVariables
    >,
) {
    return Apollo.useMutation<UpdateBulkPackageInternalShelfMutation, UpdateBulkPackageInternalShelfMutationVariables>(
        UpdateBulkPackageInternalShelfDocument,
        baseOptions,
    );
}
export type UpdateBulkPackageInternalShelfMutationHookResult = ReturnType<
    typeof useUpdateBulkPackageInternalShelfMutation
>;
export type UpdateBulkPackageInternalShelfMutationResult = Apollo.MutationResult<
    UpdateBulkPackageInternalShelfMutation
>;
export type UpdateBulkPackageInternalShelfMutationOptions = Apollo.BaseMutationOptions<
    UpdateBulkPackageInternalShelfMutation,
    UpdateBulkPackageInternalShelfMutationVariables
>;
export const UpdateBulkPackageShelfDocument = gql`
    mutation UpdateBulkPackageShelf($input: UpdateBulkPackageShelfInput!) {
        updateBulkPackageShelf(input: $input)
    }
`;
export type UpdateBulkPackageShelfMutationFn = Apollo.MutationFunction<
    UpdateBulkPackageShelfMutation,
    UpdateBulkPackageShelfMutationVariables
>;

/**
 * __useUpdateBulkPackageShelfMutation__
 *
 * To run a mutation, you first call `useUpdateBulkPackageShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkPackageShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkPackageShelfMutation, { data, loading, error }] = useUpdateBulkPackageShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBulkPackageShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateBulkPackageShelfMutation, UpdateBulkPackageShelfMutationVariables>,
) {
    return Apollo.useMutation<UpdateBulkPackageShelfMutation, UpdateBulkPackageShelfMutationVariables>(
        UpdateBulkPackageShelfDocument,
        baseOptions,
    );
}
export type UpdateBulkPackageShelfMutationHookResult = ReturnType<typeof useUpdateBulkPackageShelfMutation>;
export type UpdateBulkPackageShelfMutationResult = Apollo.MutationResult<UpdateBulkPackageShelfMutation>;
export type UpdateBulkPackageShelfMutationOptions = Apollo.BaseMutationOptions<
    UpdateBulkPackageShelfMutation,
    UpdateBulkPackageShelfMutationVariables
>;
export const UpdateCustomerByAdminDocument = gql`
    mutation UpdateCustomerByAdmin($customer: UpdateCustomerByAdminInput!) {
        updateCustomerByAdmin(customer: $customer) {
            customer {
                id
            }
        }
    }
`;
export type UpdateCustomerByAdminMutationFn = Apollo.MutationFunction<
    UpdateCustomerByAdminMutation,
    UpdateCustomerByAdminMutationVariables
>;

/**
 * __useUpdateCustomerByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerByAdminMutation, { data, loading, error }] = useUpdateCustomerByAdminMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCustomerByAdminMutation, UpdateCustomerByAdminMutationVariables>,
) {
    return Apollo.useMutation<UpdateCustomerByAdminMutation, UpdateCustomerByAdminMutationVariables>(
        UpdateCustomerByAdminDocument,
        baseOptions,
    );
}
export type UpdateCustomerByAdminMutationHookResult = ReturnType<typeof useUpdateCustomerByAdminMutation>;
export type UpdateCustomerByAdminMutationResult = Apollo.MutationResult<UpdateCustomerByAdminMutation>;
export type UpdateCustomerByAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdateCustomerByAdminMutation,
    UpdateCustomerByAdminMutationVariables
>;
export const UpdateExchangeRateDocument = gql`
    mutation UpdateExchangeRate($input: UpdateExchangeRateInput!) {
        updateExchangeRate(input: $input) {
            success
        }
    }
`;
export type UpdateExchangeRateMutationFn = Apollo.MutationFunction<
    UpdateExchangeRateMutation,
    UpdateExchangeRateMutationVariables
>;

/**
 * __useUpdateExchangeRateMutation__
 *
 * To run a mutation, you first call `useUpdateExchangeRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExchangeRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExchangeRateMutation, { data, loading, error }] = useUpdateExchangeRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExchangeRateMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateExchangeRateMutation, UpdateExchangeRateMutationVariables>,
) {
    return Apollo.useMutation<UpdateExchangeRateMutation, UpdateExchangeRateMutationVariables>(
        UpdateExchangeRateDocument,
        baseOptions,
    );
}
export type UpdateExchangeRateMutationHookResult = ReturnType<typeof useUpdateExchangeRateMutation>;
export type UpdateExchangeRateMutationResult = Apollo.MutationResult<UpdateExchangeRateMutation>;
export type UpdateExchangeRateMutationOptions = Apollo.BaseMutationOptions<
    UpdateExchangeRateMutation,
    UpdateExchangeRateMutationVariables
>;
export const UpdateExternalWarehouseDocument = gql`
    mutation UpdateExternalWarehouse($input: UpdateExternalWarehouseInput!) {
        updateExternalWarehouse(input: $input) {
            externalWarehouse {
                id
                name
            }
        }
    }
`;
export type UpdateExternalWarehouseMutationFn = Apollo.MutationFunction<
    UpdateExternalWarehouseMutation,
    UpdateExternalWarehouseMutationVariables
>;

/**
 * __useUpdateExternalWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateExternalWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalWarehouseMutation, { data, loading, error }] = useUpdateExternalWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExternalWarehouseMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateExternalWarehouseMutation, UpdateExternalWarehouseMutationVariables>,
) {
    return Apollo.useMutation<UpdateExternalWarehouseMutation, UpdateExternalWarehouseMutationVariables>(
        UpdateExternalWarehouseDocument,
        baseOptions,
    );
}
export type UpdateExternalWarehouseMutationHookResult = ReturnType<typeof useUpdateExternalWarehouseMutation>;
export type UpdateExternalWarehouseMutationResult = Apollo.MutationResult<UpdateExternalWarehouseMutation>;
export type UpdateExternalWarehouseMutationOptions = Apollo.BaseMutationOptions<
    UpdateExternalWarehouseMutation,
    UpdateExternalWarehouseMutationVariables
>;
export const UpdateFlightDocument = gql`
    mutation UpdateFlight($input: UpdateFlightInput!) {
        updateFlight(input: $input) {
            flight {
                name
                id
            }
        }
    }
`;
export type UpdateFlightMutationFn = Apollo.MutationFunction<UpdateFlightMutation, UpdateFlightMutationVariables>;

/**
 * __useUpdateFlightMutation__
 *
 * To run a mutation, you first call `useUpdateFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlightMutation, { data, loading, error }] = useUpdateFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFlightMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateFlightMutation, UpdateFlightMutationVariables>,
) {
    return Apollo.useMutation<UpdateFlightMutation, UpdateFlightMutationVariables>(UpdateFlightDocument, baseOptions);
}
export type UpdateFlightMutationHookResult = ReturnType<typeof useUpdateFlightMutation>;
export type UpdateFlightMutationResult = Apollo.MutationResult<UpdateFlightMutation>;
export type UpdateFlightMutationOptions = Apollo.BaseMutationOptions<
    UpdateFlightMutation,
    UpdateFlightMutationVariables
>;
export const UpdateInternalShelfDocument = gql`
    mutation UpdateInternalShelf($shelf: UpdateInternalShelfInput!) {
        updateInternalShelf(shelf: $shelf) {
            shelf {
                id
                name
            }
        }
    }
`;
export type UpdateInternalShelfMutationFn = Apollo.MutationFunction<
    UpdateInternalShelfMutation,
    UpdateInternalShelfMutationVariables
>;

/**
 * __useUpdateInternalShelfMutation__
 *
 * To run a mutation, you first call `useUpdateInternalShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalShelfMutation, { data, loading, error }] = useUpdateInternalShelfMutation({
 *   variables: {
 *      shelf: // value for 'shelf'
 *   },
 * });
 */
export function useUpdateInternalShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateInternalShelfMutation, UpdateInternalShelfMutationVariables>,
) {
    return Apollo.useMutation<UpdateInternalShelfMutation, UpdateInternalShelfMutationVariables>(
        UpdateInternalShelfDocument,
        baseOptions,
    );
}
export type UpdateInternalShelfMutationHookResult = ReturnType<typeof useUpdateInternalShelfMutation>;
export type UpdateInternalShelfMutationResult = Apollo.MutationResult<UpdateInternalShelfMutation>;
export type UpdateInternalShelfMutationOptions = Apollo.BaseMutationOptions<
    UpdateInternalShelfMutation,
    UpdateInternalShelfMutationVariables
>;
export const UpdateInternalWarehouseDocument = gql`
    mutation UpdateInternalWarehouse($input: UpdateInternalWarehouseInput!) {
        updateInternalWarehouse(input: $input) {
            internalWarehouse {
                id
                name
            }
        }
    }
`;
export type UpdateInternalWarehouseMutationFn = Apollo.MutationFunction<
    UpdateInternalWarehouseMutation,
    UpdateInternalWarehouseMutationVariables
>;

/**
 * __useUpdateInternalWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateInternalWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalWarehouseMutation, { data, loading, error }] = useUpdateInternalWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInternalWarehouseMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateInternalWarehouseMutation, UpdateInternalWarehouseMutationVariables>,
) {
    return Apollo.useMutation<UpdateInternalWarehouseMutation, UpdateInternalWarehouseMutationVariables>(
        UpdateInternalWarehouseDocument,
        baseOptions,
    );
}
export type UpdateInternalWarehouseMutationHookResult = ReturnType<typeof useUpdateInternalWarehouseMutation>;
export type UpdateInternalWarehouseMutationResult = Apollo.MutationResult<UpdateInternalWarehouseMutation>;
export type UpdateInternalWarehouseMutationOptions = Apollo.BaseMutationOptions<
    UpdateInternalWarehouseMutation,
    UpdateInternalWarehouseMutationVariables
>;
export const UpdateManagerByAdminDocument = gql`
    mutation updateManagerByAdmin($manager: UpdateManagerByAdminInput!) {
        updateManagerByAdmin(manager: $manager) {
            manager {
                id
            }
        }
    }
`;
export type UpdateManagerByAdminMutationFn = Apollo.MutationFunction<
    UpdateManagerByAdminMutation,
    UpdateManagerByAdminMutationVariables
>;

/**
 * __useUpdateManagerByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateManagerByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerByAdminMutation, { data, loading, error }] = useUpdateManagerByAdminMutation({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useUpdateManagerByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateManagerByAdminMutation, UpdateManagerByAdminMutationVariables>,
) {
    return Apollo.useMutation<UpdateManagerByAdminMutation, UpdateManagerByAdminMutationVariables>(
        UpdateManagerByAdminDocument,
        baseOptions,
    );
}
export type UpdateManagerByAdminMutationHookResult = ReturnType<typeof useUpdateManagerByAdminMutation>;
export type UpdateManagerByAdminMutationResult = Apollo.MutationResult<UpdateManagerByAdminMutation>;
export type UpdateManagerByAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdateManagerByAdminMutation,
    UpdateManagerByAdminMutationVariables
>;
export const UpdatePurchaseOrderByAdminDocument = gql`
    mutation UpdatePurchaseOrderByAdmin($input: UpdatePurchaseOrderByAdminInput!) {
        updatePurchaseOrderByAdmin(input: $input) {
            purchaseOrder {
                id
            }
        }
    }
`;
export type UpdatePurchaseOrderByAdminMutationFn = Apollo.MutationFunction<
    UpdatePurchaseOrderByAdminMutation,
    UpdatePurchaseOrderByAdminMutationVariables
>;

/**
 * __useUpdatePurchaseOrderByAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderByAdminMutation, { data, loading, error }] = useUpdatePurchaseOrderByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchaseOrderByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseOrderByAdminMutation,
        UpdatePurchaseOrderByAdminMutationVariables
    >,
) {
    return Apollo.useMutation<UpdatePurchaseOrderByAdminMutation, UpdatePurchaseOrderByAdminMutationVariables>(
        UpdatePurchaseOrderByAdminDocument,
        baseOptions,
    );
}
export type UpdatePurchaseOrderByAdminMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderByAdminMutation>;
export type UpdatePurchaseOrderByAdminMutationResult = Apollo.MutationResult<UpdatePurchaseOrderByAdminMutation>;
export type UpdatePurchaseOrderByAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseOrderByAdminMutation,
    UpdatePurchaseOrderByAdminMutationVariables
>;
export const UpdatePurchaseOrderSettingDocument = gql`
    mutation UpdatePurchaseOrderSetting($input: UpdateConfigurationInput!) {
        updateConfiguration(input: $input) {
            configuration {
                id
            }
        }
    }
`;
export type UpdatePurchaseOrderSettingMutationFn = Apollo.MutationFunction<
    UpdatePurchaseOrderSettingMutation,
    UpdatePurchaseOrderSettingMutationVariables
>;

/**
 * __useUpdatePurchaseOrderSettingMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderSettingMutation, { data, loading, error }] = useUpdatePurchaseOrderSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchaseOrderSettingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseOrderSettingMutation,
        UpdatePurchaseOrderSettingMutationVariables
    >,
) {
    return Apollo.useMutation<UpdatePurchaseOrderSettingMutation, UpdatePurchaseOrderSettingMutationVariables>(
        UpdatePurchaseOrderSettingDocument,
        baseOptions,
    );
}
export type UpdatePurchaseOrderSettingMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderSettingMutation>;
export type UpdatePurchaseOrderSettingMutationResult = Apollo.MutationResult<UpdatePurchaseOrderSettingMutation>;
export type UpdatePurchaseOrderSettingMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseOrderSettingMutation,
    UpdatePurchaseOrderSettingMutationVariables
>;
export const UpdateShelfDocument = gql`
    mutation UpdateShelf($shelf: UpdateShelfInput!) {
        updateShelf(shelf: $shelf) {
            shelf {
                id
            }
        }
    }
`;
export type UpdateShelfMutationFn = Apollo.MutationFunction<UpdateShelfMutation, UpdateShelfMutationVariables>;

/**
 * __useUpdateShelfMutation__
 *
 * To run a mutation, you first call `useUpdateShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShelfMutation, { data, loading, error }] = useUpdateShelfMutation({
 *   variables: {
 *      shelf: // value for 'shelf'
 *   },
 * });
 */
export function useUpdateShelfMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateShelfMutation, UpdateShelfMutationVariables>,
) {
    return Apollo.useMutation<UpdateShelfMutation, UpdateShelfMutationVariables>(UpdateShelfDocument, baseOptions);
}
export type UpdateShelfMutationHookResult = ReturnType<typeof useUpdateShelfMutation>;
export type UpdateShelfMutationResult = Apollo.MutationResult<UpdateShelfMutation>;
export type UpdateShelfMutationOptions = Apollo.BaseMutationOptions<UpdateShelfMutation, UpdateShelfMutationVariables>;
export const UpdateTariffDocument = gql`
    mutation UpdateTariff($tariff: UpdateTariffInput!) {
        updateTariff(tariff: $tariff) {
            tariff {
                id
            }
        }
    }
`;
export type UpdateTariffMutationFn = Apollo.MutationFunction<UpdateTariffMutation, UpdateTariffMutationVariables>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      tariff: // value for 'tariff'
 *   },
 * });
 */
export function useUpdateTariffMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateTariffMutation, UpdateTariffMutationVariables>,
) {
    return Apollo.useMutation<UpdateTariffMutation, UpdateTariffMutationVariables>(UpdateTariffDocument, baseOptions);
}
export type UpdateTariffMutationHookResult = ReturnType<typeof useUpdateTariffMutation>;
export type UpdateTariffMutationResult = Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
>;
export const UpdatePackagebyAdminDocument = gql`
    mutation updatePackagebyAdmin($input: UpdatePackageByAdminInput!) {
        updatePackageByAdmin(input: $input) {
            package {
                id
            }
        }
    }
`;
export type UpdatePackagebyAdminMutationFn = Apollo.MutationFunction<
    UpdatePackagebyAdminMutation,
    UpdatePackagebyAdminMutationVariables
>;

/**
 * __useUpdatePackagebyAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePackagebyAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackagebyAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackagebyAdminMutation, { data, loading, error }] = useUpdatePackagebyAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackagebyAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePackagebyAdminMutation, UpdatePackagebyAdminMutationVariables>,
) {
    return Apollo.useMutation<UpdatePackagebyAdminMutation, UpdatePackagebyAdminMutationVariables>(
        UpdatePackagebyAdminDocument,
        baseOptions,
    );
}
export type UpdatePackagebyAdminMutationHookResult = ReturnType<typeof useUpdatePackagebyAdminMutation>;
export type UpdatePackagebyAdminMutationResult = Apollo.MutationResult<UpdatePackagebyAdminMutation>;
export type UpdatePackagebyAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdatePackagebyAdminMutation,
    UpdatePackagebyAdminMutationVariables
>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
        updateRole(input: $input) {
            role {
                id
            }
        }
    }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>,
) {
    return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
}
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateWeightDocument = gql`
    mutation UpdateWeight($weight: UpdateWeightInput!) {
        updateWeight(weight: $weight) {
            weight {
                name
                min
                max
                id
            }
        }
    }
`;
export type UpdateWeightMutationFn = Apollo.MutationFunction<UpdateWeightMutation, UpdateWeightMutationVariables>;

/**
 * __useUpdateWeightMutation__
 *
 * To run a mutation, you first call `useUpdateWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeightMutation, { data, loading, error }] = useUpdateWeightMutation({
 *   variables: {
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateWeightMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateWeightMutation, UpdateWeightMutationVariables>,
) {
    return Apollo.useMutation<UpdateWeightMutation, UpdateWeightMutationVariables>(UpdateWeightDocument, baseOptions);
}
export type UpdateWeightMutationHookResult = ReturnType<typeof useUpdateWeightMutation>;
export type UpdateWeightMutationResult = Apollo.MutationResult<UpdateWeightMutation>;
export type UpdateWeightMutationOptions = Apollo.BaseMutationOptions<
    UpdateWeightMutation,
    UpdateWeightMutationVariables
>;
export const UploadBlogPostCoverImageDocument = gql`
    mutation UploadBlogPostCoverImage($blogPost: UploadBlogPostCoverImageInput!) {
        uploadBlogPostCoverImage(blogPost: $blogPost) {
            blogPost {
                id
            }
        }
    }
`;
export type UploadBlogPostCoverImageMutationFn = Apollo.MutationFunction<
    UploadBlogPostCoverImageMutation,
    UploadBlogPostCoverImageMutationVariables
>;

/**
 * __useUploadBlogPostCoverImageMutation__
 *
 * To run a mutation, you first call `useUploadBlogPostCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBlogPostCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBlogPostCoverImageMutation, { data, loading, error }] = useUploadBlogPostCoverImageMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useUploadBlogPostCoverImageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadBlogPostCoverImageMutation,
        UploadBlogPostCoverImageMutationVariables
    >,
) {
    return Apollo.useMutation<UploadBlogPostCoverImageMutation, UploadBlogPostCoverImageMutationVariables>(
        UploadBlogPostCoverImageDocument,
        baseOptions,
    );
}
export type UploadBlogPostCoverImageMutationHookResult = ReturnType<typeof useUploadBlogPostCoverImageMutation>;
export type UploadBlogPostCoverImageMutationResult = Apollo.MutationResult<UploadBlogPostCoverImageMutation>;
export type UploadBlogPostCoverImageMutationOptions = Apollo.BaseMutationOptions<
    UploadBlogPostCoverImageMutation,
    UploadBlogPostCoverImageMutationVariables
>;
export const TransactionsDocument = gql`
    query Transactions($page: Int, $where: TransactionWhereInput) {
        transactions(page: $page, where: $where) {
            edges {
                node {
                    id
                    amount
                    type
                    createdAt
                    status
                    salesOrderLines {
                        edges {
                            node {
                                package {
                                    id
                                    falkonTrackingNumber
                                }
                                purchaseOrder {
                                    id
                                    purchaseOrderNumber
                                }
                                type
                            }
                        }
                    }
                }
            }
            pageInfo {
                page
                totalPages
            }
            totalCount
        }
    }
`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTransactionsQuery(
    baseOptions?: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>,
) {
    return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, baseOptions);
}
export function useTransactionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>,
) {
    return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, baseOptions);
}
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const BlogPostDocument = gql`
    query blogPost($blogPostId: String) {
        blogPost(id: $blogPostId) {
            title
            coverImage {
                url
                name
            }
            body
            tags
            createdAt
            relatedBlogPosts {
                edges {
                    node {
                        createdAt
                        title
                        coverImage {
                            url
                        }
                    }
                }
            }
            tags
        }
    }
`;

/**
 * __useBlogPostQuery__
 *
 * To run a query within a React component, call `useBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostQuery({
 *   variables: {
 *      blogPostId: // value for 'blogPostId'
 *   },
 * });
 */
export function useBlogPostQuery(baseOptions?: Apollo.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
    return Apollo.useQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, baseOptions);
}
export function useBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
    return Apollo.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, baseOptions);
}
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>;
export type BlogPostLazyQueryHookResult = ReturnType<typeof useBlogPostLazyQuery>;
export type BlogPostQueryResult = Apollo.QueryResult<BlogPostQuery, BlogPostQueryVariables>;
export const BlogPostDetailDocument = gql`
    query blogPostDetail($slug: String) {
        blogPost(slug: $slug) {
            id
            slug
            title
            coverImage {
                id
                url
            }
            body
            createdAt
            relatedBlogPosts {
                edges {
                    node {
                        id
                        slug
                        createdAt
                        title
                        coverImage {
                            id
                            url
                        }
                    }
                }
            }
            tags
        }
    }
`;

/**
 * __useBlogPostDetailQuery__
 *
 * To run a query within a React component, call `useBlogPostDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogPostDetailQuery(
    baseOptions?: Apollo.QueryHookOptions<BlogPostDetailQuery, BlogPostDetailQueryVariables>,
) {
    return Apollo.useQuery<BlogPostDetailQuery, BlogPostDetailQueryVariables>(BlogPostDetailDocument, baseOptions);
}
export function useBlogPostDetailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BlogPostDetailQuery, BlogPostDetailQueryVariables>,
) {
    return Apollo.useLazyQuery<BlogPostDetailQuery, BlogPostDetailQueryVariables>(BlogPostDetailDocument, baseOptions);
}
export type BlogPostDetailQueryHookResult = ReturnType<typeof useBlogPostDetailQuery>;
export type BlogPostDetailLazyQueryHookResult = ReturnType<typeof useBlogPostDetailLazyQuery>;
export type BlogPostDetailQueryResult = Apollo.QueryResult<BlogPostDetailQuery, BlogPostDetailQueryVariables>;
export const BlogPostsDocument = gql`
    query BlogPosts {
        blogPosts {
            edges {
                node {
                    id
                    slug
                    createdAt
                    title
                    coverImage {
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useBlogPostsQuery__
 *
 * To run a query within a React component, call `useBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<BlogPostsQuery, BlogPostsQueryVariables>) {
    return Apollo.useQuery<BlogPostsQuery, BlogPostsQueryVariables>(BlogPostsDocument, baseOptions);
}
export function useBlogPostsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BlogPostsQuery, BlogPostsQueryVariables>,
) {
    return Apollo.useLazyQuery<BlogPostsQuery, BlogPostsQueryVariables>(BlogPostsDocument, baseOptions);
}
export type BlogPostsQueryHookResult = ReturnType<typeof useBlogPostsQuery>;
export type BlogPostsLazyQueryHookResult = ReturnType<typeof useBlogPostsLazyQuery>;
export type BlogPostsQueryResult = Apollo.QueryResult<BlogPostsQuery, BlogPostsQueryVariables>;
export const BranchDocument = gql`
    query branch($branchId: String!) {
        branch(id: $branchId) {
            id
            address
            name
            price
        }
    }
`;

/**
 * __useBranchQuery__
 *
 * To run a query within a React component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useBranchQuery(baseOptions: Apollo.QueryHookOptions<BranchQuery, BranchQueryVariables>) {
    return Apollo.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, baseOptions);
}
export function useBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchQuery, BranchQueryVariables>) {
    return Apollo.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, baseOptions);
}
export type BranchQueryHookResult = ReturnType<typeof useBranchQuery>;
export type BranchLazyQueryHookResult = ReturnType<typeof useBranchLazyQuery>;
export type BranchQueryResult = Apollo.QueryResult<BranchQuery, BranchQueryVariables>;
export const BranchesDocument = gql`
    query Branches {
        branches {
            edges {
                node {
                    id
                    address
                    price
                    name
                }
            }
        }
    }
`;

/**
 * __useBranchesQuery__
 *
 * To run a query within a React component, call `useBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBranchesQuery(baseOptions?: Apollo.QueryHookOptions<BranchesQuery, BranchesQueryVariables>) {
    return Apollo.useQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, baseOptions);
}
export function useBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesQuery, BranchesQueryVariables>) {
    return Apollo.useLazyQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, baseOptions);
}
export type BranchesQueryHookResult = ReturnType<typeof useBranchesQuery>;
export type BranchesLazyQueryHookResult = ReturnType<typeof useBranchesLazyQuery>;
export type BranchesQueryResult = Apollo.QueryResult<BranchesQuery, BranchesQueryVariables>;
export const CategoriesDocument = gql`
    query Categories($where: CategoryWhereInput, $page: Int, $pageSize: Int) {
        categories(where: $where, page: $page, pageSize: $pageSize) {
            pageInfo {
                page
                totalPages
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    id
                    name
                    iconId
                    color
                    icon {
                        url
                        id
                    }
                    parent {
                        id
                        name
                    }
                }
            }
            totalCount
        }
    }
`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
    return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
}
export function useCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>,
) {
    return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const GetCategoryByIdDocument = gql`
    query GetCategoryById($categoryId: String!) {
        category(id: $categoryId) {
            id
            name
            iconId
            icon {
                id
                name
                url
            }
            color
            parent {
                id
                name
            }
        }
    }
`;

/**
 * __useGetCategoryByIdQuery__
 *
 * To run a query within a React component, call `useGetCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryByIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetCategoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>,
) {
    return Apollo.useQuery<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(GetCategoryByIdDocument, baseOptions);
}
export function useGetCategoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>,
) {
    return Apollo.useLazyQuery<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(
        GetCategoryByIdDocument,
        baseOptions,
    );
}
export type GetCategoryByIdQueryHookResult = ReturnType<typeof useGetCategoryByIdQuery>;
export type GetCategoryByIdLazyQueryHookResult = ReturnType<typeof useGetCategoryByIdLazyQuery>;
export type GetCategoryByIdQueryResult = Apollo.QueryResult<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>;
export const ConfigurationsDocument = gql`
    query Configurations {
        configurations {
            edges {
                node {
                    type
                    price
                    id
                }
            }
        }
    }
`;

/**
 * __useConfigurationsQuery__
 *
 * To run a query within a React component, call `useConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationsQuery(
    baseOptions?: Apollo.QueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>,
) {
    return Apollo.useQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, baseOptions);
}
export function useConfigurationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>,
) {
    return Apollo.useLazyQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, baseOptions);
}
export type ConfigurationsQueryHookResult = ReturnType<typeof useConfigurationsQuery>;
export type ConfigurationsLazyQueryHookResult = ReturnType<typeof useConfigurationsLazyQuery>;
export type ConfigurationsQueryResult = Apollo.QueryResult<ConfigurationsQuery, ConfigurationsQueryVariables>;
export const RegionsDocument = gql`
    query Regions {
        regions {
            edges {
                node {
                    id
                    name
                    active
                    currencies {
                        edges {
                            node {
                                id
                                name
                                abbreviation
                                rate
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
    return Apollo.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
}
export function useRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
    return Apollo.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
}
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<RegionsQuery, RegionsQueryVariables>;
export const MyBonusesDocument = gql`
    query MyBonuses {
        myBonuses {
            edges {
                node {
                    createdAt
                    bonus {
                        amount
                    }
                    isVerified
                }
            }
        }
    }
`;

/**
 * __useMyBonusesQuery__
 *
 * To run a query within a React component, call `useMyBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBonusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBonusesQuery(baseOptions?: Apollo.QueryHookOptions<MyBonusesQuery, MyBonusesQueryVariables>) {
    return Apollo.useQuery<MyBonusesQuery, MyBonusesQueryVariables>(MyBonusesDocument, baseOptions);
}
export function useMyBonusesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyBonusesQuery, MyBonusesQueryVariables>,
) {
    return Apollo.useLazyQuery<MyBonusesQuery, MyBonusesQueryVariables>(MyBonusesDocument, baseOptions);
}
export type MyBonusesQueryHookResult = ReturnType<typeof useMyBonusesQuery>;
export type MyBonusesLazyQueryHookResult = ReturnType<typeof useMyBonusesLazyQuery>;
export type MyBonusesQueryResult = Apollo.QueryResult<MyBonusesQuery, MyBonusesQueryVariables>;
export const CustomersDocument = gql`
    query Customers($page: Int, $pageSize: Int, $where: CustomerWhereInput) {
        customers(page: $page, pageSize: $pageSize, where: $where) {
            edges {
                node {
                    id
                    userId
                    code
                    balance
                    idFin
                    idSerialNumber
                    createdAt
                    isRegistered
                    status
                    branches {
                        edges {
                            node {
                                name
                                price
                            }
                        }
                    }
                    user {
                        fullName
                        email
                        phoneNumber
                        gender
                        address
                        lastLogin
                    }
                    customerBonuses {
                        edges {
                            node {
                                referrerCustomer {
                                    user {
                                        fullName
                                    }
                                    code
                                }
                            }
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                page
                totalPages
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
    return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
}
export function useCustomersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>,
) {
    return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const DasboardsDocument = gql`
    query Dasboards {
        dasboards {
            edges {
                node {
                    id
                    code
                    embedUrl
                }
            }
        }
    }
`;

/**
 * __useDasboardsQuery__
 *
 * To run a query within a React component, call `useDasboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDasboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDasboardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDasboardsQuery(baseOptions?: Apollo.QueryHookOptions<DasboardsQuery, DasboardsQueryVariables>) {
    return Apollo.useQuery<DasboardsQuery, DasboardsQueryVariables>(DasboardsDocument, baseOptions);
}
export function useDasboardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DasboardsQuery, DasboardsQueryVariables>,
) {
    return Apollo.useLazyQuery<DasboardsQuery, DasboardsQueryVariables>(DasboardsDocument, baseOptions);
}
export type DasboardsQueryHookResult = ReturnType<typeof useDasboardsQuery>;
export type DasboardsLazyQueryHookResult = ReturnType<typeof useDasboardsLazyQuery>;
export type DasboardsQueryResult = Apollo.QueryResult<DasboardsQuery, DasboardsQueryVariables>;
export const ContainerDocument = gql`
    query Container($containerId: String!) {
        container(id: $containerId) {
            id
            name
            totalWeight
            packageCount
            packages {
                edges {
                    node {
                        airWaybill
                        cargoCompany
                        categoryId
                        containerId
                        count
                        country {
                            id
                            name
                            currencies {
                                edges {
                                    node {
                                        abbreviation
                                        id
                                    }
                                }
                            }
                        }
                        category {
                            name
                            id
                        }
                        store {
                            name
                            id
                            link
                        }
                        countryId
                        createdAt
                        createdById
                        customer {
                            id
                            code
                            user {
                                id
                                lastName
                                fullName
                                firstName
                            }
                            branches {
                                edges {
                                    node {
                                        id
                                        name
                                        price
                                    }
                                }
                            }
                        }
                        customerId
                        declaredValue
                        deliveryPrice
                        description
                        externalShelf {
                            id
                            name
                        }
                        falkonTrackingNumber
                        flightId
                        id
                        internalShelf {
                            id
                            name
                        }
                        internalShelfId
                        invoicePrice
                        materialType
                        packageNumber
                        parcelNumber
                        paymentStatus
                        regNumber
                        smartCustomStatus
                        status
                        storeTrackingNumber
                        invoicePriceUpdated
                        weight
                        volumetricWeight
                        deliveryPrice
                        declaredValue
                        estimatedAt
                    }
                }
            }
        }
    }
`;

/**
 * __useContainerQuery__
 *
 * To run a query within a React component, call `useContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useContainerQuery(baseOptions: Apollo.QueryHookOptions<ContainerQuery, ContainerQueryVariables>) {
    return Apollo.useQuery<ContainerQuery, ContainerQueryVariables>(ContainerDocument, baseOptions);
}
export function useContainerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContainerQuery, ContainerQueryVariables>,
) {
    return Apollo.useLazyQuery<ContainerQuery, ContainerQueryVariables>(ContainerDocument, baseOptions);
}
export type ContainerQueryHookResult = ReturnType<typeof useContainerQuery>;
export type ContainerLazyQueryHookResult = ReturnType<typeof useContainerLazyQuery>;
export type ContainerQueryResult = Apollo.QueryResult<ContainerQuery, ContainerQueryVariables>;
export const ContainersDocument = gql`
    query Containers($pageSize: Int, $page: Int, $where: ContainerWhereInput) {
        containers(pageSize: $pageSize, page: $page, where: $where) {
            edges {
                node {
                    createdAt
                    flightId
                    id
                    name
                    packageCount
                    totalWeight
                    version
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
        }
    }
`;

/**
 * __useContainersQuery__
 *
 * To run a query within a React component, call `useContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainersQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useContainersQuery(baseOptions?: Apollo.QueryHookOptions<ContainersQuery, ContainersQueryVariables>) {
    return Apollo.useQuery<ContainersQuery, ContainersQueryVariables>(ContainersDocument, baseOptions);
}
export function useContainersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContainersQuery, ContainersQueryVariables>,
) {
    return Apollo.useLazyQuery<ContainersQuery, ContainersQueryVariables>(ContainersDocument, baseOptions);
}
export type ContainersQueryHookResult = ReturnType<typeof useContainersQuery>;
export type ContainersLazyQueryHookResult = ReturnType<typeof useContainersLazyQuery>;
export type ContainersQueryResult = Apollo.QueryResult<ContainersQuery, ContainersQueryVariables>;
export const CronJobsDocument = gql`
    query CronJobs {
        cronJobs {
            edges {
                node {
                    id
                    name
                    isActivated
                }
            }
        }
    }
`;

/**
 * __useCronJobsQuery__
 *
 * To run a query within a React component, call `useCronJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCronJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCronJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCronJobsQuery(baseOptions?: Apollo.QueryHookOptions<CronJobsQuery, CronJobsQueryVariables>) {
    return Apollo.useQuery<CronJobsQuery, CronJobsQueryVariables>(CronJobsDocument, baseOptions);
}
export function useCronJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CronJobsQuery, CronJobsQueryVariables>) {
    return Apollo.useLazyQuery<CronJobsQuery, CronJobsQueryVariables>(CronJobsDocument, baseOptions);
}
export type CronJobsQueryHookResult = ReturnType<typeof useCronJobsQuery>;
export type CronJobsLazyQueryHookResult = ReturnType<typeof useCronJobsLazyQuery>;
export type CronJobsQueryResult = Apollo.QueryResult<CronJobsQuery, CronJobsQueryVariables>;
export const CustomerDocument = gql`
    query Customer($customerId: String!) {
        customer(id: $customerId) {
            id
            userId
            code
            balance
            idFin
            idSerialNumber
            createdAt
            isRegistered
            status
            branches {
                edges {
                    node {
                        id
                        name
                        price
                    }
                }
            }
            user {
                firstName
                lastName
                emailVerified
                fullName
                email
                phoneNumber
                gender
                address
                lastLogin
                dateOfBirth
            }
            customerBonuses {
                edges {
                    node {
                        referrerCustomer {
                            user {
                                fullName
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
    return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
}
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
    return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const DeleteTariffDocument = gql`
    mutation DeleteTariff($deleteTariffId: String!) {
        deleteTariff(id: $deleteTariffId) {
            id
        }
    }
`;
export type DeleteTariffMutationFn = Apollo.MutationFunction<DeleteTariffMutation, DeleteTariffMutationVariables>;

/**
 * __useDeleteTariffMutation__
 *
 * To run a mutation, you first call `useDeleteTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTariffMutation, { data, loading, error }] = useDeleteTariffMutation({
 *   variables: {
 *      deleteTariffId: // value for 'deleteTariffId'
 *   },
 * });
 */
export function useDeleteTariffMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteTariffMutation, DeleteTariffMutationVariables>,
) {
    return Apollo.useMutation<DeleteTariffMutation, DeleteTariffMutationVariables>(DeleteTariffDocument, baseOptions);
}
export type DeleteTariffMutationHookResult = ReturnType<typeof useDeleteTariffMutation>;
export type DeleteTariffMutationResult = Apollo.MutationResult<DeleteTariffMutation>;
export type DeleteTariffMutationOptions = Apollo.BaseMutationOptions<
    DeleteTariffMutation,
    DeleteTariffMutationVariables
>;
export const ExchangeRatesDocument = gql`
    query ExchangeRates {
        exchangeRates {
            edges {
                node {
                    id
                    to
                    from
                    rate
                }
            }
        }
    }
`;

/**
 * __useExchangeRatesQuery__
 *
 * To run a query within a React component, call `useExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangeRatesQuery(
    baseOptions?: Apollo.QueryHookOptions<ExchangeRatesQuery, ExchangeRatesQueryVariables>,
) {
    return Apollo.useQuery<ExchangeRatesQuery, ExchangeRatesQueryVariables>(ExchangeRatesDocument, baseOptions);
}
export function useExchangeRatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangeRatesQuery, ExchangeRatesQueryVariables>,
) {
    return Apollo.useLazyQuery<ExchangeRatesQuery, ExchangeRatesQueryVariables>(ExchangeRatesDocument, baseOptions);
}
export type ExchangeRatesQueryHookResult = ReturnType<typeof useExchangeRatesQuery>;
export type ExchangeRatesLazyQueryHookResult = ReturnType<typeof useExchangeRatesLazyQuery>;
export type ExchangeRatesQueryResult = Apollo.QueryResult<ExchangeRatesQuery, ExchangeRatesQueryVariables>;
export const ExternalWarehousesDocument = gql`
    query ExternalWarehouses($page: Int, $pageSize: Int, $where: ExternalWarehouseWhereInput) {
        externalWarehouses(page: $page, pageSize: $pageSize, where: $where) {
            edges {
                node {
                    id
                    name
                    externalShelfs {
                        totalCount
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                page
                totalPages
            }
        }
    }
`;

/**
 * __useExternalWarehousesQuery__
 *
 * To run a query within a React component, call `useExternalWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalWarehousesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExternalWarehousesQuery(
    baseOptions?: Apollo.QueryHookOptions<ExternalWarehousesQuery, ExternalWarehousesQueryVariables>,
) {
    return Apollo.useQuery<ExternalWarehousesQuery, ExternalWarehousesQueryVariables>(
        ExternalWarehousesDocument,
        baseOptions,
    );
}
export function useExternalWarehousesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExternalWarehousesQuery, ExternalWarehousesQueryVariables>,
) {
    return Apollo.useLazyQuery<ExternalWarehousesQuery, ExternalWarehousesQueryVariables>(
        ExternalWarehousesDocument,
        baseOptions,
    );
}
export type ExternalWarehousesQueryHookResult = ReturnType<typeof useExternalWarehousesQuery>;
export type ExternalWarehousesLazyQueryHookResult = ReturnType<typeof useExternalWarehousesLazyQuery>;
export type ExternalWarehousesQueryResult = Apollo.QueryResult<
    ExternalWarehousesQuery,
    ExternalWarehousesQueryVariables
>;
export const FlightDocument = gql`
    query Flight($flightId: String!) {
        flight(id: $flightId) {
            id
            name
            packageCount
            totalWeight
            containerCount
            createdAt
            cwb
            containers {
                edges {
                    node {
                        id
                        name
                        packageCount
                        totalWeight
                    }
                }
            }
        }
    }
`;

/**
 * __useFlightQuery__
 *
 * To run a query within a React component, call `useFlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlightQuery({
 *   variables: {
 *      flightId: // value for 'flightId'
 *   },
 * });
 */
export function useFlightQuery(baseOptions: Apollo.QueryHookOptions<FlightQuery, FlightQueryVariables>) {
    return Apollo.useQuery<FlightQuery, FlightQueryVariables>(FlightDocument, baseOptions);
}
export function useFlightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlightQuery, FlightQueryVariables>) {
    return Apollo.useLazyQuery<FlightQuery, FlightQueryVariables>(FlightDocument, baseOptions);
}
export type FlightQueryHookResult = ReturnType<typeof useFlightQuery>;
export type FlightLazyQueryHookResult = ReturnType<typeof useFlightLazyQuery>;
export type FlightQueryResult = Apollo.QueryResult<FlightQuery, FlightQueryVariables>;
export const FlightsDocument = gql`
    query Flights($page: Int, $pageSize: Int, $where: FlightWhereInput) {
        flights(page: $page, pageSize: $pageSize, where: $where) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    name
                    packageCount
                    version
                    totalWeight
                    cwb
                    createdAt
                    completedAt
                    packages {
                        totalCount
                        pageInfo {
                            page
                            totalPages
                            hasNextPage
                            hasPreviousPage
                        }
                    }
                    containers {
                        totalCount
                        pageInfo {
                            page
                            totalPages
                            hasNextPage
                            hasPreviousPage
                        }
                    }
                    containerCount
                }
            }
        }
    }
`;

/**
 * __useFlightsQuery__
 *
 * To run a query within a React component, call `useFlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlightsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFlightsQuery(baseOptions?: Apollo.QueryHookOptions<FlightsQuery, FlightsQueryVariables>) {
    return Apollo.useQuery<FlightsQuery, FlightsQueryVariables>(FlightsDocument, baseOptions);
}
export function useFlightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlightsQuery, FlightsQueryVariables>) {
    return Apollo.useLazyQuery<FlightsQuery, FlightsQueryVariables>(FlightsDocument, baseOptions);
}
export type FlightsQueryHookResult = ReturnType<typeof useFlightsQuery>;
export type FlightsLazyQueryHookResult = ReturnType<typeof useFlightsLazyQuery>;
export type FlightsQueryResult = Apollo.QueryResult<FlightsQuery, FlightsQueryVariables>;
export const InternalShelfsDocument = gql`
    query InternalShelfs($where: InternalShelfWhereInput, $page: Int, $pageSize: Int) {
        internalShelfs(where: $where, page: $page, pageSize: $pageSize) {
            edges {
                node {
                    id
                    name
                    internalWarehouseId
                    internalWarehouse {
                        name
                        id
                    }
                }
            }
            pageInfo {
                page
                totalPages
            }
            totalCount
        }
    }
`;

/**
 * __useInternalShelfsQuery__
 *
 * To run a query within a React component, call `useInternalShelfsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalShelfsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalShelfsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useInternalShelfsQuery(
    baseOptions?: Apollo.QueryHookOptions<InternalShelfsQuery, InternalShelfsQueryVariables>,
) {
    return Apollo.useQuery<InternalShelfsQuery, InternalShelfsQueryVariables>(InternalShelfsDocument, baseOptions);
}
export function useInternalShelfsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<InternalShelfsQuery, InternalShelfsQueryVariables>,
) {
    return Apollo.useLazyQuery<InternalShelfsQuery, InternalShelfsQueryVariables>(InternalShelfsDocument, baseOptions);
}
export type InternalShelfsQueryHookResult = ReturnType<typeof useInternalShelfsQuery>;
export type InternalShelfsLazyQueryHookResult = ReturnType<typeof useInternalShelfsLazyQuery>;
export type InternalShelfsQueryResult = Apollo.QueryResult<InternalShelfsQuery, InternalShelfsQueryVariables>;
export const InternalWarehousesDocument = gql`
    query InternalWarehouses($page: Int, $pageSize: Int, $where: InternalWarehouseWhereInput) {
        internalWarehouses(page: $page, pageSize: $pageSize, where: $where) {
            edges {
                node {
                    id
                    name
                    internalShelfs {
                        totalCount
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
            pageInfo {
                page
                totalPages
            }
            totalCount
        }
    }
`;

/**
 * __useInternalWarehousesQuery__
 *
 * To run a query within a React component, call `useInternalWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalWarehousesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInternalWarehousesQuery(
    baseOptions?: Apollo.QueryHookOptions<InternalWarehousesQuery, InternalWarehousesQueryVariables>,
) {
    return Apollo.useQuery<InternalWarehousesQuery, InternalWarehousesQueryVariables>(
        InternalWarehousesDocument,
        baseOptions,
    );
}
export function useInternalWarehousesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<InternalWarehousesQuery, InternalWarehousesQueryVariables>,
) {
    return Apollo.useLazyQuery<InternalWarehousesQuery, InternalWarehousesQueryVariables>(
        InternalWarehousesDocument,
        baseOptions,
    );
}
export type InternalWarehousesQueryHookResult = ReturnType<typeof useInternalWarehousesQuery>;
export type InternalWarehousesLazyQueryHookResult = ReturnType<typeof useInternalWarehousesLazyQuery>;
export type InternalWarehousesQueryResult = Apollo.QueryResult<
    InternalWarehousesQuery,
    InternalWarehousesQueryVariables
>;
export const ManagerDocument = gql`
    query manager($managerId: String!) {
        manager(id: $managerId) {
            countryId
            user {
                firstName
                lastName
                phoneNumber
                gender
                roles {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useManagerQuery__
 *
 * To run a query within a React component, call `useManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useManagerQuery(baseOptions: Apollo.QueryHookOptions<ManagerQuery, ManagerQueryVariables>) {
    return Apollo.useQuery<ManagerQuery, ManagerQueryVariables>(ManagerDocument, baseOptions);
}
export function useManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagerQuery, ManagerQueryVariables>) {
    return Apollo.useLazyQuery<ManagerQuery, ManagerQueryVariables>(ManagerDocument, baseOptions);
}
export type ManagerQueryHookResult = ReturnType<typeof useManagerQuery>;
export type ManagerLazyQueryHookResult = ReturnType<typeof useManagerLazyQuery>;
export type ManagerQueryResult = Apollo.QueryResult<ManagerQuery, ManagerQueryVariables>;
export const ManagersDocument = gql`
    query Managers($page: Int, $pageSize: Int) {
        managers(page: $page, pageSize: $pageSize) {
            edges {
                node {
                    id
                    createdAt
                    user {
                        id
                        fullName
                        email
                        lastLogin
                        roles {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        customer {
                            id
                            createdAt
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                page
                totalPages
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useManagersQuery(baseOptions?: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
    return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, baseOptions);
}
export function useManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
    return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, baseOptions);
}
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<typeof useManagersLazyQuery>;
export type ManagersQueryResult = Apollo.QueryResult<ManagersQuery, ManagersQueryVariables>;
export const PackagesByAdminWithoutPaginationDocument = gql`
    query PackagesByAdminWithoutPagination($where: PackageWhereInput) {
        packagesByAdmin(where: $where) {
            edges {
                node {
                    id
                    falkonTrackingNumber
                    storeTrackingNumber
                    customerId
                    weight
                    customer {
                        code
                        branches {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                        user {
                            fullName
                            phoneNumber
                            address
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __usePackagesByAdminWithoutPaginationQuery__
 *
 * To run a query within a React component, call `usePackagesByAdminWithoutPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesByAdminWithoutPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesByAdminWithoutPaginationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePackagesByAdminWithoutPaginationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PackagesByAdminWithoutPaginationQuery,
        PackagesByAdminWithoutPaginationQueryVariables
    >,
) {
    return Apollo.useQuery<PackagesByAdminWithoutPaginationQuery, PackagesByAdminWithoutPaginationQueryVariables>(
        PackagesByAdminWithoutPaginationDocument,
        baseOptions,
    );
}
export function usePackagesByAdminWithoutPaginationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PackagesByAdminWithoutPaginationQuery,
        PackagesByAdminWithoutPaginationQueryVariables
    >,
) {
    return Apollo.useLazyQuery<PackagesByAdminWithoutPaginationQuery, PackagesByAdminWithoutPaginationQueryVariables>(
        PackagesByAdminWithoutPaginationDocument,
        baseOptions,
    );
}
export type PackagesByAdminWithoutPaginationQueryHookResult = ReturnType<
    typeof usePackagesByAdminWithoutPaginationQuery
>;
export type PackagesByAdminWithoutPaginationLazyQueryHookResult = ReturnType<
    typeof usePackagesByAdminWithoutPaginationLazyQuery
>;
export type PackagesByAdminWithoutPaginationQueryResult = Apollo.QueryResult<
    PackagesByAdminWithoutPaginationQuery,
    PackagesByAdminWithoutPaginationQueryVariables
>;
export const PackagesByAdminDocument = gql`
    query PackagesByAdmin($page: Int, $pageSize: Int, $where: PackageWhereInput, $orderBy: PackageOrderBy) {
        packagesByAdmin(page: $page, pageSize: $pageSize, where: $where, orderBy: $orderBy) {
            edges {
                node {
                    id
                    createdAt
                    parcelNumber
                    returnId
                    returnedAt
                    cargoCompany
                    note
                    airWaybill
                    invoicePriceUpdated
                    falkonTrackingNumber
                    storeTrackingNumber
                    volumetricWeight
                    customerId
                    regNumber
                    purchaseOrder {
                        id
                        purchaseOrderNumber
                    }
                    customer {
                        code
                        branches {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                        idFin
                        user {
                            fullName
                            phoneNumber
                            address
                        }
                    }
                    country {
                        name
                        currencies {
                            edges {
                                node {
                                    rate
                                    abbreviation
                                }
                            }
                        }
                    }
                    store {
                        name
                    }
                    weight
                    count
                    invoicePrice
                    deliveryPrice
                    declaredValue
                    status
                    smartCustomStatus
                    estimatedAt
                    paymentStatus
                    externalShelf {
                        id
                        name
                    }
                    internalShelf {
                        id
                        name
                    }
                }
            }
            totalCount
            pageInfo {
                page
                totalPages
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

/**
 * __usePackagesByAdminQuery__
 *
 * To run a query within a React component, call `usePackagesByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesByAdminQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePackagesByAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<PackagesByAdminQuery, PackagesByAdminQueryVariables>,
) {
    return Apollo.useQuery<PackagesByAdminQuery, PackagesByAdminQueryVariables>(PackagesByAdminDocument, baseOptions);
}
export function usePackagesByAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PackagesByAdminQuery, PackagesByAdminQueryVariables>,
) {
    return Apollo.useLazyQuery<PackagesByAdminQuery, PackagesByAdminQueryVariables>(
        PackagesByAdminDocument,
        baseOptions,
    );
}
export type PackagesByAdminQueryHookResult = ReturnType<typeof usePackagesByAdminQuery>;
export type PackagesByAdminLazyQueryHookResult = ReturnType<typeof usePackagesByAdminLazyQuery>;
export type PackagesByAdminQueryResult = Apollo.QueryResult<PackagesByAdminQuery, PackagesByAdminQueryVariables>;
export const PackagesTerminalDocument = gql`
    query PackagesTerminal($where: PackageWhereInput) {
        packagesByAdmin(where: $where) {
            edges {
                node {
                    falkonTrackingNumber
                    store {
                        name
                    }
                    customer {
                        user {
                            firstName
                            lastName
                            fullName
                            phoneNumber
                            address
                        }
                        idFin
                        code
                        branches {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                    weight
                    count
                    invoicePrice
                    deliveryPrice
                    declaredValue
                    regNumber
                    id
                    smartCustomStatus
                    country {
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __usePackagesTerminalQuery__
 *
 * To run a query within a React component, call `usePackagesTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesTerminalQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePackagesTerminalQuery(
    baseOptions?: Apollo.QueryHookOptions<PackagesTerminalQuery, PackagesTerminalQueryVariables>,
) {
    return Apollo.useQuery<PackagesTerminalQuery, PackagesTerminalQueryVariables>(
        PackagesTerminalDocument,
        baseOptions,
    );
}
export function usePackagesTerminalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PackagesTerminalQuery, PackagesTerminalQueryVariables>,
) {
    return Apollo.useLazyQuery<PackagesTerminalQuery, PackagesTerminalQueryVariables>(
        PackagesTerminalDocument,
        baseOptions,
    );
}
export type PackagesTerminalQueryHookResult = ReturnType<typeof usePackagesTerminalQuery>;
export type PackagesTerminalLazyQueryHookResult = ReturnType<typeof usePackagesTerminalLazyQuery>;
export type PackagesTerminalQueryResult = Apollo.QueryResult<PackagesTerminalQuery, PackagesTerminalQueryVariables>;
export const PurchaseOrdersCountDocument = gql`
    query PurchaseOrdersCount {
        purchaseOrdersCount
    }
`;

/**
 * __usePurchaseOrdersCountQuery__
 *
 * To run a query within a React component, call `usePurchaseOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrdersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchaseOrdersCountQuery(
    baseOptions?: Apollo.QueryHookOptions<PurchaseOrdersCountQuery, PurchaseOrdersCountQueryVariables>,
) {
    return Apollo.useQuery<PurchaseOrdersCountQuery, PurchaseOrdersCountQueryVariables>(
        PurchaseOrdersCountDocument,
        baseOptions,
    );
}
export function usePurchaseOrdersCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrdersCountQuery, PurchaseOrdersCountQueryVariables>,
) {
    return Apollo.useLazyQuery<PurchaseOrdersCountQuery, PurchaseOrdersCountQueryVariables>(
        PurchaseOrdersCountDocument,
        baseOptions,
    );
}
export type PurchaseOrdersCountQueryHookResult = ReturnType<typeof usePurchaseOrdersCountQuery>;
export type PurchaseOrdersCountLazyQueryHookResult = ReturnType<typeof usePurchaseOrdersCountLazyQuery>;
export type PurchaseOrdersCountQueryResult = Apollo.QueryResult<
    PurchaseOrdersCountQuery,
    PurchaseOrdersCountQueryVariables
>;
export const PurchaseOrdersByAdminDocument = gql`
    query PurchaseOrdersByAdmin(
        $where: PurchaseOrderWhereInput
        $page: Int
        $orderBy: PurchaseOrderOrderBy
        $pageSize: Int
    ) {
        purchaseOrdersByAdmin(where: $where, page: $page, orderBy: $orderBy, pageSize: $pageSize) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    createdAt
                    customerId
                    package {
                        falkonTrackingNumber
                        storeTrackingNumber
                        categoryId
                    }
                    customer {
                        code
                        user {
                            firstName
                            lastName
                            fullName
                        }
                    }
                    country {
                        name
                        currencies {
                            edges {
                                node {
                                    rate
                                    abbreviation
                                }
                            }
                        }
                    }
                    totalPrice
                    invoicePrice
                    storeCargoPrice
                    status
                    storeLink
                    count
                    color
                    description
                    size
                    purchaseOrderNumber
                    expeditedServicePrice
                    statuses {
                        edges {
                            node {
                                id
                                status
                                createdAt
                            }
                        }
                    }
                    store {
                        id
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __usePurchaseOrdersByAdminQuery__
 *
 * To run a query within a React component, call `usePurchaseOrdersByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrdersByAdminQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function usePurchaseOrdersByAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<PurchaseOrdersByAdminQuery, PurchaseOrdersByAdminQueryVariables>,
) {
    return Apollo.useQuery<PurchaseOrdersByAdminQuery, PurchaseOrdersByAdminQueryVariables>(
        PurchaseOrdersByAdminDocument,
        baseOptions,
    );
}
export function usePurchaseOrdersByAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrdersByAdminQuery, PurchaseOrdersByAdminQueryVariables>,
) {
    return Apollo.useLazyQuery<PurchaseOrdersByAdminQuery, PurchaseOrdersByAdminQueryVariables>(
        PurchaseOrdersByAdminDocument,
        baseOptions,
    );
}
export type PurchaseOrdersByAdminQueryHookResult = ReturnType<typeof usePurchaseOrdersByAdminQuery>;
export type PurchaseOrdersByAdminLazyQueryHookResult = ReturnType<typeof usePurchaseOrdersByAdminLazyQuery>;
export type PurchaseOrdersByAdminQueryResult = Apollo.QueryResult<
    PurchaseOrdersByAdminQuery,
    PurchaseOrdersByAdminQueryVariables
>;
export const ShelfsDocument = gql`
    query Shelfs($page: Int, $pageSize: Int, $where: ShelfWhereInput) {
        shelfs(page: $page, pageSize: $pageSize, where: $where) {
            edges {
                node {
                    id
                    name
                    externalWarehouseId
                    externalWarehouse {
                        id
                        name
                    }
                }
            }
            pageInfo {
                page
                totalPages
            }
            totalCount
        }
    }
`;

/**
 * __useShelfsQuery__
 *
 * To run a query within a React component, call `useShelfsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShelfsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShelfsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useShelfsQuery(baseOptions?: Apollo.QueryHookOptions<ShelfsQuery, ShelfsQueryVariables>) {
    return Apollo.useQuery<ShelfsQuery, ShelfsQueryVariables>(ShelfsDocument, baseOptions);
}
export function useShelfsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShelfsQuery, ShelfsQueryVariables>) {
    return Apollo.useLazyQuery<ShelfsQuery, ShelfsQueryVariables>(ShelfsDocument, baseOptions);
}
export type ShelfsQueryHookResult = ReturnType<typeof useShelfsQuery>;
export type ShelfsLazyQueryHookResult = ReturnType<typeof useShelfsLazyQuery>;
export type ShelfsQueryResult = Apollo.QueryResult<ShelfsQuery, ShelfsQueryVariables>;
export const TariffDocument = gql`
    query Tariff($tariffId: String!) {
        tariff(id: $tariffId) {
            id
            countryId
            country {
                id
                name
            }
            isActive
            price
            type
            weightId
        }
    }
`;

/**
 * __useTariffQuery__
 *
 * To run a query within a React component, call `useTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffQuery({
 *   variables: {
 *      tariffId: // value for 'tariffId'
 *   },
 * });
 */
export function useTariffQuery(baseOptions: Apollo.QueryHookOptions<TariffQuery, TariffQueryVariables>) {
    return Apollo.useQuery<TariffQuery, TariffQueryVariables>(TariffDocument, baseOptions);
}
export function useTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TariffQuery, TariffQueryVariables>) {
    return Apollo.useLazyQuery<TariffQuery, TariffQueryVariables>(TariffDocument, baseOptions);
}
export type TariffQueryHookResult = ReturnType<typeof useTariffQuery>;
export type TariffLazyQueryHookResult = ReturnType<typeof useTariffLazyQuery>;
export type TariffQueryResult = Apollo.QueryResult<TariffQuery, TariffQueryVariables>;
export const TariffsDocument = gql`
    query Tariffs {
        tariffs {
            edges {
                node {
                    id
                    isActive
                    price
                    type
                    countryId
                    weightId
                    weight {
                        id
                        name
                        min
                        max
                    }
                    country {
                        id
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __useTariffsQuery__
 *
 * To run a query within a React component, call `useTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTariffsQuery(baseOptions?: Apollo.QueryHookOptions<TariffsQuery, TariffsQueryVariables>) {
    return Apollo.useQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, baseOptions);
}
export function useTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TariffsQuery, TariffsQueryVariables>) {
    return Apollo.useLazyQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, baseOptions);
}
export type TariffsQueryHookResult = ReturnType<typeof useTariffsQuery>;
export type TariffsLazyQueryHookResult = ReturnType<typeof useTariffsLazyQuery>;
export type TariffsQueryResult = Apollo.QueryResult<TariffsQuery, TariffsQueryVariables>;
export const TransactionsByAdminDocument = gql`
    query TransactionsByAdmin($where: TransactionWhereInput, $page: Int, $pageSize: Int) {
        transactions(where: $where, page: $page, pageSize: $pageSize) {
            totalCount
            edges {
                node {
                    id
                    customerId
                    createdAt
                    customer {
                        userId
                        user {
                            firstName
                            lastName
                        }
                        code
                    }
                    amount
                    type
                    status
                    salesOrderId
                    sessionId
                    orderId
                    salesOrderLines {
                        edges {
                            node {
                                package {
                                    id
                                    falkonTrackingNumber
                                }
                                purchaseOrder {
                                    id
                                    purchaseOrderNumber
                                }
                                type
                            }
                        }
                    }
                }
            }
            pageInfo {
                totalPages
            }
        }
    }
`;

/**
 * __useTransactionsByAdminQuery__
 *
 * To run a query within a React component, call `useTransactionsByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsByAdminQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTransactionsByAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<TransactionsByAdminQuery, TransactionsByAdminQueryVariables>,
) {
    return Apollo.useQuery<TransactionsByAdminQuery, TransactionsByAdminQueryVariables>(
        TransactionsByAdminDocument,
        baseOptions,
    );
}
export function useTransactionsByAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TransactionsByAdminQuery, TransactionsByAdminQueryVariables>,
) {
    return Apollo.useLazyQuery<TransactionsByAdminQuery, TransactionsByAdminQueryVariables>(
        TransactionsByAdminDocument,
        baseOptions,
    );
}
export type TransactionsByAdminQueryHookResult = ReturnType<typeof useTransactionsByAdminQuery>;
export type TransactionsByAdminLazyQueryHookResult = ReturnType<typeof useTransactionsByAdminLazyQuery>;
export type TransactionsByAdminQueryResult = Apollo.QueryResult<
    TransactionsByAdminQuery,
    TransactionsByAdminQueryVariables
>;
export const UserPermissionsDocument = gql`
    query UserPermissions($where: UserPermissionWhereInput!) {
        userPermissions(where: $where) {
            edges {
                node {
                    id
                    userId
                    permissionId
                    permission {
                        id
                        name
                        parameters {
                            edges {
                                node {
                                    id
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserPermissionsQuery(
    baseOptions: Apollo.QueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
) {
    return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, baseOptions);
}
export function useUserPermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
) {
    return Apollo.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
        UserPermissionsDocument,
        baseOptions,
    );
}
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<UserPermissionsQuery, UserPermissionsQueryVariables>;
export const MeDocument = gql`
    query Me {
        me {
            id
            email
            firstName
            lastName
            phoneNumber
            dateOfBirth
            gender
            fullName
            customer {
                idFin
                idSerialNumber
                balance
                mounthlyLimit
                totalBonusAmount
                code
                branches {
                    edges {
                        node {
                            name
                            id
                        }
                    }
                }
            }
            avatar {
                id
                name
                url
            }
        }
    }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MessagesDocument = gql`
    query Messages($pageSize: Int, $page: Int) {
        messages(pageSize: $pageSize, page: $page) {
            edges {
                node {
                    id
                    name
                    message
                    phoneNumber
                    isAnswered
                    code
                    createdAt
                    isRead
                    email
                    children {
                        edges {
                            node {
                                message
                                createdAt
                            }
                        }
                    }
                }
            }
            pageInfo {
                totalPages
            }
            totalCount
        }
    }
`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions?: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
    return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, baseOptions);
}
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
    return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, baseOptions);
}
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const PackageDocument = gql`
    query Package($packageId: String!) {
        package(id: $packageId) {
            category {
                name
            }
            airWaybill
            note
            cargoCompany
            returnId
            parcelNumber
            storeTrackingNumber
            purchaseOrder {
                id
                purchaseOrderNumber
            }
            store {
                id
                name
            }
            categoryId
            customerId
            count
            country {
                id
                name
                currencies {
                    edges {
                        node {
                            rate
                            abbreviation
                            name
                        }
                    }
                }
            }
            countryId
            createdAt
            updatedAt
            createdBy {
                fullName
                customer {
                    code
                    id
                }
            }
            declaredValue
            deliveryPrice
            description
            estimatedAt
            falkonTrackingNumber
            file {
                size
                name
                url
                id
            }
            height
            id
            invoicePrice
            length
            materialType
            smartCustomStatus
            paymentStatus
            status
            weight
            width
            customer {
                code
                branches {
                    edges {
                        node {
                            price
                        }
                    }
                }
                user {
                    fullName
                }
            }
        }
    }
`;

/**
 * __usePackageQuery__
 *
 * To run a query within a React component, call `usePackageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageQuery({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function usePackageQuery(baseOptions: Apollo.QueryHookOptions<PackageQuery, PackageQueryVariables>) {
    return Apollo.useQuery<PackageQuery, PackageQueryVariables>(PackageDocument, baseOptions);
}
export function usePackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageQuery, PackageQueryVariables>) {
    return Apollo.useLazyQuery<PackageQuery, PackageQueryVariables>(PackageDocument, baseOptions);
}
export type PackageQueryHookResult = ReturnType<typeof usePackageQuery>;
export type PackageLazyQueryHookResult = ReturnType<typeof usePackageLazyQuery>;
export type PackageQueryResult = Apollo.QueryResult<PackageQuery, PackageQueryVariables>;
export const PackagesDocument = gql`
    query packages($where: PackageWhereInput, $page: Int, $pageSize: Int) {
        packages(where: $where, page: $page, pageSize: $pageSize) {
            edges {
                node {
                    id
                    status
                    smartCustomStatus
                    paymentStatus
                    materialType
                    count
                    invoicePrice
                    deliveryPrice
                    volumetricWeight
                    declaredValue
                    falkonTrackingNumber
                    storeTrackingNumber
                    airWaybill
                    parcelNumber
                    regNumber
                    packageNumber
                    width
                    height
                    length
                    weight
                    description
                    customerId
                    countryId
                    storeId
                    categoryId
                    fileId
                    createdById
                    updatedById
                    createdAt
                    updatedAt
                    estimatedAt
                    invoicePriceUpdated
                    shelfId
                    customer {
                        id
                        code
                    }
                    country {
                        id
                        name
                        currencies {
                            edges {
                                node {
                                    rate
                                    abbreviation
                                    name
                                }
                            }
                        }
                    }
                    createdBy {
                        id
                        fullName
                    }
                    updatedBy {
                        id
                        fullName
                    }
                    store {
                        id
                        name
                    }
                    category {
                        id
                        name
                    }
                    file {
                        id
                        url
                        name
                    }
                    statuses {
                        edges {
                            node {
                                id
                                status
                                createdById
                                createdAt
                            }
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                page
                totalPages
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

/**
 * __usePackagesQuery__
 *
 * To run a query within a React component, call `usePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function usePackagesQuery(baseOptions?: Apollo.QueryHookOptions<PackagesQuery, PackagesQueryVariables>) {
    return Apollo.useQuery<PackagesQuery, PackagesQueryVariables>(PackagesDocument, baseOptions);
}
export function usePackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesQuery, PackagesQueryVariables>) {
    return Apollo.useLazyQuery<PackagesQuery, PackagesQueryVariables>(PackagesDocument, baseOptions);
}
export type PackagesQueryHookResult = ReturnType<typeof usePackagesQuery>;
export type PackagesLazyQueryHookResult = ReturnType<typeof usePackagesLazyQuery>;
export type PackagesQueryResult = Apollo.QueryResult<PackagesQuery, PackagesQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions {
        permissions {
            edges {
                node {
                    id
                    name
                    description
                    parameters {
                        edges {
                            node {
                                id
                                type
                                value
                                description
                                permissionId
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(
    baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>,
) {
    return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
}
export function usePermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>,
) {
    return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const PurchaseOrderDocument = gql`
    query PurchaseOrder($id: String!) {
        purchaseOrder(id: $id) {
            id
            createdAt
            totalPrice
            invoicePrice
            storeCargoPrice
            status
            storeLink
            count
            color
            description
            size
            countryId
            expeditedServicePrice
            customerId
            package {
                falkonTrackingNumber
                storeTrackingNumber
                categoryId
                materialType
            }
            customer {
                id
                code
                user {
                    id
                    fullName
                }
            }
        }
    }
`;

/**
 * __usePurchaseOrderQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePurchaseOrderQuery(
    baseOptions: Apollo.QueryHookOptions<PurchaseOrderQuery, PurchaseOrderQueryVariables>,
) {
    return Apollo.useQuery<PurchaseOrderQuery, PurchaseOrderQueryVariables>(PurchaseOrderDocument, baseOptions);
}
export function usePurchaseOrderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderQuery, PurchaseOrderQueryVariables>,
) {
    return Apollo.useLazyQuery<PurchaseOrderQuery, PurchaseOrderQueryVariables>(PurchaseOrderDocument, baseOptions);
}
export type PurchaseOrderQueryHookResult = ReturnType<typeof usePurchaseOrderQuery>;
export type PurchaseOrderLazyQueryHookResult = ReturnType<typeof usePurchaseOrderLazyQuery>;
export type PurchaseOrderQueryResult = Apollo.QueryResult<PurchaseOrderQuery, PurchaseOrderQueryVariables>;
export const PurchaseOrdersDocument = gql`
    query PurchaseOrders($where: PurchaseOrderWhereInput, $page: Int, $orderBy: PurchaseOrderOrderBy) {
        purchaseOrders(where: $where, page: $page, orderBy: $orderBy) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    createdAt
                    customerId
                    package {
                        falkonTrackingNumber
                        storeTrackingNumber
                        categoryId
                    }
                    customer {
                        code
                        user {
                            firstName
                            lastName
                            fullName
                        }
                    }
                    country {
                        name
                        currencies {
                            edges {
                                node {
                                    rate
                                    abbreviation
                                }
                            }
                        }
                    }
                    totalPrice
                    invoicePrice
                    storeCargoPrice
                    status
                    storeLink
                    count
                    color
                    description
                    size
                    purchaseOrderNumber
                    expeditedServicePrice
                    statuses {
                        edges {
                            node {
                                id
                                status
                                createdAt
                            }
                        }
                    }
                    store {
                        id
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __usePurchaseOrdersQuery__
 *
 * To run a query within a React component, call `usePurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrdersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePurchaseOrdersQuery(
    baseOptions?: Apollo.QueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>,
) {
    return Apollo.useQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, baseOptions);
}
export function usePurchaseOrdersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>,
) {
    return Apollo.useLazyQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, baseOptions);
}
export type PurchaseOrdersQueryHookResult = ReturnType<typeof usePurchaseOrdersQuery>;
export type PurchaseOrdersLazyQueryHookResult = ReturnType<typeof usePurchaseOrdersLazyQuery>;
export type PurchaseOrdersQueryResult = Apollo.QueryResult<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>;
export const PurchaseSettingsDocument = gql`
    query PurchaseSettings {
        configurations {
            edges {
                node {
                    id
                    type
                    price
                }
            }
        }
    }
`;

/**
 * __usePurchaseSettingsQuery__
 *
 * To run a query within a React component, call `usePurchaseSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchaseSettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<PurchaseSettingsQuery, PurchaseSettingsQueryVariables>,
) {
    return Apollo.useQuery<PurchaseSettingsQuery, PurchaseSettingsQueryVariables>(
        PurchaseSettingsDocument,
        baseOptions,
    );
}
export function usePurchaseSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PurchaseSettingsQuery, PurchaseSettingsQueryVariables>,
) {
    return Apollo.useLazyQuery<PurchaseSettingsQuery, PurchaseSettingsQueryVariables>(
        PurchaseSettingsDocument,
        baseOptions,
    );
}
export type PurchaseSettingsQueryHookResult = ReturnType<typeof usePurchaseSettingsQuery>;
export type PurchaseSettingsLazyQueryHookResult = ReturnType<typeof usePurchaseSettingsLazyQuery>;
export type PurchaseSettingsQueryResult = Apollo.QueryResult<PurchaseSettingsQuery, PurchaseSettingsQueryVariables>;
export const RatesDocument = gql`
    query Rates {
        exchangeRates {
            edges {
                node {
                    from
                    to
                    rate
                }
            }
        }
    }
`;

/**
 * __useRatesQuery__
 *
 * To run a query within a React component, call `useRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRatesQuery(baseOptions?: Apollo.QueryHookOptions<RatesQuery, RatesQueryVariables>) {
    return Apollo.useQuery<RatesQuery, RatesQueryVariables>(RatesDocument, baseOptions);
}
export function useRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatesQuery, RatesQueryVariables>) {
    return Apollo.useLazyQuery<RatesQuery, RatesQueryVariables>(RatesDocument, baseOptions);
}
export type RatesQueryHookResult = ReturnType<typeof useRatesQuery>;
export type RatesLazyQueryHookResult = ReturnType<typeof useRatesLazyQuery>;
export type RatesQueryResult = Apollo.QueryResult<RatesQuery, RatesQueryVariables>;
export const ReturningPackagesDocument = gql`
    query ReturningPackages {
        returningPackages {
            edges {
                node {
                    falkonTrackingNumber
                    store {
                        id
                        name
                        link
                    }
                    category {
                        id
                        name
                        iconId
                        parentId
                    }
                    id
                    invoicePrice
                    status
                    weight
                    volumetricWeight
                    deliveryPrice
                    statuses {
                        edges {
                            node {
                                id
                                status
                                createdById
                                packageId
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useReturningPackagesQuery__
 *
 * To run a query within a React component, call `useReturningPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturningPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturningPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReturningPackagesQuery(
    baseOptions?: Apollo.QueryHookOptions<ReturningPackagesQuery, ReturningPackagesQueryVariables>,
) {
    return Apollo.useQuery<ReturningPackagesQuery, ReturningPackagesQueryVariables>(
        ReturningPackagesDocument,
        baseOptions,
    );
}
export function useReturningPackagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ReturningPackagesQuery, ReturningPackagesQueryVariables>,
) {
    return Apollo.useLazyQuery<ReturningPackagesQuery, ReturningPackagesQueryVariables>(
        ReturningPackagesDocument,
        baseOptions,
    );
}
export type ReturningPackagesQueryHookResult = ReturnType<typeof useReturningPackagesQuery>;
export type ReturningPackagesLazyQueryHookResult = ReturnType<typeof useReturningPackagesLazyQuery>;
export type ReturningPackagesQueryResult = Apollo.QueryResult<ReturningPackagesQuery, ReturningPackagesQueryVariables>;
export const RoleDocument = gql`
    query Role($roleId: String!) {
        role(id: $roleId) {
            id
            name
            rolePermissions {
                edges {
                    node {
                        id
                        parameterId
                        permissionId
                        roleId
                        parameter {
                            id
                            permissionId
                            description
                            type
                            value
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
    return Apollo.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, baseOptions);
}
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
    return Apollo.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, baseOptions);
}
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const RolesDocument = gql`
    query Roles {
        roles {
            edges {
                node {
                    id
                    name
                    rolePermissions {
                        edges {
                            node {
                                id
                                parameterId
                                permissionId
                                roleId
                                parameter {
                                    id
                                    description
                                    permissionId
                                    type
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
    return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
}
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
    return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const CheckSmsBalanceDocument = gql`
    query CheckSmsBalance {
        checkSMSBalance
    }
`;

/**
 * __useCheckSmsBalanceQuery__
 *
 * To run a query within a React component, call `useCheckSmsBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSmsBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSmsBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckSmsBalanceQuery(
    baseOptions?: Apollo.QueryHookOptions<CheckSmsBalanceQuery, CheckSmsBalanceQueryVariables>,
) {
    return Apollo.useQuery<CheckSmsBalanceQuery, CheckSmsBalanceQueryVariables>(CheckSmsBalanceDocument, baseOptions);
}
export function useCheckSmsBalanceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckSmsBalanceQuery, CheckSmsBalanceQueryVariables>,
) {
    return Apollo.useLazyQuery<CheckSmsBalanceQuery, CheckSmsBalanceQueryVariables>(
        CheckSmsBalanceDocument,
        baseOptions,
    );
}
export type CheckSmsBalanceQueryHookResult = ReturnType<typeof useCheckSmsBalanceQuery>;
export type CheckSmsBalanceLazyQueryHookResult = ReturnType<typeof useCheckSmsBalanceLazyQuery>;
export type CheckSmsBalanceQueryResult = Apollo.QueryResult<CheckSmsBalanceQuery, CheckSmsBalanceQueryVariables>;
export const GetStoreByIdDocument = gql`
    query GetStoreById($storeId: String!) {
        store(id: $storeId) {
            id
            name
            link
            isMerchantStore
            logo {
                id
                name
                url
            }
            categories {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __useGetStoreByIdQuery__
 *
 * To run a query within a React component, call `useGetStoreByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreByIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetStoreByIdQuery, GetStoreByIdQueryVariables>,
) {
    return Apollo.useQuery<GetStoreByIdQuery, GetStoreByIdQueryVariables>(GetStoreByIdDocument, baseOptions);
}
export function useGetStoreByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetStoreByIdQuery, GetStoreByIdQueryVariables>,
) {
    return Apollo.useLazyQuery<GetStoreByIdQuery, GetStoreByIdQueryVariables>(GetStoreByIdDocument, baseOptions);
}
export type GetStoreByIdQueryHookResult = ReturnType<typeof useGetStoreByIdQuery>;
export type GetStoreByIdLazyQueryHookResult = ReturnType<typeof useGetStoreByIdLazyQuery>;
export type GetStoreByIdQueryResult = Apollo.QueryResult<GetStoreByIdQuery, GetStoreByIdQueryVariables>;
export const StoresDocument = gql`
    query Stores($page: Int, $pageSize: Int, $where: StoreWhereInput) {
        stores(page: $page, where: $where, pageSize: $pageSize) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                totalPages
                page
            }
            edges {
                node {
                    id
                    name
                    link
                    logo {
                        url
                    }
                    categories {
                        edges {
                            node {
                                id
                                name
                                iconId
                                color
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStoresQuery(baseOptions?: Apollo.QueryHookOptions<StoresQuery, StoresQueryVariables>) {
    return Apollo.useQuery<StoresQuery, StoresQueryVariables>(StoresDocument, baseOptions);
}
export function useStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresQuery, StoresQueryVariables>) {
    return Apollo.useLazyQuery<StoresQuery, StoresQueryVariables>(StoresDocument, baseOptions);
}
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>;
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>;
export type StoresQueryResult = Apollo.QueryResult<StoresQuery, StoresQueryVariables>;
export const UsersDocument = gql`
    query Users {
        customers {
            edges {
                node {
                    code
                    user {
                        fullName
                    }
                }
            }
        }
    }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
    return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
    return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetWeightByIdDocument = gql`
    query GetWeightById($weightId: String!) {
        weight(id: $weightId) {
            id
            name
            min
            max
        }
    }
`;

/**
 * __useGetWeightByIdQuery__
 *
 * To run a query within a React component, call `useGetWeightByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeightByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeightByIdQuery({
 *   variables: {
 *      weightId: // value for 'weightId'
 *   },
 * });
 */
export function useGetWeightByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetWeightByIdQuery, GetWeightByIdQueryVariables>,
) {
    return Apollo.useQuery<GetWeightByIdQuery, GetWeightByIdQueryVariables>(GetWeightByIdDocument, baseOptions);
}
export function useGetWeightByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWeightByIdQuery, GetWeightByIdQueryVariables>,
) {
    return Apollo.useLazyQuery<GetWeightByIdQuery, GetWeightByIdQueryVariables>(GetWeightByIdDocument, baseOptions);
}
export type GetWeightByIdQueryHookResult = ReturnType<typeof useGetWeightByIdQuery>;
export type GetWeightByIdLazyQueryHookResult = ReturnType<typeof useGetWeightByIdLazyQuery>;
export type GetWeightByIdQueryResult = Apollo.QueryResult<GetWeightByIdQuery, GetWeightByIdQueryVariables>;
export const WeightsDocument = gql`
    query Weights {
        weights {
            edges {
                node {
                    id
                    name
                    min
                    max
                }
            }
        }
    }
`;

/**
 * __useWeightsQuery__
 *
 * To run a query within a React component, call `useWeightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWeightsQuery(baseOptions?: Apollo.QueryHookOptions<WeightsQuery, WeightsQueryVariables>) {
    return Apollo.useQuery<WeightsQuery, WeightsQueryVariables>(WeightsDocument, baseOptions);
}
export function useWeightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeightsQuery, WeightsQueryVariables>) {
    return Apollo.useLazyQuery<WeightsQuery, WeightsQueryVariables>(WeightsDocument, baseOptions);
}
export type WeightsQueryHookResult = ReturnType<typeof useWeightsQuery>;
export type WeightsLazyQueryHookResult = ReturnType<typeof useWeightsLazyQuery>;
export type WeightsQueryResult = Apollo.QueryResult<WeightsQuery, WeightsQueryVariables>;
