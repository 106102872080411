import { Box, Link } from '@mui/material';
import { styled } from '@mui/styles';

const Root = styled(Box)(() => ({
    maxWidth: '16ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

type MinimizedLinkProps = {
    to: string;
    name: string;
};

export const MinimizedLink: React.FC<MinimizedLinkProps> = ({ to, name }) => {
    return (
        <Root>
            <Link href={to} target="_blank" sx={{ fontSize: 12 }}>
                {name}
            </Link>
        </Root>
    );
};
