import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';

type ServiceProps = {
    title: string;
    description: string;
    icon: React.ReactNode;
};

type ServiceItemProps = {
    service: ServiceProps;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    '& .service-content': {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            padding: theme.spacing(3, 8),
        },
    },
    '& .service-description': {
        fontSize: '0.75rem',
    },
    '& .icon-box': {
        display: 'flex',
        boxShadow: theme.dark ? 'none' : theme.shadows[3],
        padding: theme.spacing(5),
        borderRadius: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(3),
        },
    },
}));

export const ServiceItem: React.FC<ServiceItemProps> = ({ service }) => {
    return (
        <Root item xs={12} md={6}>
            <Box className="icon-box">{service.icon}</Box>
            <Box className="service-content">
                <Typography variant="h5" mb={2}>
                    {service.title}
                </Typography>
                <Typography color="textSecondary" className="service-description">
                    {service.description}
                </Typography>
            </Box>
        </Root>
    );
};
