import { Box, Popover as MUIPopover, Typography, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';
import { Fragment, MouseEvent, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { notifications } from './data';
import { NotificationItem } from 'components/notification-item';

const Popover = styled(MUIPopover)(({ theme }: { theme: Theme }) => ({
    '& .notification-count': {
        fontWeight: 500,
        margin: theme.spacing(0, 0, 1, 2),
    },
    '& .MuiPaper-root': {
        borderRadius: theme.spacing(3),
        minWidth: '300px',
    },
    '& .notifications': {
        maxHeight: '200px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.1em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#8a8787',
            borderRadius: '8px',
        },
    },
}));

const IconBox = styled(Box)(({ theme }: { theme: Theme }) => ({
    width: 40,
    height: 40,
    borderRadius: theme.spacing(1.25),
    backgroundColor: 'rgba(59, 67, 242, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

export const ManagerNotifications: React.FC<BoxProps> = ({ ...props }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const openPopover = (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };
    const closePopover = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconBox onClick={(event: any) => openPopover(event)} {...props}>
                <NotificationsIcon />
            </IconBox>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box p={2}>
                    <Typography variant="subtitle2" className="notification-count">
                        Bildirişlər
                    </Typography>
                    <Box className="notifications">
                        <Box display="flex" flexDirection="column">
                            {notifications.map((notification, i) => (
                                <NotificationItem key={i} notification={notification} />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </Fragment>
    );
};
