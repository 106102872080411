import React from 'react';
import { TextField as MUITextField, Autocomplete } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { Category, useCategoriesQuery } from 'graphql/generated';

export const CategoriesField: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { data: categoriesData } = useCategoriesQuery({
        fetchPolicy: 'cache-first',
        variables: { where: { isGetAll: true } },
    });

    const categories =
        categoriesData?.categories.edges
            .map(({ node }) => node)
            .filter((category) => category.parent)
            .map((category) => ({ ...category, name: `${category.parent?.name}: ${category.name}` }))
            .sort((a, b) => a.name.localeCompare(b.name)) || [];

    return (
        <Field name="categoryId">
            {({ form: { setFieldValue, setFieldTouched, touched, errors, values } }: FieldProps): React.ReactNode => {
                const category = categories.find((category) => category.id === values.categoryId);

                return (
                    <Autocomplete
                        id="categoryId"
                        onChange={(_, newValue: any): void => {
                            setFieldValue('categoryId', newValue?.id);
                        }}
                        value={category as Category}
                        options={categories || []}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <MUITextField
                                name="categoryId"
                                {...params}
                                variant="outlined"
                                error={touched.categoryId && !!errors.categoryId}
                                label={label}
                                placeholder={placeholder}
                                onBlur={() => setFieldTouched('categoryId', true)}
                            />
                        )}
                    />
                );
            }}
        </Field>
    );
};
