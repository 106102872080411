export const namespaces = [
    'header',
    'footer',
    'enums',
    'privacyPolicy',
    'termsOfTransportation',
    'warehouse',
    'settings',
    'home',
    'myPackages',
    'orders',
    'delivery',
    'myBonuses',
    'myAccount',
    'myDebt',
    'userMenu',
    'login',
    'weight',
    'homeAdmin',
    'customersAdmin',
    'packagesAdmin',
    'orderAdmin',
    'couriersAdmin',
    'returnsAdmin',
    'transactions',
    'storesAdmin',
    'messagesAdmin',
    'tariffsAdmin',
    'addressAdmin',
    'countriesAdmin',
    'branchAdmin',
    'managersAdmin',
    'categoriesAdmin',
    'orderSettingAdmin',
    'sidebarAdmin',
    'error',
    'packageFilter',
    'exchangeRatesByAdmin',
    'balance',
    'terminal',
    'confirmDialog',
    'resetPassword',
    'statuses',
    'blogs',
    'registration',
    'about',
    'contact',
    'services',
    'permissions',
    'flightAdmin',
    'createFlightDialog',
    'flightProperties',
];
