import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { styled } from '@mui/styles';
import { Button, Theme } from '@mui/material';

const Root = styled(Button)(({ theme }: { theme: Theme }) => ({
    '& .export-button': {
        color: 'rgba(153, 153, 153, 1)',
        border: 'none !important',

        textTransform: 'none',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        transition: '.3s box-shadow',
        ...theme.typography.body2,
        fontWeight: 500,
        boxShadow: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'rgba(245, 245, 245, 1)',
        },
        '& .MuiButton-root': {
            width: '100%',
        },
    },
}));

interface ExcelExportProps {
    tabelId: string;
    fileName: string;
}

export const ExcelExport: React.FC<ExcelExportProps> = ({ tabelId, fileName }) => {
    return (
        <Root>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table={tabelId}
                filename={fileName}
                sheet="Səhifə-1"
                buttonText="Export as .xlsx"
                className="export-button"
            />
        </Root>
    );
};
